import { AccountDTO, Phase, Roadmap } from "@dedmonkes/phase-protocol-sdk";
import { Dialog } from "@headlessui/react";
import { Nft } from "@metaplex-foundation/js";
import { web3 } from "@project-serum/anchor";
import { FC, Suspense, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { handleTxNotifications } from "../libs/utils";
import LoadingSpinner from "./LoadingSpinner";
import { useRoadmapScopedNfts } from "../state/hooks/nfts";
import {
    useRefreshSolPoolAmount,
    useRefreshUsdcPoolAmount,
    useRoadmap,
} from "../state/hooks/roadmaps";
import { useRecoilState, useRecoilValue } from "recoil";
import { claimModalIsOpen } from "../state/ui";
import { XCircleIcon } from "@heroicons/react/outline";

const NftItem = ({
    nft,
    onSelect,
    isSelected,
}: {
    nft: Nft;
    onSelect: () => void;
    isSelected: boolean;
}) => {
    const [name, setName] = useState<string>();
    const [imgUri, setImgUrl] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchMetadata = async () => {
            const response = await fetch(nft.uri);
            const metadata = await response.json();

            setImgUrl(metadata.image);
            setName(metadata.name);
            setLoading(false);
        };

        fetchMetadata();
    }, [nft]);
    return (
        <li className="relative" onClick={onSelect}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <img
                        className={`rounded-lg hover:outline hover:outline-2 hover:shadow-sm outline-gray-200 hover:shadow-black ${
                            isSelected ? "outline outline-2" : ""
                        }`}
                        src={imgUri}
                    />
                    <div className="absolute bottom-1 text-sm font-semi w-full text-center">
                        {name}
                    </div>
                </>
            )}
        </li>
    );
};

interface RefundClaimModalArgs {}
const RefundClaimModalContainer = () => {
    const [isClaimModalOpen, setIsClaimModalOpen] =
        useRecoilState(claimModalIsOpen);

    return (
        <>
            {isClaimModalOpen ? (
                <div className="relative z-10 top-0">
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-80" />
                    <div className="fixed inset-0 h-full">
                        <div className="flex min-h-full items-center justify-center p-4 text-center ">
                            <div
                                className="flex-row items-between w-full h-full 
                          max-w-xl h- transform
                          rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all"
                            >
                                <button
                                    className="absolute right-0 top-0 p-4"
                                    onClick={() => setIsClaimModalOpen(false)}
                                >
                                    <XCircleIcon className="text-white w-8" />
                                </button>
                                <div className="text-2xl font-bold leading-6 text-white">
                                    Claim your refund
                                </div>
                                <br />
                                <div className="text-md font-semi leading-normal text-gray-100">
                                    <p>Burn your nft to claim your refund</p>
                                    <br></br>
                                    <Suspense fallback={<LoadingSpinner />}>
                                        <RefundClaimModal />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

const RefundClaimModal: FC<RefundClaimModalArgs> = ({}) => {
    //@ts-ignore
    const [selectedNftMint, setSelectedNftMint] =
        useState<web3.PublicKey | undefined>();
    let completeButtonRef = useRef(null);
    const [nfts, refreshNfts] = useRoadmapScopedNfts();
    const sdk = usePhaseSdk();
    const [roadmap, setRoadmap] = useRoadmap();

    const fetchSolPool = useRefreshSolPoolAmount();
    const fetchUsdcPool = useRefreshUsdcPoolAmount();
    const handleClaim = async (mint: web3.PublicKey | undefined) => {
        if (!sdk?.globalConfig || mint === undefined) {
            return;
        }
        const phaseUnlock$ = await sdk.claimRefund(roadmap, mint);
        const toasts = new Map<string, string>();

        phaseUnlock$.subscribe({
            next: async (notification) => {
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Refund Claimed Successfully!",
                    "phase failed"
                );
                if (notification.type === undefined) {
                    setRoadmap(notification);
                }
                // navigate(`/roadmap/${roadmap.address.toBase58()}/phase/${phase.address.toBase58()}`);
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
            complete: () => {
                refreshNfts();
                fetchSolPool();
                fetchUsdcPool();
            },
        });
    };

    const handleClaimAll = async () => {
    if (!sdk?.globalConfig || nfts.length === 0) {
        return;
    }

    await Promise.all(nfts.map((nft) => handleClaim(nft.mintAddress)));
    };

    return (
      <>
        <div className='p-4 bg-gray-800 rounded-md h-fit max-h-[70vh] overflow-y-auto'>
          {nfts.length === 0 ? (
            <div className='text-center flex justify-center items-center h-40'>
              <p className='w-full'>No NFT's left to burn</p>
            </div>
          ) : null}
          <ul className='grid grid-cols-3 gap-4'>
            {nfts.map((nft) => (
              // @ts-ignore
              <NftItem
                key={nft.mintAddress.toBase58}
                nft={nft}
                onSelect={() => setSelectedNftMint(nft.mintAddress)}
                isSelected={
                  selectedNftMint?.toBase58() === nft.mintAddress.toBase58()
                }
              />
            ))}
          </ul>
        </div>

        <div className='mt-10 flex space-x-4'>
          <button
            onClick={(e) => {
              handleClaim(selectedNftMint);
            }}
            ref={completeButtonRef}
            disabled={!selectedNftMint}
            className='w-1/2 bg-maroon-flush disabled:opacity-50 disabled:bg-maroon-flush inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white'>
            Claim Refund
          </button>

          <button
            onClick={handleClaimAll}
            className='w-1/2 bg-maroon-flush disabled:opacity-50 disabled:bg-maroon-flush inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:bg-blue-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush focus:ring-white'>
            Claim All
          </button>
        </div>
      </>
    );
};

export default RefundClaimModalContainer;
