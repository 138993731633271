const RoadmapListSkeleton = () => (
    <div className="animate-pulse flex">
        <div className="p-8 w-full flex gap-8 justify-center mb-10">
            <div className="w-1/4 aspect-1  bg-gray-700 rounded-lg"></div>
            <div className="w-3/4">
                <div className="h-5 bg-gray-700 rounded-full w-48 mb-8 mt-1"></div>
                <div className="h-2 bg-gray-700 rounded-full max-w-[480px] mb-2.5"></div>
                <div className="h-2 bg-gray-700 rounded-full max-w-[440px] mb-2.5"></div>
                <div className="h-2 bg-gray-700 rounded-full max-w-[440px] mb-2.5"></div>
                <div className="h-2 bg-gray-700 rounded-full max-w-[400px] mb-2.5"></div>
            </div>
        </div>
    </div>
);
export default RoadmapListSkeleton;
