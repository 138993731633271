import { atom, selector } from "recoil";

export const solanaPriceQuery = selector<number>({
    key: "solanaPriceQuery",
    get: async ({ get }: any) => {
        try {

            const priceResponse = await fetch(
                "https://api.solscan.io/market?symbol=SOL",
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (priceResponse.ok) {
                const priceJson: { data: { priceUsdt: number } } =
                    await priceResponse.json();
                return priceJson.data.priceUsdt;
            } else {
                return 0;
            }
            
            
        } catch (e) {
            const priceResponse = await fetch(
                "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd",
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (priceResponse.ok) {
                const priceJson: { solana: { usd: number } } =
                    await priceResponse.json();
                return priceJson.solana.usd;
            } else {
                return 0;
            }
        }
    },
});

export const solanaPrice = atom<number>({
    key: "solanaPrice",
    default: solanaPriceQuery,
});
