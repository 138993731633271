import React from "react";
export const DataContext: any = React.createContext([
    {
        data: [
            { id: 1, title: "title", thumbnail: "" },
            { id: 1, title: "title", thumbnail: "" },
        ],
    },
]);
export const DataContext2: any = React.createContext([
    { users: [{ id: 1, title: "title", thumbnail: "" }] },
]);
