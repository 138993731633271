import toast from "react-hot-toast";
import { BN } from "@project-serum/anchor";

export const truncateKey = (publicKey: string, count: number = 6) => {
    if (!publicKey) return;
    const first = [...publicKey].slice(0, count).join("");
    const last = [...publicKey]
        .slice(publicKey.length - count, publicKey.length)
        .join("");
    return `${first}…${last}`;
};

export const sleep = (ms: number) =>
    new Promise((res, rej) => setTimeout(res, ms));

export const handleTxNotifications = (
    notification: any,
    toasts: Map<string, string>,
    successText: string,
    failText: string
) => {
    const failed: string[] = [];

    if (notification.type === "Information") {
        const toastId = toast.loading(
            `Sending transaction ${truncateKey(notification.sig)}`
        );
        toasts.set(notification.sig, toastId);
        return;
    }

    if (notification.type === "Success") {
        const toastId = toasts.get(notification.sig);
        if (toastId) {
            toast.success(
                `Transaction ${truncateKey(notification.sig)} confirmed`,
                { id: toastId }
            );
        }
        return;
    }

    if (notification.type === "Error") {
        const toastId = toasts.get(notification.sig);
        if (toastId) {
            toast.error(`Transaction ${truncateKey(notification.sig)} failed`, {
                id: toastId,
            });
        }
        failed.push(notification.sig);
        return;
    }

    if (failed.length > 0) {
        toast.error(failText);
    } else {
        toast.success(successText);
    }
};

export const getProgressPerc = (left: BN | number, total: BN | number) => {
    if (typeof left !== "number") left = left.toNumber();
    if (typeof total !== "number") total = total.toNumber();
    return `${(left / total) * 100}%`;
};
