import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex flex-col md:flex-row justify-center md:justify-between items-center py-6 px-8 mt-12 p-5 gap-5 text-right text-white">
                <ul className="flex items-center gap-6">
                    <li>
                        <a href="https://phaseprotocol.io">
                            <img
                                className="h-8 w-auto block mx-auto"
                                src="/phase-logo-full.svg"
                                alt="Workflow"
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://phaseprotocol.io/manifesto"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Manifesto
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://phaseprotocol.io/faq"
                            target="_blank"
                            rel="noreferrer"
                        >
                            FAQ
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/phaseprotocol"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Twitter
                        </a>
                    </li>
                    <li></li>
                </ul>
                <ul className="flex items-center gap-4">
                    <li>&#169; Phase Protocol 2022</li>
                    <li>
                        <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
