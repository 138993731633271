import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RoadmapListSkeleton from "./skeleton/RoadmapListSkeleton";
import { useRoadmaps, useRoadmapsMetadata } from "../state/hooks/roadmaps";
import { useNfts } from "../state/hooks/nfts";
import { AccountDTO, Roadmap } from "@dedmonkes/phase-protocol-sdk";
import { Nft } from "@metaplex-foundation/js";
import { useWallet } from "@solana/wallet-adapter-react";

export default React.memo(function RoadmapList({
    max = 50,
    pubkeys = [],
    useOwnedNftsOnly = false
}: {
    max: number;
    useOwnedNftsOnly?: boolean
    pubkeys?: string[];
}) {
    const [roadmaps, setRoadmaps] = useRoadmaps();
    const wallet = useWallet()
    const [displayRoadmaps, setDisplayRoadmaps] = useState<AccountDTO<Roadmap>[]>([]);
    const meta = useRoadmapsMetadata();
    const nfts = useNfts()

    useEffect(() => {
        let rms : AccountDTO<Roadmap>[] = []
        if (useOwnedNftsOnly) {
            //@ts-ignore
            const filtered = roadmaps.filter(rm => Array.from(nfts.keys()).findIndex((collection : string) => (collection === rm.account.collection.toBase58())) !== -1)
            rms.push(...filtered)
        }
        else {
            const filtered = pubkeys.length > 0
            ? roadmaps.filter((rm : any) => rm.featured)
            : roadmaps;
            rms.push(...filtered)
        }
        
        setDisplayRoadmaps(rms)
    }, [roadmaps.length, nfts.size, wallet.publicKey]);

    return (
        <div className="">
            {displayRoadmaps.length ? (
                <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-8 mb-12">
                    {displayRoadmaps
                        .filter((rm) => !!rm)
                        .map((rm) => (
                            <Link
                                to={`/roadmap/${rm.address}`}
                                key={rm.account.name}
                                className="relative hover:bg-gray-800 hover:bg-opacity-50 rounded-lg"
                            >
                                <div className="">
                                    <div className="flex items-center justify-start relative gap-8">
                                        <div className="w-36 h-36 min-w-36 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-maroon-flush-500 overflow-hidden">
                                            <img
                                                alt="roadmap branding"
                                                className="w-36 h-36 min-w-36 object-cover pointer-events-none group-hover:opacity-75"
                                                src={
                                                    meta[rm.address.toBase58()]
                                                        ?.image
                                                }
                                            />
                                        </div>
                                        <div className="flex-1 pr-2">
                                            <h3 className="text-xl font-medium font-syne text-white truncate pointer-events-none tracking-wide">
                                                {rm.account.name}
                                            </h3>
                                            <p className="text-sm font-medium text-gray-400 pointer-events-none line-clamp-3">
                                                {
                                                    meta[rm.address.toBase58()]
                                                        ?.description
                                                }
                                            </p>
                                            {/* <RoadmapVotes /> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                </ul>
            ) : (
                <RoadmapListSkeleton />
            )}
        </div>
    );
});
