import { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import { Link } from "react-router-dom";
import Progress from "./Progress";
import RoadmapVotes from "./RoadmapVotes";
import { useRoadmaps, useRoadmapsMetadata } from "../state/hooks/roadmaps";
import { AccountDTO, Roadmap, RoadmapMetadata } from "@dedmonkes/phase-protocol-sdk";
import { Account, Metaplex, Nft } from "@metaplex-foundation/js";
import { useConnection } from "@solana/wallet-adapter-react";
import RoadmapList from "./RoadmapList";
import { PublicKey } from "@solana/web3.js";
import { useNfts } from "../state/hooks/nfts";

export default function RoadmapsWallet() {
    const [roadmaps, setRoadmap] = useRoadmaps();

    // fetch featured pubkeys
    // const [featured, setFeatured] = useState<string[]>([]);

    // useEffect(() => {
    //     const getFeatured = async () => {
    //         // const res = await fetch(
    //         //     `${process.env.REACT_APP_PHASE_API!}/featured`
    //         // );
    //         // const data = await res.json();
    //         const ballonsvilleRoadmap = "C6ryt1EWciJW4n57tageWxe2ypKwzPiJEAKUdhgbMAL2"

    //         const data = [ballonsvilleRoadmap]
    //         setFeatured(data.map((rm: any) => rm.pubkey));
    //     };

    //     getFeatured();
    // }, [setFeatured]);
    

    return (
        <div className="p-8">
            <RoadmapList
                max={10}   
                useOwnedNftsOnly={true} 
                            
                    
            />
        </div>
    );
}

// view example votes in progress or something for non wallet connected
