import {
    AccountDTO,
    Roadmap,
    RoadmapMetadata,
} from "@dedmonkes/phase-protocol-sdk";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    roadmapMetaStore,
    roadmapQuery,
    roadmapsByAuthorityQuery,
    roadmapStore,
    solPoolAmountState,
    solTreasuryTotal,
    solVaultAmountQueryId,
    usdcPoolAmountState,
    usdcTreasuryTotal,
    usdcVaultAmountQuery,
    usdcVaultAmountQueryId,
} from "../roadmaps";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { currentRoadmap } from "../roadmaps";
import { currentPhaseKey } from "../phases";

export const useCurrentRoadmapKey = (): [string | null, string | null] => {
    const { pid, id } = useParams();
    const [currentRoadmapKey, setCurrentRoadmapKey] =
        useRecoilState<string | null>(currentRoadmap);
    const [currentPhase, setCurrentPhaseKey] =
        useRecoilState<string | null>(currentPhaseKey);

    useEffect(() => {
        if (!id) {
            return;
        }
        setCurrentRoadmapKey(id);
    }, [id]);

    useEffect(() => {
        if (!pid) {
            setCurrentPhaseKey(null);
            return;
        }
        setCurrentPhaseKey(pid);
    }, [pid]);

    return [currentRoadmapKey, currentPhase];
};

export const useRoadmap = (): [
    AccountDTO<Roadmap> | undefined,
    (roadmap: AccountDTO<Roadmap>) => void
] => {
    const [roadmapAddress, _] = useCurrentRoadmapKey();
    const roadmap = useRecoilValue<AccountDTO<Roadmap> | undefined>(
        roadmapQuery(roadmapAddress as string)
    );
    const setRoadmaps =
        useSetRecoilState<Record<string, AccountDTO<Roadmap>>>(roadmapStore);

    const updateRoadmap = (roadmap: AccountDTO<Roadmap>) => {
        const mergeRoadmap = (prev: Record<string, AccountDTO<Roadmap>>) => {
            if (roadmapAddress === null) {
                return prev;
            }
            const copy = { ...prev };
            copy[roadmapAddress] = roadmap;
            return copy;
        };

        setRoadmaps(mergeRoadmap);
    };

    return [roadmap, updateRoadmap];
};

export const useRoadmaps = (): [
    AccountDTO<Roadmap>[],
    (roadmap: AccountDTO<Roadmap>) => void
] => {
    const [roadmaps, setRoadmaps] =
        useRecoilState<Record<string, AccountDTO<Roadmap>>>(roadmapStore);

    const updateRoadmaps = (roadmap: AccountDTO<Roadmap>) => {
        const mergeRoadmaps = (prev: Record<string, AccountDTO<Roadmap>>) => {
            // if(prev[roadmap.address.toBase58()] === undefined){
            //     return prev
            // }

            const copy = { ...prev };
            copy[roadmap.address.toBase58()] = roadmap;
            return copy;
        };

        setRoadmaps(mergeRoadmaps);
    };

    return [Object.values(roadmaps), updateRoadmaps];
};

export const useRoadmapsByAuthority = (): AccountDTO<Roadmap>[] => {
    const roadmaps = useRecoilValue<AccountDTO<Roadmap>[]>(
        roadmapsByAuthorityQuery
    );
    return roadmaps;
};

export const useRoadmapsMetadata = (): Record<string, RoadmapMetadata> => {
    const [metadata, setMetadata] =
        useRecoilState<Record<string, RoadmapMetadata>>(roadmapMetaStore);
    return metadata;
};

export const useTreasuryTotal = (): [number, number] => {
    const [roadmap] = useCurrentRoadmapKey();
    const [solAmount, setSolAmount] = useRecoilState<number>(
        solTreasuryTotal(roadmap)
    );
    const [usdcAmount, setUsdcAmount] = useRecoilState<number>(
        usdcTreasuryTotal(roadmap)
    );

    return [solAmount, usdcAmount];
};

export const useSolPoolAmount = (): number => {
    const [roadmap] = useRoadmap();
    const [amount] = useRecoilState<number>(
        solPoolAmountState(roadmap?.account.solRoadmapPool.toBase58())
    );
    return amount;
};

export const useUsdcPoolAmount = (): number => {
    const [roadmap] = useRoadmap();
    const [amount] = useRecoilState<number>(
        usdcPoolAmountState(roadmap?.account.usdcRoadmapPool.toBase58())
    );
    return amount;
};

export const useRefreshSolPoolAmount = (): (() => void) => {
    const [roadmap] = useRoadmap();
    const setQueryId = useSetRecoilState<number>(
        solVaultAmountQueryId(roadmap?.account.solRoadmapPool.toBase58())
    );
    return () => setQueryId((val) => val + 1);
};

export const useRefreshUsdcPoolAmount = (): (() => void) => {
    const [roadmap] = useRoadmap();
    const setQueryId = useSetRecoilState<number>(
        usdcVaultAmountQueryId(roadmap?.account.usdcRoadmapPool.toBase58())
    );
    return () => setQueryId((val) => val + 1);
};
