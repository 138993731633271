import {
    AccountDTO,
    Deliverable,
    Phase,
    PhaseProtocolSDK,
    Proof,
} from "@dedmonkes/phase-protocol-sdk";
import { web3 } from "@project-serum/anchor";
import { atom, atomFamily, selectorFamily } from "recoil";
import { phaseContext } from "./phaseSdk";
import { currentRoadmap } from "./roadmaps";

export const refreshPhasesQuery = atom<number>({
    key: "phaseQuery",
    default: 0,
});

export const phaseQuery = selectorFamily<AccountDTO<Phase>[], string>({
    key: "phaseQuery",
    get:
        (roadmapAddress: string) =>
        async ({ get }: any) => {
            const sdk: PhaseProtocolSDK = get(phaseContext);
            
            get(refreshPhasesQuery);

            if (sdk && roadmapAddress) {
                const phases = await sdk.getPhases(
                    new web3.PublicKey(roadmapAddress)
                );
                return phases.sort((a : AccountDTO<Phase>, b : AccountDTO<Phase>) => a.account.id - b.account.id);
            }
            return [];
        },
});

export const phaseStore = atomFamily<AccountDTO<Phase>[], string>({
    key: "phaseStore",
    default: phaseQuery,
});

export const totalLockedPhasesQuery = selectorFamily<AccountDTO<Phase>[], string>({
    key: "totalLockedPhasesQuery",
    get:
        (roadmapAddress: string) =>
        async ({ get }: any) => {
            const phases  = get(phaseStore(roadmapAddress));
            get(refreshPhasesQuery);

            phases
                .filter((p: AccountDTO<Phase>) => {
                    return !!(p.account.state as any)?.lockedForMint;
                })
                .reduce(
                    (prev: number, curr: AccountDTO<Phase>) =>
                        prev +
                        curr.account.config.phaseSolVaultDepositAmount.toNumber(),
                    0
                )
            return phases;
        },
});

export const deliverablesQuery = selectorFamily<
    AccountDTO<Deliverable>[],
    string
>({
    key: "deliverableQuery",
    get:
        (phaseAddress: string) =>
        async ({ get }: any) => {
            const sdk: PhaseProtocolSDK = get(phaseContext);
            const currRoadmap: string = get(currentRoadmap);
            const phases: AccountDTO<Phase>[] = get(phaseStore(currRoadmap));
            const phase: AccountDTO<Phase> | undefined = phases.find(
                (phase) => phase.address.toBase58() === phaseAddress
            );

            if (phase) {
                const deliverables: AccountDTO<Deliverable>[] =
                    await sdk.getDeliverables(phase.account.activeDeliverables);
                return deliverables;
            }

            return [];
        },
});

export const deliverablesStore = atomFamily<AccountDTO<Deliverable>[], string>({
    key: "deliverableStore",
    default: deliverablesQuery,
});

export const proofsQuery = selectorFamily<
    Record<string, AccountDTO<Proof>[]>,
    string
>({
    key: "proofsQuery",
    get:
        (phaseAddress: string) =>
        async ({ get }: any) => {
            const sdk: PhaseProtocolSDK = get(phaseContext);

            const deliverables: AccountDTO<Deliverable>[] = get(
                deliverablesStore(phaseAddress)
            );

            const proofKeys: web3.PublicKey[] = deliverables
                .map((del) => del.account.proofs)
                .flat()
                .filter((x) => x !== null) as web3.PublicKey[];

            if (proofKeys.length > 0) {
                const proofs: AccountDTO<Proof>[] = await sdk.getProofs(
                    proofKeys
                );
                const proofRecord: Record<string, AccountDTO<Proof>[]> = {};

                for (const proof of proofs) {
                    const deliverableAddress =
                        proof.account.deliverable.toBase58();

                    if (proofRecord[deliverableAddress] === undefined) {
                        proofRecord[deliverableAddress] = [proof];
                    } else {
                        proofRecord[deliverableAddress] = [
                            ...proofRecord[deliverableAddress],
                            proof,
                        ];
                    }
                }
                return proofRecord;
            }

            return {};
        },
});

export const proofsStore = atomFamily<
    Record<string, AccountDTO<Proof>[]>,
    string
>({
    key: "proofsStore",
    default: proofsQuery,
});

export const currentPhaseKey = atom<string | null>({
    key: "proofsStore",
    default: null,
});
