import { useEffect, useMemo, useState } from "react";
import Countdown from "../Countdown";
import NextStepsBox from "./NextStepsBox";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import base58 from "bs58";
import { ComputeBudgetProgram, LAMPORTS_PER_SOL, SystemProgram, Transaction } from "@solana/web3.js";
import React from "react";
import toast from "react-hot-toast";
import { useFirebaseDataContext } from "../context/FirebaseDataProvider";
import Axios from "axios"
import { ProgressBar } from "./VotingInfoPanel";

const votingStartTime = process.env.REACT_APP_VOTING_START_TIME || 0;
const votingPeriod = process.env.REACT_APP_VOTING_PERIOD || 0;
const url = `https://mainnet.helius-rpc.com/?api-key=68394631-d011-489b-92b5-66c5527b9875`

const RefundVotePanel = () => {
    const { wallet, publicKey, signMessage, signTransaction } = useWallet();
    
    const [nfts, setNfts] = useState<string[]>([])
    const [hasProposalEnded, setHasProposalEnded] = useState(false)
    const anchorWallet = useAnchorWallet();
    const { connection } = useConnection();
    const [signWithLedger, setSignWithLedger] = React.useState(false);
    const {ownVotes, count, allVotes} = useFirebaseDataContext()

    const availableVoteMints = useMemo(() => nfts.filter((nft) => !ownVotes.map(x => x.mintId).includes(nft)), [nfts, ownVotes]);
    const canVote = useMemo(() => availableVoteMints.length > 0, [availableVoteMints]);
    function clamp(val: number, min: number, max: number) {
      return val > max ? max : val < min ? min : val;
    }
    const signWalletMessage = async (signatureMessage: string, publicKey: any, signMessage: any) => {
        if (!publicKey) throw Error('Wallet is not connected.');
        if (!signMessage) throw Error('Wallet does not support message signing!');
        console.log(signatureMessage)
        const message = new TextEncoder().encode(signatureMessage);
        const signedMessage = await signMessage(message);
        
        return {
            encodedMessage: base58.encode(signedMessage?.signature || signedMessage),
            message: base58.encode(message),
        };
    };

    const handleVote = React.useCallback(async (toRevoke: boolean) => {
        if (!publicKey || !wallet || !anchorWallet || !connection) return;
        toast.loading("Processing vote...", {id: "vote"})
        let authTx = ''
        let queryProof = '';
        if (signWithLedger === false) {
            const nonce = (new Date().getTime()/1000).toFixed(0);
            try{
                const signed = await signWalletMessage(`Sign this message to confirm - Vote: ${toRevoke? "revoke": "yes"} ts: ${nonce}`, publicKey, signMessage);
                queryProof = `${signed.encodedMessage}&nonce=${nonce}`;
            }
            catch(e){
                toast.error("Error signing with wallet.", {id: "vote"})
                return
            }
      
        } else {
            // const transaction = new Transaction().add(
            //     SystemProgram.transfer({
            //         fromPubkey: publicKey,
            //         toPubkey: publicKey,
            //         lamports: 0.001 * LAMPORTS_PER_SOL, //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
            //     })
            // );
            // transaction.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 5000 }));
            // transaction.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 25000 }));
            // transaction.feePayer = publicKey;
            // const blockhash = await connection.getLatestBlockhash('confirmed');
            // transaction.recentBlockhash = blockhash.blockhash;
  
            // if (!signTransaction) return;
            // const signature = await sendTransaction(transaction, connection);
  
            // authTx = signature;
        }
        try{
            let voteFetch = await Axios.get(
                `/api/vote/${publicKey}?vote=${toRevoke ? "no": "yes"}&queryProof=${queryProof}&authTx=${authTx}`
            );
            const res = await voteFetch.data
            if (res.success){
                toast.success(res.data, {id:"vote"})
            }
            else {
                toast.error(res.data, {id: "vote"})
            }
        }
        catch(e){
            toast.error("There was an error voting. Please try again.", {id:"vote"})

        }
       
      },[publicKey, wallet, anchorWallet, connection, signWithLedger, signMessage])
     
    useEffect(() => {
        if (publicKey === null){
            return
        }

        const fetchNfts = async () => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  jsonrpc: '2.0',
                  id: 'my-id',
                  method: 'searchAssets',
                  params: {
                    ownerAddress: publicKey?.toBase58(),
                    grouping: ["collection", "DA5mouwm1Dh4GcJkt1tNZybXKwfMSJC5PWotvY2K6hCB"],
                    displayOptions: {
                        showZeroBalance: false,
                        showUnverifiedCollections: false,
                    },
                    page: 1, // Starts at 1
                    limit: 1000
                  },
                }),
              });
    
              const { result } = await response.json();
              const mints = result.items.filter((n: any) => !n.burnt).map((nft: { id: any; }) => nft.id)
              console.log(mints)
              setNfts(mints)
        } 
        fetchNfts()
    }, [publicKey])

    useEffect(() => {
      if(allVotes.length > 0 && allVotes.filter((vote) => vote.vote).length >= Math.ceil(0.05 * 3333)){
        setHasProposalEnded(true);
      }
    },[allVotes])

    return (
      <>
        {votingPeriod && votingStartTime && (
          <div className='max-w-7xl mx-auto py-8 px-6 lg:px-8'>
            <NextStepsBox
              title='FUD Bois - Vote to open refunds'
              collapsed={true}
              classes='shadow-lg border-2 border-gray-800 bg-gray-700 bg-opacity-50 text-gray-50 p-6 rounded-lg'>
              <div className='flex flex-col gap-3'>
                <div className='flex-col'>
                  <p>
                    This project has broken terms of service due to prolonged
                    inactivity (details can be found in the termination clause).
                  </p>
                  <p>
                    Please vote whether to open for refunds to the community
                    with the remainder pool total.
                  </p>
                </div>
                <>
                  {hasProposalEnded ? (
                    <h1>
                      Voting has ended, please check project page for updates
                      after the votes have been finalized on the Blockchain.
                    </h1>
                  ) : (
                    <Countdown
                      endTimestamp={
                        Number(votingStartTime) + Number(votingPeriod)
                      }
                      onCompletion={() => setHasProposalEnded(true)}
                    />
                  )}
                </>
                <div>
                  <div className='flex flex-row w-full justify-start gap-4 '>
                    <p className='text-xs py-2 text-left'>
                      Your Available Votes: {availableVoteMints?.length}
                    </p>
                    <p className='text-xs py-2 text-left'>
                      Votes Casted: {ownVotes?.length}
                    </p>
                    <p className='text-xs  py-2 text-left self-end '>
                      {clamp(
                        allVotes.filter((vote) => vote.vote).length,
                        0,
                        Math.ceil(0.05 * 3333)
                      )}{' '}
                      / {Math.ceil(0.05 * 3333)} Total Votes
                    </p>
                  </div>

                  <ProgressBar
                    percentageVetoed={
                      allVotes.filter((vote) => vote.vote).length
                    }
                    threshold={Math.ceil(0.05 * 3333)}
                  />
                </div>

                <div className='flex gap-4 mt-2 justify-start'>
                  <button
                    onClick={() => handleVote(false)}
                    disabled={!canVote || hasProposalEnded}
                    className='relative inline-flex w-100 items-center px-12 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-600 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500'>
                    Yes
                  </button>

                  <button
                    onClick={() => handleVote(true)}
                    disabled={ownVotes.length === 0 || hasProposalEnded}
                    className='relative inline-flex items-center px-12 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-600 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500'>
                    Revoke Votes
                  </button>
                </div>
              </div>
            </NextStepsBox>
          </div>
        )}
      </>
    );
}
 
export default RefundVotePanel;