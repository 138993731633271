import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function TOS() {
  const tos = `These terms and conditions create a contract between you and Phase Protocol aka "Phase" (the "Agreement"). Please read the Agreement carefully.

A. INTRODUCTION TO OUR SERVICES

This Agreement governs your use of Phase's Services ("Services" - NFT mint security  service), in which Phase will be holding mint funds in their custom program  until you meet each goal as described in the Roadmap defined below. Our Services are available for your use in your country or territory of residence ("Home Country"). By creating an account for use of the Services in a particular country or territory you are specifying it as your Home Country. 

B. USING OUR SERVICES

ESCROW

Your mint funds will be stored in Phase's custom programs. 

PAYMENT

Phase will receive 2% of every phase deposit value that is approved in by the DAO or from completion of the mint process. The initial payout will also have a fee of 2%. 

ESCROW PROCESS

After your mint process is complete on one of our launchpad partners, the funds will be visible in our program. The funds are then dispersed over each milestone outlined in your roadmap. , . These funds will be released to you upon completion of outlined milestones with your holders providing authority over the disbursement. The Escrowed Funds will not be released under any circumstances by Phase unless said circumstances are discussed in this Agreement.

PRIVACY

Your use of our Services is not conditioned upon you revealing your legal identity.

ROADMAP

Your use of the Services is conditional upon providing a list of goals for the project with estimated months of completion (the "Roadmap"). This must be provided before the mint process begins.
Once mint is complete, the Roadmap may only be changed by you if the holders of the project do not reject the proposed change within the 3 day time constraint. If a vote gets more than 5% of holders to veto, then this is considered a rejected proposal.


SERVICES AND CONTENT USAGE RULES

Your use of the Services must follow the rules set forth in this section ("Usage Rules"). Any other use of the Services is a material breach of this Agreement. Phase may monitor your use of the Services to ensure that you are following these Usage Rules.

All Services:
- Phase's delivery of Services does not transfer any commercial or promotional use rights to you, and does not constitute a grant or waiver of any rights of the copyright owners.
- Manipulating social metrics, ratings, or reviews via any means — such as (i) using a bot, script, or automated process; or (ii) providing or accepting any kind of compensation or incentive — is prohibited.
- It is your responsibility to respond promptly to any requests from us to provide direction, information, approvals, authorizations, or decisions that are reasonably necessary for us to perform Services in accordance with the requirements of this Agreement.
- You may not tamper with or circumvent any security technology included with the Service.
- You may access our Services only using Phase's software, and may not modify or use modified versions of such software.

C. YOUR SUBMISSIONS TO OUR SERVICES

Submissions Guidelines: You may not use the Services to:
- use any images that (i) you do not have permission, right or license to use, or (ii) infringe on the rights of any third party;
- use images that are objectionable, offensive, unlawful, deceptive, inaccurate, or harmful content;
- request personal information from a minor;
- impersonate or misrepresent your affiliation with another person, or entity;
- plan or engage in any illegal, fraudulent, or manipulative activity.

CONTRACT CHANGES

Phase reserves the right at any time to modify this Agreement and to add new or additional terms or conditions on your use of the Services. Such modifications and additional terms and conditions will be effective immediately and incorporated into this Agreement. Your continued use of the Services will be deemed acceptance thereof.

INTELLECTUAL PROPERTY

You agree that the Services, including but not limited to graphics, user interface, audio clips, video clips, editorial content, and the scripts and software used to implement the Services, contain proprietary information and material that is owned by Phase, its licensors and/or Content providers, and is protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary information or materials in any way whatsoever except for use of the Services for personal, noncommercial uses in compliance with this Agreement. No portion of the Services may be transferred or reproduced in any form or by any means, except as expressly permitted by this Agreement. You agree not to modify, rent, loan, sell, share, or distribute the Services in any manner, and you shall not exploit the Services in any manner not expressly authorized.
The Phase name, the Phase logo, and other Phase trademarks, service marks, graphics, and logos used in connection with the Services are trademarks or registered trademarks of Phase in the U.S. and other countries throughout the world. You are granted no right or license with respect to any of the aforesaid trademarks.

COPYRIGHT 

Unless otherwise noted, Services provided by Phase are copyrights of Phase.

TERMINATION

If you become inactive for at least 30 days by failing to update on developments relating to progress of the Roadmap, fail to be active on social media (twitter, discord, or any other communication service you use to your holders) or Phase suspects that you have rugged the NFT project, to comply with any of the provisions of this Agreement, Phase may, after giving notice to you: (i) take a vote among NFT holders to ask if they believe if the project is being abandoned; and (ii) release the mint funds equally among the holders if the vote reaches holder consensus (which is constant across all of Phase Protocol at 5% of the total NFT holder base for the collection in question). Phase further reserves the right to modify, suspend, or discontinue the Services (or any part or Content thereof) at any time as allowed by this Agreement with notice to you, and Phase will not be liable to you or to any third party should it exercise such rights. In the event Phase chooses to discontinue services with you, refunds will open to holders of the project.

DISCLAIMER OF WARRANTIES; LIABILITY LIMITATION

PHASE DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT FROM TIME TO TIME PHASE MAY MODIFY THE SERVICES AT ANY TIME.

YOU AGREE THAT UNLESS REQUIRED BY APPLICABLE LAW, PHASE HAS NO RESPONSIBILITY TO RELEASE THE ESCROW FUNDS TO YOU UNLESS YOU COMPLETE THE SUBMITTED ROADMAP.

PHASE SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION SUBMITTED BY YOU IN CONNECTION WITH THE SERVICES, BUT YOU AGREE THAT YOUR SUBMISSION OF SUCH INFORMATION IS AT YOUR SOLE RISK, AND YOU HEREBY RELEASE PHASE FROM ANY AND ALL LIABILITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.

PHASE DOES NOT REPRESENT OR GUARANTEE THAT THE SERVICES WILL BE FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND YOU HEREBY RELEASE PHASE FROM ANY LIABILITY RELATING THERETO. 

PHASE IS NOT RESPONSIBLE FOR DATA CHARGES YOU MAY INCUR IN CONNECTION WITH YOUR USE OF THE SERVICES.

WAIVER AND INDEMNITY

BY USING THE SERVICES, YOU AGREE, TO THE EXTENT PERMITTED BY LAW, TO INDEMNIFY AND HOLD PHASE, ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS HARMLESS WITH RESPECT TO ANY CLAIMS ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, YOUR USE OF THE SERVICES, YOUR FAILURE TO COMPLETE YOUR SUBMITTED ROADMAP, OR ANY ACTION TAKEN BY PHASE AS PART OF ITS INVESTIGATION OF A SUSPECTED VIOLATION OF THIS AGREEMENT OR AS A RESULT OF ITS FINDING OR DECISION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. YOU AGREE THAT YOU SHALL NOT SUE OR RECOVER ANY DAMAGES FROM PHASE, ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, AND LICENSORS AS A RESULT OF ITS DECISION TO REMOVE OR DENY ACCESS TO ANY INFORMATION OR CONTENT, TO SUSPEND OR TERMINATE YOUR ACCESS TO THE SERVICES, OR TO TAKE ANY OTHER ACTION DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR AS A RESULT OF PHASE'S CONCLUSION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. THIS WAIVER AND INDEMNITY PROVISION APPLIES TO ALL VIOLATIONS DESCRIBED IN OR CONTEMPLATED BY THIS AGREEMENT.

GOVERNING LAW

Except to the extent expressly provided in the following paragraph or to the extent required by applicable law, this Agreement and the relationship between you and Phase, and all Transactions on the Services shall be governed by the laws of the State of Delaware, excluding its conflicts of law provisions. You and Phase agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Kent, Delaware, to resolve any dispute or claim arising from this Agreement. If (a) you are not a U.S. citizen; (b) you do not reside in the U.S.; (c) you are not accessing the Service from the U.S.; and (d) you are a citizen of one of the countries identified below, you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts located in the state, province or country identified below whose law governs:
If you are a citizen of any European Union country or the United Kingdom, Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.

OTHER PROVISIONS

This Agreement constitutes the entire agreement between you and Phase and governs your use of the Services, superseding any prior agreements with respect to the same subject matter between you and Phase. If any part of this Agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect. Phase's failure to enforce any right or provisions in this Agreement will not constitute a waiver of such or any other provision. Phase will not be responsible for failures to fulfill any obligations due to causes beyond its control.
You agree to comply with all local, state, federal, and national laws, statutes, ordinances, and regulations that apply to your use of the Services. Your use of the Services may also be subject to other laws. Risk of loss for all electronically delivered Transactions pass to the acquirer upon electronic transmission to the recipient. No Phase employee or agent has the authority to vary this Agreement.
Phase may notify you with respect to the Services by sending an email message to your email address, or by a posting on the Services. Notices shall become effective immediately. Phase may also contact you by email or push notification to send you additional information about the Services.
You hereby grant Phase the right to take steps Phase believes are reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement. You agree that Phase has the right, without liability to you, to disclose any data and/or information to law enforcement authorities, government officials, and/or a third party, as Phase believes is reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement (including but not limited to Phase's right to cooperate with any legal process relating to your use of the Services and/or Content, and/or a third-party claim that your use of the Services and/or Content is unlawful and/or infringes such third party's rights).

Last Updated: October 17, 2022`;
  const navigate = useNavigate();

  // useEffect(() => {
  //     const getTOS = async () => {
  //         const terms = await fetch(
  //             `${process.env.REACT_APP_PHASE_API!}/tos`
  //         );
  //         setTos(await terms.text());
  //     };

  //     getTOS();
  // });

  return (
    <>
      <main className='text-white'>
        <div className='mx-auto py-8 px-6 lg:px-8 '>
          <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 items-center'>
            <button
              className='btn'
              onClick={() => {
                navigate('/');
              }}>
              Back Home
            </button>
            <h1 className='mb-5 mt-5' style={{ fontSize: '32px' }}>
              Terms of Service
            </h1>
            <pre className='font-sans tos-pre'>{tos}</pre>
          </div>
        </div>
      </main>
    </>
  );
}
