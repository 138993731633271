import { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import { Link } from "react-router-dom";
import Progress from "./Progress";
import RoadmapVotes from "./RoadmapVotes";
import usePhase from "../hooks/usePhaseProtocol";
import { Metaplex, Nft } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { roadmapState, roadmapMetaStore, nftsState } from "../state/roadmaps";
import { AccountDTO, Phase, Roadmap } from "@dedmonkes/phase-protocol-sdk";

import { useRecoilValue } from "recoil";
import VotingInfoPanel from "./VotingInfoPanel";
import { useRoadmaps, useRoadmapsMetadata } from "../state/hooks/roadmaps";

export default function RoadmapsOngoing() {
    const sdk = usePhase();
    const [roadmaps]: [
        AccountDTO<Roadmap>[],
        (roadmap: AccountDTO<Roadmap>) => void
    ] = useRoadmaps();
    const [votingPhases, setVotingPhases] = useState<AccountDTO<Phase>[]>();
    const meta = useRoadmapsMetadata();
    useEffect(() => {
        const getVotingPhases = async () => {
            const phasePromise = roadmaps.map((rm: AccountDTO<Roadmap>) =>
                sdk.getPhases(rm.address)
            );
            const phases: AccountDTO<Phase>[] = (
                await Promise.all(phasePromise)
            ).flat();
            const votingPhases = phases.filter(
                (x) =>
                    x.account.state?.stagedForApproval ||
                    x.account.state?.stagedForCompletion
            );
            setVotingPhases(votingPhases);
        };
        getVotingPhases();
    }, [roadmaps.length]);
    return (
        <div className="p-8">
            <ul className="grid grid-cols-3 gap-2">
                {/* <div key={phase.account.id} className="flex flex-col items-start relative gap-8"> */}

                {votingPhases && roadmaps ?(
                    votingPhases.map((phase, i) => (
                        <div
                            key={phase.account.id}
                            className="flex flex-col items-start relative gap-8"
                        >
                            <div className="rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-maroon-flush-500 overflow-hidden">
                                <img
                                    src={
                                        meta[phase.account.roadmap.toBase58()]
                                            ?.image
                                    }
                                    alt=""
                                    className="object-cover pointer-events-none group-hover:opacity-75"
                                />
                            </div>
                            <div className="">
                                <h3 className="block text-lg font-bold text-white truncate pointer-events-none">
                                    {
                                        roadmaps.filter(
                                            (rm) =>
                                                rm?.address.toBase58() ===
                                                phase.account.roadmap.toBase58()
                                        )[0]?.account.name
                                    }
                                </h3>
                                <p className="block text-base text-gray-200 pointer-events-none">
                                    {phase.account.config.name}
                                </p>
                                <p className="block text-base text-gray-200 pointer-events-none">
                                    {phase.account.state?.stagedForApproval
                                        ? "Draft Phase Approval"
                                        : "Phase Payout Vote"}
                                </p>
                                {/* <RoadmapVotes /> */}
                                <Link
                                    to={`/roadmap/${roadmaps
                                        .filter(
                                            (rm) =>
                                                rm?.address.toBase58() ===
                                                phase.account.roadmap.toBase58()
                                        )[0]
                                        ?.address.toBase58()}/phase/${phase.address.toBase58()}`}
                                    type="button"
                                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-gray-400 shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-800 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500"
                                >
                                    Cast Your Vote
                                </Link>
                            </div>
                        </div>
                        // <div className="w-full">
                        //     <VotingInfoPanel key={i} roadmap={roadmaps.filter(rm => rm.address.toBase58() === phase.account.roadmap.toBase58())[0]} phase={phase}/>

                        // </div>
                    ))
                ) : (
                    <></>
                )}
                {/* </div> */}
            </ul>
        </div>
    );
}

// view example votes in progress or something for non wallet connected
