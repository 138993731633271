// import { PublicKey } from "@solana/web3.js";
import { useState, useEffect } from "react";
import { connection } from "../constants";
import { useRecoilState } from "recoil";
import { solVaultAmountState } from "../state/roadmaps";
import { LAMPORTS_PER_SOL, TokenAmount } from "@solana/web3.js";
import { useCurrentRoadmapKey, useRoadmap } from "../state/hooks/roadmaps";
import {
    usePhase,
    useSolVaultAmount,
    useUsdcVaultAmount,
} from "../state/hooks/phases";
import { BN } from "@project-serum/anchor";

export default function RoadmapLockup() {
    const [phase] = usePhase();
    const [roadmap] = useRoadmap();
    const solPhaseVaultAmount = useSolVaultAmount();
    const usdcPhaseVaultAmount = useUsdcVaultAmount();

    return (
        <>
            {roadmap && phase ? (
                <div className="py-2 px-0 leading-3 text-white">
                    <table className="table table-auto w-full">
                        <tbody>
                            <tr className="py-2 flex justify-between">
                                <td>SOL Deposit Amount</td>
                                <td>
                                    <span className="sol inline-block align-middle mr-[2px]">
                                        ◎
                                    </span>
                                    {phase.account.config.phaseSolVaultDepositAmount
                                        .div(new BN(LAMPORTS_PER_SOL))
                                        .toString()}
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>SOL Vault</td>
                                <td>
                                    <span className="sol inline-block align-middle mr-[2px]">
                                        ◎
                                    </span>
                                    {solPhaseVaultAmount}
                                </td>
                            </tr>
                            <hr className="my-2 opacity-25" />
                            <tr className="py-2 flex justify-between">
                                <td>USDC Deposit Amount</td>
                                <td>
                                    <span className="mr-[2px]">$</span>
                                    {phase.account.config.phaseUsdcVaultDepositAmount
                                        .div(new BN(1_000_000))
                                        .toString()}
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>USD Vault</td>
                                <td>
                                    <span className="mr-[2px]">$</span>
                                    {usdcPhaseVaultAmount}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
