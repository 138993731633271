import { AccountDTO, Phase } from "@dedmonkes/phase-protocol-sdk";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { deliverablesStore, phaseStore, refreshPhasesQuery } from "../phases";
import { solVaultAmountState, usdcVaultAmountState } from "../roadmaps";
import { useCurrentRoadmapKey } from "./roadmaps";

export const usePhases = (): [
    AccountDTO<Phase>[],
    (phase: AccountDTO<Phase>) => void
] => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const [phases, setPhases] = useRecoilState<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );

    const updatePhases = (phase: AccountDTO<Phase>) => {
        const mergePhases = (prev: AccountDTO<Phase>[]) => {
            const index = prev.findIndex(
                (item) => phase.address.toBase58() === item.address.toBase58()
            );

            if (index === -1) {
                return [...prev, phase];
            }

            const copy = [...prev];
            copy[index] = phase;
            return copy;
        };

        setPhases(mergePhases);
    };

    return [phases, updatePhases];
};

export const usePhasesId = (): [
    AccountDTO<Phase>[],
    (phase: AccountDTO<Phase>) => void
] => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const [phases, setPhases] = useRecoilState<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );

    const updatePhases = (phase: AccountDTO<Phase>) => {
        const mergePhases = (prev: AccountDTO<Phase>[]) => {
            const index = prev.findIndex(
                (item) => phase.address.toBase58() === item.address.toBase58()
            );

            if (index === -1) {
                return [...prev, phase];
            }

            const copy = [...prev];
            copy[index] = phase;
            return copy;
        };

        setPhases(mergePhases);
    };

    const ids = useMemo(
        () => phases.map((x) => x.address.toBase58()),
        [phases.map((x) => x.address.toBase58())]
    );

    return [phases, updatePhases];
};

export const usePhase = (): [
    AccountDTO<Phase> | undefined,
    (phase: AccountDTO<Phase>) => void
] => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const [phases, setPhases] = useRecoilState<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );

    const updatePhases = (phase: AccountDTO<Phase>) => {
        const mergePhases = (prev: AccountDTO<Phase>[]) => {
            const index = prev.findIndex(
                (item) => phase.address.toBase58() === item.address.toBase58()
            );

            if (index === -1) {
                return [...prev, phase];
            }

            const copy = [...prev];
            copy[index] = phase;
            return copy;
        };

        setPhases(mergePhases);
    };

    return [
        phases.find((phase) => phase.address.toBase58() === phaseAddress),
        updatePhases,
    ];
};

export const useRefreshPhases = () => {
    const [queryId, setQueryId] = useRecoilState<number>(refreshPhasesQuery);
    return () => setQueryId((val) => val + 1);
};

export const useSolVaultAmount = () => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const phases = useRecoilValue<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );
    const phase = phases.find(
        (phase) => phase.address.toBase58() === phaseAddress
    );
    const [amount, setAmount] = useRecoilState<number>(
        solVaultAmountState(phase?.account.solPhaseVault.toBase58())
    );
    return amount;

    return 0;
};

export const useUsdcVaultAmount = () => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const phases = useRecoilValue<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );
    const phase = phases.find(
        (phase) => phase.address.toBase58() === phaseAddress
    );
    const [amount, setAmount] = useRecoilState<number>(
        usdcVaultAmountState(phase?.account.usdcPhaseVault.toBase58())
    );
    return amount;

    return 0;
};
