import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function ConnectPlaceholder({ ...props }) {
    return (
        <div className="p-8">
            <div className="p-16 border-2 border-dashed border-gray-700 rounded-md">
                <img
                    className="h-12 w-auto block mx-auto"
                    src="/phase-logo-full.svg"
                    alt="Workflow"
                />

                <div className="text-center mt-8">
                    <div className="mt-2">
                        <p className="text text-white">
                            Connect your wallet to view the communities that
                            have enabled Phase.
                        </p>
                        <WalletMultiButton className="block mx-auto mt-5" />
                    </div>
                </div>
            </div>
        </div>
    );
}
