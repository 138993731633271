import { Nft } from "@metaplex-foundation/js";
import { Wallet, web3 } from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { useEffect, useMemo, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "../../components/Tooltip";
import { useCollectionNfts, useNfts } from "../../state/hooks/nfts";

export interface WalletNFT {
    mint: string;
    metadata: Metadata;
}

export interface NftType {
        "address": web3.PublicKey,
        "mintAddress": web3.PublicKey,
        "updateAuthorityAddress": web3.PublicKey,
        "json": any,
        "jsonLoaded": boolean,
        "name": string,
        "symbol": string,
        "uri": string,
        "isMutable": boolean,
        "primarySaleHappened": boolean,
        "sellerFeeBasisPoints": number,
        "editionNonce": number,
        "creators": CreatorsEntity[],
        "tokenStandard": number,
        "collection": Collection,
        "collectionDetails": {
            "version": string,
            "size": number
        },
        "uses": Uses | null
     
}
export interface Metadata {
    key: number;
    data: Data;
    mint: string;
    uses: Uses;
    count: number;
    tokenAcc: string;
    uiAmount: string;
    isMutable: number;
    collection: Collection;
    editionNonce: number;
    masterEdition: string;
    tokenStandard: number;
    metadataAddress: string;
    updateAuthority: string;
    primarySaleHappened: number;
}
export interface Data {
    uri: string;
    name: string;
    symbol: string;
    creators?: CreatorsEntity[] | null;
    sellerFeeBasisPoints: number;
}
export interface CreatorsEntity {
    share: number;
    address: string;
    verified: number;
}
export interface Uses {
    total: string;
    remaining: string;
    useMethod: number;
}
export interface Collection {
    key: string;
    verified: number;
}

export function NFTPicker(props: {
    publicKey: PublicKey;
    onChange: (nft:  {nft : NftType, metadata : any}) => void;
}) {


    const [selectedNFT, setSelectedNFT] =
        useState<{nft : NftType, metadata : any} | null>(null);
        
    const nfts : {nft : NftType, metadata : any}[] = useCollectionNfts() as unknown as {nft : NftType, metadata : any}[]

    const potentialCollectionNFts = useMemo(() => nfts.filter(
        (item) => {
            if (item.nft?.collectionDetails) {
                if(Number(item.nft?.collectionDetails.size) !== 0){
                    return true
                }
            }
            else if (!item.nft.collection){
                return true
            }
            return false
        }
    ), [nfts]);

    return (
        <div className="text-white mt-5">
            <label
                htmlFor="roadmapTitle"
                className="mt-8 mb-5 flex items-center font-medium text-gray-200"
            >
                Select Your Collection NFT
                <Tooltip text="In order to create a roadmap for an existing collection, you must be using the wallet containing your Metaplex Collection NFT. Select it below.">
                    <BiInfoCircle />
                </Tooltip>
            </label>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-7">
                {potentialCollectionNFts.map(nft => (
                    <div key={nft.nft.mintAddress.toBase58()}>
                        <img
                            alt={nft.nft.mintAddress.toBase58()}
                            src={nft.metadata.image}
                            className="rounded"
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                                border:
                                    nft.nft.mintAddress.toBase58() === selectedNFT?.nft.mintAddress.toBase58()
                                        ? "4px solid #94225B"
                                        : "none",
                            }}
                            onClick={() => {
                                setSelectedNFT(nft);
                                props.onChange(nft)
                            }}
                        />
                    </div>
                ))}
            </div>
            {potentialCollectionNFts.length === 0 && (
                <div>
                    <p className="text-gray-400">
                        No collection NFTs found. Ensure you have the correct
                        wallet connected.
                    </p>
                </div>
            )}
        </div>
    );
}
