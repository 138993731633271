import {
    AccountDTO,
    Phase,
    PhaseProtocolSDK,
    Roadmap,
} from "@dedmonkes/phase-protocol-sdk";
import { Metaplex, Nft } from "@metaplex-foundation/js";
import { web3 } from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { atom, atomFamily, selector } from "recoil";
import { phaseQuery, phaseStore } from "./phases";
import { phaseContext } from "./phaseSdk";
import { currentRoadmap, roadmapQuery } from "./roadmaps";
import { connection, phaseWallet } from "./wallet";

export const nftQuery = selector<Map<string, Nft[]>>({
    key: "nftQuery",
    get: async ({ get }: any) => {
        const wallet = get(phaseWallet);
        const connectionState = get(connection);
        const nfts = new Map<string, Nft[]>();
        // Allow query to refresh nfts store from rpc
        get(nftsQueryRequestIDState);
        if (connectionState && wallet?.publicKey) {
            const metaplex = new Metaplex(connectionState);
            const myNfts: Nft[] = await metaplex
                .nfts()
                .findAllByOwner(wallet.publicKey)
                .run();

            for (const nft of myNfts) {
                if (nft.collection && nft.collection.verified) {
                    const collectionAddress = nft.collection.address.toBase58();
                    let currentCollectionNfts = nfts.get(collectionAddress);
                    currentCollectionNfts = currentCollectionNfts
                        ? currentCollectionNfts
                        : [];
                    nfts.set(collectionAddress, [
                        ...currentCollectionNfts,
                        nft,
                    ]);
                }
            }
        }

        return nfts;
    },
});

export const collectionsQuery = selector<PublicKey[]>({
    key: "collectionsQuery",
    get: async ({ get }: any) => {
        const nfts: Map<string, Nft[]> = get(nftStore);
        const collections: PublicKey[] = Array.from(nfts.keys()).map(
            (collection: string) => new PublicKey(collection)
        );
        return collections;
    },
});

export const nftsForRoadmapQuery = selector<Nft[]>({
    key: "nftsForRoadmapQuery",
    get: async ({ get }: any) => {
        const currentRm = get(currentRoadmap);
        if (currentRm === undefined) {
            return [];
        }

        const roadmap: AccountDTO<Roadmap> = get(roadmapQuery(currentRm));
        const nfts: Map<string, Nft[]> = get(nftStore);
        if (roadmap == undefined) {
            return [];
        }
        const scopedNfts = nfts.get(
            roadmap.account.collection?.toBase58() as string
        );
        return scopedNfts ? scopedNfts : [];
    },
});


export const collectionNftQuery = selector<Nft[]>({
    key: "collectionNftQuery",
    get: async ({ get }: any) => {
        const wallet = get(phaseWallet);
        const connectionState = get(connection);
        let nfts : Nft[] = [];
        // Allow query to refresh nfts store from rpc
        get(nftsQueryRequestIDState);
        if (connectionState && wallet?.publicKey) {
            const metaplex = new Metaplex(connectionState);
            const myNfts: Nft[] = await metaplex
                .nfts()
                .findAllByOwner(wallet.publicKey)
                .run();

            for (const nft of myNfts) {
                if (nft.collection === null || nft.collectionDetails) {
                    nfts.push(nft)
            }
        }
    }
        return nfts;
    },
});

export const nftsQueryRequestIDState = atom({
    key: "nftsQueryRequestIDState",
    default: 0,
});

export const nftStore = atom<Map<string, Nft[]>>({
    key: "nftStore",
    default: nftQuery,
});
export const collectionNfts = atom<Nft[]>({
    key: "collectionNftStore",
    default: collectionNftQuery,
});
