import { useMemo, Suspense } from "react";
import { Link } from "react-router-dom";
import RoadmapsFeatured from "../components/RoadmapsFeatured";
import RoadmapsWallet from "../components/RoadmapsWallet";
import { useWallet } from "@solana/wallet-adapter-react";
import ConnectPlaceholder from "../components/ConnectPlaceholder";

import RoadmapsOngoing from "../components/RoadmapsOngoing";
import RoadmapListSkeleton from "../components/skeleton/RoadmapListSkeleton";

export default function Login() {
    const { publicKey } = useWallet();
    const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);

    return (
        <>
            <main className="min-h-screen">
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 items-center">
                    <>
                        <div className="py-4 px-8 border-b border-gray-700 mt-8">
                            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                <h3 className="text-lg leading-6 font-medium text-white">
                                    Featured Projects
                                </h3>

                                <div className="flex-shrink-0">
                                    <Link
                                        to={``}
                                        type="button"
                                        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-600 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500"
                                    >
                                        View All Featured
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Suspense fallback={<RoadmapListSkeleton />}>
                            <RoadmapsFeatured />
                        </Suspense>
                    </>

                    {!base58 && <ConnectPlaceholder />}

                    {/* {!!base58 && hasNfts && ( */}
                    <>
                        <div className="py-4 px-8 border-b border-gray-700 mt-8">
                            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                <h3 className="text-lg leading-6 font-medium text-white">
                                    My Portfolio
                                </h3>
                            </div>
                        </div>
                        <Suspense fallback={<RoadmapListSkeleton />}>
                            <RoadmapsWallet />
                        </Suspense>
                    </>
                    <>
                        <div className="py-4 px-8 border-b border-gray-700 mt-8">
                            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
                                <h3 className="text-lg leading-6 font-medium text-white">
                                    On-Going Votes
                                </h3>
                            </div>
                        </div>
                        <Suspense fallback={<RoadmapListSkeleton />}>
                            <RoadmapsOngoing />
                        </Suspense>
                        {/* 3 x 3 grid of on-going votes, with vote percentages with call to action. */}
                    </>
                </div>
            </main>
        </>
    );
}
