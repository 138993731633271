import { Combobox } from "@headlessui/react";
import { useContext, useState, useCallback } from "react";
import { DataContext } from "../DataContext";
import { SelectorIcon } from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import LoadingSpinner from "./LoadingSpinner";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function CollectionSearch() {
    const [query, setQuery] = useState("");
    const [selectedCommm, setSelectedComm] = useState<any>("");
    const [realmData, setRealmData] = useState<any[]>([]);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const navigate = useNavigate();

    const search = async (value: string) => {
        // Default options are marked with *
        const res = await fetch(`${process.env.REACT_APP_PHASE_API}/search/`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                value,
            }), // body data type must match "Content-Type" header
        });

        if (res.status === 200) {
            const data = await res.json();
            setRealmData(data.map((d: any) => d.data));
        } else {
            console.warn("Error when searching for roadmap: " + res.statusText);
        }
    };

    const searchHandler = (event: any) => {
        search(event.target.value);
        setIsSearching(false);
    };

    const debouncedSearchHandler = useCallback(
        debounce(searchHandler, 300),
        []
    );

    return (
        <>
            <Combobox
                value={selectedCommm}
                onChange={(event) => {
                    navigate(`/roadmap/${event.address}`);
                }}
                nullable
            >
                <div className="relative text-gray-200">
                    <Combobox.Input
                        placeholder="Search Communities"
                        onChange={(event) => {
                            setIsSearching(true);
                            setQuery(event.target.value);
                            debouncedSearchHandler(event);
                        }}
                        displayValue={(comm: any) => comm?.title}
                        className="w-full border-b border-gray-500 bg-transparent py-2 pl-3 pr-10 shadow-sm focus:border-maroon-flush-500 focus:outline-none focus:ring-1 focus:ring-maroon-flush-500 sm:text-sm"
                    />
                    {isSearching && (
                        <div className="absolute top-2 right-8">
                            <LoadingSpinner size="small" />
                        </div>
                    )}
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#111111] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {realmData.map((comm: any) => (
                            <Combobox.Option
                                key={comm.account.name}
                                value={comm}
                                className={({ active }) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                            ? "bg-maroon-flush-600 text-white"
                                            : "text-gray-200"
                                    )
                                }
                            >
                                {comm.account.name}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            </Combobox>
        </>
    );
}
