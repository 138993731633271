import React, { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { useContext } from "react";
import { DataContext } from "../DataContext";
import { useParams, Link, useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import Editor from "../components/Editor";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    AccountDTO,
    InitPhaseArgs,
    ITransactionNotification,
    Phase,
    PhaseProtocolSDK,
    Roadmap,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import { Observable } from "rxjs";
import toast from "react-hot-toast";
import { truncateKey } from "../libs/utils";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { useRoadmap } from "../state/hooks/roadmaps";

export default function PhaseCreate() {
    const appState = useContext<any>(DataContext);
    let { id } = useParams();
    const sdk = usePhaseSdk();
    const [roadmap] = useRoadmap();

    const navigate = useNavigate();
    const wallet = useWallet();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [solAmount, setSolAmount] = useState(0);
    const [usdcAmount, setUsdcAmount] = useState(0);
    const [feeAmount, setFeeAmount] = useState(0);

    const submitPhase = async (
        name: string,
        solAmount: number,
        usdcAmount: number,
        description: string
    ) => {
        if (!wallet.connected || !wallet.publicKey || roadmap === undefined) {
            return;
        }

        let initPhaseArgs: InitPhaseArgs = {
            name: name,
            taskDescriptionUri: description,
            solDepositAmount: new BN(solAmount * 0.02),
            usdcDepositAmount: new BN(usdcAmount * 0.02),
            phaseIndex: roadmap.account.phaseCount,
            roadmapAddress: roadmap.address,
            usdcPoolAddress: roadmap.account.usdcRoadmapPool,
            solPoolAddress: roadmap.account.solRoadmapPool,
            realmAddress: roadmap.account.realm,
        };

        /*
      Init phase returns an observable that you can use to get notifications on when transactions are being sent, confirmed and errored.
      Once all transactions have been sent and confirmed, the last event to be submited in the stream
      is the account dto 
  */

        const phaseTxStatus$ = await sdk.initPhase(initPhaseArgs);

        const toasts = new Map<string, string>();
        const failed: string[] = [];

        phaseTxStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === "Information") {
                    const toastId = toast.loading(
                        `Sending transaction ${truncateKey(notification.sig)}`
                    );
                    toasts.set(notification.sig, toastId);
                    return;
                }

                if (notification.type === "Success") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.success(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} confirmed`,
                            { id: toastId }
                        );
                    }
                    return;
                }

                if (notification.type === "Error") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.error(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} failed`,
                            {
                                id: toastId,
                            }
                        );
                    }
                    failed.push(notification.sig);
                    return;
                }

                // we have the roadmap object
                if (failed.length > 0) {
                    toast.error(
                        "Roadmap name is already in use. Please try again."
                    );
                } else {
                    toast.success("Roadmap created successfully!");
                    navigate(`/roadmap/${roadmap.address.toBase58()}`);
                }
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    return (
        <>
            <div className="max-w-7xl mx-auto py-8 px-6 lg:px-8">
                <main className="pb-12">
                    <div className="py-8">
                        <div className=" justify-between items-start text-white">
                            <h1 className="text-4xl font-black mb-2">
                                Create New Phase
                            </h1>
                            {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-200 text-gray-800">
                            Draft
                        </span> */}
                        </div>
                        <form
                            className="mt-8"
                            action="/roadmap/new-roadmap-1"
                            onSubmit={(e) => {
                                e.preventDefault();

                                submitPhase(
                                    name,
                                    solAmount,
                                    usdcAmount,
                                    description
                                );
                            }}
                        >
                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                Phase Title
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="phase"
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    className="input"
                                />
                            </div>

                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                Phase Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="description"
                                    rows={8}
                                    className="input"
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                ></textarea>

                                {/* <Editor  /> */}
                            </div>

                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                USDC Payout Amount
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="phase"
                                    type="text"
                                    className="input"
                                    onChange={(e) =>
                                        setUsdcAmount(parseInt(e.target.value))
                                    }
                                />
                            </div>

                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                SOL Payout Amount
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="phase"
                                    type="text"
                                    className="input"
                                    onChange={(e) =>
                                        setSolAmount(parseInt(e.target.value))
                                    }
                                />
                            </div>

                            <input type="hidden" name="phases" value="1" />

                            <div className="mt-8">
                                <button
                                    type="submit"
                                    className="bg-maroon-flush border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-maroon-flush-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </>
    );
}
