import {
    Phase,
    AccountDTO,
    Roadmap,
    Deliverable,
    MintingAccountPlugin,
} from "@dedmonkes/phase-protocol-sdk";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { handleTxNotifications } from "../libs/utils";
import { useDeliverables } from "../state/hooks/deliverables";
import { usePhase } from "../state/hooks/phases";
import { useCurrentRoadmapKey, useRoadmap, useRoadmapsMetadata } from "../state/hooks/roadmaps";
import { totalLockedPhasesQuery } from "../state/phases";
import { solVaultAmountState } from "../state/roadmaps";
import ConfirmationModal from "./ConfirmationModal";
import NextStepsBox from "./NextStepsBox";
import Progress from "./Progress";

const ActionPanel = () => {
    const sdk = usePhaseSdk();
    const [roadmap, setRoadmap] = useRoadmap();
    const [phase, setPhase] = usePhase();
    const { publicKey } = useWallet();
    const [hasMintingPlugin, setHasMintingPlugin] = useState<boolean>(false);
    const wallet = useWallet();
    const [isFounder, setIsFounder] = useState(false);
    const solPhaseVaultAmount = useRecoilValue(
        solVaultAmountState(phase?.account.solPhaseVault.toBase58() as string)
    );
    const [canVote, setCanVote] = useState<boolean>(false);
    const [deliverables] = useDeliverables();
    const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
        useState(false);



    useEffect(() => {
        const getPlugin = async () => {
            if (
                roadmap &&
                roadmap?.account.config.mintingAccountPluginProgramAddress !==
                null
            ) {
                try {
                    const plugin: AccountDTO<MintingAccountPlugin> = await sdk.getMintAccountPlugin(
                        roadmap.address,
                        roadmap?.account.config
                            .mintingAccountPluginProgramAddress
                    );
                    setHasMintingPlugin(true);
                } catch (e: any) { }
            }
        };

        getPlugin();
    }, [publicKey, roadmap]);

    useEffect(() => {
        /**
         * Needs to check nfts quantities
         */
        // if (roadmap && myRoadmaps.length) {
        //   if (
        //     myRoadmaps
        //       .map((rm) => rm.address.toBase58())
        //       .includes(roadmap.address.toBase58())
        //   ) {
        //     setCanVote(true);
        //   }
        // }
        if (roadmap && wallet.publicKey) {
            if (
                wallet.publicKey.toBase58() ===
                roadmap.account.teamAuthority.toString()
            ) {
                setIsFounder(true);
            }
        }
    }, [roadmap, wallet.publicKey]);

    const handlePhaseVeto = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => { };

    const handlePhaseAction = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk.getPhase) {
            return;
        }

        const phaseActionTxStatus$ = await sdk.actionPhase(
            roadmap,
            phase.address
        );
        const toasts = new Map<string, string>();

        phaseActionTxStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase actioned successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const handlePhaseCancel = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.cancelPhase) {
            return;
        }

        const phaseCancelTxStatus$ = await sdk.cancelPhase(roadmap, phase);
        const toasts = new Map<string, string>();

        phaseCancelTxStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase cancelled successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const handleStageForApproval = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }

        const phaseStageApproval$ = await sdk.stagePhaseForApproval(
            roadmap,
            phase
        );
        const toasts = new Map<string, string>();

        phaseStageApproval$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase staged for approval successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const handleStageForCompletion = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>,
        deliverables: AccountDTO<Deliverable>[]
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }
        const hasNonCompletedDeliverables =
            deliverables.filter((x) => !x.account.isComplete).length > 0;
        const hasDeliverablesWithoutProof =
            deliverables.filter((x) => x.account.proofs?.length === 0).length >
            0;

        if (hasDeliverablesWithoutProof) {
            toast.error(
                "Phase has deliverables that do not have any proofs attached."
            );
            return;
        } else if (hasNonCompletedDeliverables) {
            toast.error(
                "Phase has deliverables that are not marked as complete."
            );
            return;
        }

        const phaseStageCompletion$ = await sdk.stagePhaseForCompletion(
            roadmap,
            phase
        );
        const toasts = new Map<string, string>();

        phaseStageCompletion$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase staged for voting successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const handleLock = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }

        

        const phaseLockForMint$ = await sdk.lockPhaseForMint(
            phase.address,
            roadmap
        );
        const toasts = new Map<string, string>();


        phaseLockForMint$.subscribe({
            next: async (notification) => {

                if (notification.type === undefined) {
                    setPhase(notification);
                    const rm = await sdk.getRoadmap(roadmap.address);
                    setRoadmap(rm);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase locked for mint successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const handleUnlock = async (
        roadmap: AccountDTO<Roadmap>,
        phase: AccountDTO<Phase>
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }
        const phaseUnlock$ = await sdk.unlockPhase(roadmap, phase.address);
        const toasts = new Map<string, string>();

        phaseUnlock$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                    const rm = await sdk.getRoadmap(roadmap.address);
                    setRoadmap(rm);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Phase unlocked successfully!",
                    "phase failed"
                );
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    const rmStateObj = useMemo(() => roadmap?.account?.state, [roadmap]);
    const pStateObj = useMemo(() => phase?.account?.state, [phase]);

    const phaseSolVaultDepositAmount = useMemo(
        () => phase?.account?.config.phaseSolVaultDepositAmount,
        [phase]
    );
    const phaseSolVaultDepositAmountFormatted = phaseSolVaultDepositAmount
        ? phaseSolVaultDepositAmount.toNumber() / LAMPORTS_PER_SOL
        : 0;

    const roadmapLockedTotalSol = useMemo(
        () => roadmap?.account.lockedTotalSol,
        [roadmap]
    );
    const roadmapLockedTotalSolFormatted = roadmapLockedTotalSol
        ? roadmapLockedTotalSol.toNumber() / LAMPORTS_PER_SOL
        : 0;

    const poolHasEnoughFundsForLock =
        roadmap &&
        phaseSolVaultDepositAmountFormatted >=
        roadmapLockedTotalSolFormatted +
        phaseSolVaultDepositAmountFormatted;
    const poolHasEnoughFundsForStage =
        roadmap && solPhaseVaultAmount >= roadmapLockedTotalSolFormatted;

    const canLock = useMemo(
        () =>
            ((!!rmStateObj?.draft && !!pStateObj?.draft) ||
                (!!rmStateObj?.resolution && !!pStateObj?.draft)) &&
            isFounder,
        [roadmap, pStateObj, poolHasEnoughFundsForLock, isFounder]
    );

    const phases = useRecoilValue(totalLockedPhasesQuery(roadmap?.address.toBase58() || ""))

    const totalLockedForMint = useMemo(() => phases.reduce((acc, val) => {
        if (val.account.state.lockedForMint) {
            return val.account.config.phaseSolVaultDepositAmount.toNumber();
        }
        return 0;
    }, 0), [phases])
    const totalDepositRequired = useMemo(() => phases.reduce((acc, val) => acc + val.account.config.phaseSolVaultDepositAmount.toNumber(), 0), [phases]);

    const healthFactor = useMemo(() => totalLockedForMint / totalDepositRequired, [totalLockedForMint, totalDepositRequired])
    const healthOK = totalDepositRequired * .30;
    const healthWarning = totalDepositRequired * .50;
    const healthBad = totalDepositRequired * 0.70;


    const futureHealthFactor = useMemo(() => {
        if (!phaseSolVaultDepositAmount) {
            return null;
        }

        return (totalLockedForMint + phaseSolVaultDepositAmount.toNumber()) / totalDepositRequired;
    }, [phaseSolVaultDepositAmount])

    const getHealthFriendlyName = (n: number) => {
        if (n <= healthOK) {
            return "Low"
        }
        if (n > healthOK && n <= healthWarning) {
            return "Risky"
        }
        if (n <= healthBad) {
            return "Extreme"
        }
    }

    const canUnlock = useMemo(
        () =>
            !!rmStateObj?.resolution && !!pStateObj?.lockedForMint && isFounder,
        [roadmap, pStateObj, isFounder]
    );
    const canCancel = useMemo(
        () =>
            ((!!rmStateObj?.draft && !!pStateObj?.draft) ||
                (!!rmStateObj?.resolution && !!pStateObj?.draft) ||
                (!!rmStateObj?.actioned && !!pStateObj?.draft) ||
                (!!rmStateObj?.actioned && !!pStateObj?.approved) ||
                (!!rmStateObj?.actioned && !!pStateObj?.actioned)) &&
            isFounder,
        [roadmap, pStateObj, isFounder]
    );
    const canAction = useMemo(
        () => !!rmStateObj?.actioned && !!pStateObj?.approved && isFounder,
        [roadmap, pStateObj, isFounder]
    );
    const canStage = useMemo(
        () =>
            !!rmStateObj?.actioned &&
            !!pStateObj?.draft &&
            poolHasEnoughFundsForStage,
        [roadmap, pStateObj, poolHasEnoughFundsForStage]
    );
    const canApprove = useMemo(
        () =>
            (!!rmStateObj?.actioning && !!pStateObj?.lockedForMint) ||
            (!!rmStateObj?.actioned && !!pStateObj?.stagedForApproval) ||
            (!!rmStateObj?.actioned && !!pStateObj?.lockedForMint) ||
            (!!rmStateObj?.actioned && !!pStateObj?.actioned),
        [roadmap, pStateObj]
    );
    const canComplete = useMemo(
        () => !!rmStateObj?.actioned && !!pStateObj?.actioned && isFounder,
        [roadmap, isFounder, pStateObj]
    );

    return (
        <>
            {roadmap && phase && !rmStateObj?.refunding && isFounder && (
                <>
                    <NextStepsBox>
                        {pStateObj?.approved && isFounder ? (
                            <p>
                                This phase is currently in approved state. You
                                can now action your phase to show you are
                                currently working on it
                            </p>
                        ) : (
                            <></>
                        )}
                        {pStateObj?.actioned && isFounder ? (
                            <p>
                                This phase is currently in actioned state,
                                meaning you are actively working on completing
                                the phase. You may add proof to your
                                deliverables and mark them as complete as you
                                finish these tasks.
                            </p>
                        ) : (
                            <></>
                        )}

                        {pStateObj?.stagedForApproval && isFounder ? (
                            <p>
                                This draft phase is currently up for veto
                                voting. If threshold is not met the item can be
                                approved into the roadmap.
                            </p>
                        ) : (
                            <></>
                        )}
                        {pStateObj?.stagedForCompletion && isFounder ? (
                            <p>
                                This phase is currently up for veto voting. If
                                threshold is not met the payout will be made to
                                the team.
                            </p>
                        ) : (
                            <></>
                        )}
                        {pStateObj?.draft &&
                            rmStateObj?.actioned &&
                            isFounder ? (
                            <p>
                                This is a draft phase this is which has not been
                                accepted into the roadmap. You can intiate a
                                veto vote for acceptance into the roadmap by
                                staging the phase.
                            </p>
                        ) : (
                            <></>
                        )}

                        {pStateObj?.draft && rmStateObj?.draft && isFounder ? (
                            <p>
                                This draft phase will be included in your
                                roadmap when finalized. Once finalized your phase
                                cannot be changed untill fundraising is complete.
                            </p>
                        ) : (
                            <></>
                        )}

                        {/* {pStateObj?.draft && rmStateObj?.draft && isFounder ? (
                            <p>
                                This draft phase will not be included in your
                                revised roadmap if you wish to add it proceed to
                                locking the phase. This is only possib
                            </p>
                        ) : (
                            <></>
                        )} */}

                        {pStateObj?.lockedForMint &&
                            rmStateObj?.resolution &&
                            isFounder ? (
                            <p>
                                This locked phase will be included in the voting
                                on the revised roadmap once the roadmap is
                                staged for resolution. You may edit this phase
                                freely to allow your roadmap captial
                                requirements to meet your collected mint funds.
                            </p>
                        ) : (
                            <></>
                        )}

                        {pStateObj?.complete && isFounder ? (
                            <p>
                                This phase is complete and payout has been made
                                to the team.
                            </p>
                        ) : (
                            <></>
                        )}

                        {canAction && (
                            <p>
                                <button
                                    onClick={(e) =>
                                        handlePhaseAction(roadmap, phase)
                                    }
                                    className="btn"
                                >
                                    Action Phase
                                </button>
                            </p>
                        )}

                        {canStage && (
                            <p>
                                <button
                                    onClick={(e) =>
                                        handleStageForApproval(roadmap, phase)
                                    }
                                    className="btn"
                                >
                                    Stage for Approval
                                </button>
                            </p>
                        )}

                        {canComplete && (
                            <p>
                                <button
                                    onClick={(e) =>
                                        handleStageForCompletion(
                                            roadmap,
                                            phase,
                                            deliverables
                                        )
                                    }
                                    className="btn"
                                >
                                    Initiate Completion Vote
                                </button>
                            </p>
                        )}

                        {canUnlock && (
                            <p>
                                <button
                                    onClick={(e) =>
                                        handleUnlock(roadmap, phase)
                                    }
                                    className="btn"
                                >
                                    Revert to draft
                                </button>
                            </p>
                        )}

                        {/* {canLock && (
                            <>
                                <p className="mt-5 text-white text-bold mb-3">Treasury Health Preview</p>
                                <table className="table-fixed gap-3" style={{ width: "50%" }}>
                                    <tbody>
                                        <tr>
                                            <td>Risk Before Lock</td>
                                            <td className="text-right"><strong>{getHealthFriendlyName(healthFactor)}</strong> ({(healthFactor * 100).toFixed(2)}%)</td>
                                        </tr>
                                        <tr>
                                            <td>Risk After Lock</td>
                                            <td className="text-right"><strong>{futureHealthFactor && getHealthFriendlyName(futureHealthFactor)}</strong> ({futureHealthFactor && (futureHealthFactor * 100).toFixed(2)}%)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {futureHealthFactor && getHealthFriendlyName(futureHealthFactor) === "Low" && <p className="mt-3">
                                    Even if you finalize this phase, your treasury risk level remains low.     
                                </p>}
                                {futureHealthFactor && getHealthFriendlyName(futureHealthFactor) === "Risky" && <p className="mt-3">
                                    Finalizing this phase brings your treasury health factor in the risky range. Only lock this phase if you are sure you can raise enough funds to cover it.  
                                </p>}
                                {futureHealthFactor && getHealthFriendlyName(futureHealthFactor) === "Extreme" && <p className="mt-3">
                                    You can't finalize this phase, because it brings your treasury health factor into the extremely risky range. Consider reducing your overall treasury allocation. You can always allocate more phases after the mint is complete!
                                </p>}
                            </>
                        )} */}

                        {canLock && (
                            <p>
                                <button
                                    onClick={(e) => handleLock(roadmap, phase)}
                                    className="btn"
                                    disabled={!hasMintingPlugin || getHealthFriendlyName(futureHealthFactor || 1) === "Extreme"}
                                >
                                    Finalize Phase
                                </button>
                                {!hasMintingPlugin && <p className="mt-2">You are unable to lock this phase because no minting plugin is selected.</p>}
                            </p>
                        )}

                        {canCancel && isFounder && (
                            <p>
                                <button
                                    onClick={(e) =>
                                        setIsCancelConfirmationModalOpen(true)
                                    }
                                    className="btn btn-gray"
                                >
                                    Cancel Phase
                                </button>
                            </p>
                        )}
                    </NextStepsBox>
                    <ConfirmationModal
                        title="Confirm Removal of Phase"
                        message="This will remove the phase from the roadmap. This is not reversible!!"
                        onClose={() => setIsCancelConfirmationModalOpen(false)}
                        show={isCancelConfirmationModalOpen}
                        onConfirmation={() => handlePhaseCancel(roadmap, phase)}
                    />
                </>
            )}
        </>
    );
};

export default function PhaseHeader({ }: {}) {
    const [roadmap, setRoadmap] = useRoadmap();
    const [phase, setPhase] = usePhase();
    const [roadmapAddress] = useCurrentRoadmapKey();
    const metadataMap = useRoadmapsMetadata();
    const [metadata, setMetadata] = useState<any>(undefined);

    useEffect(() => {
        const getMetadata = async () => {
            if (roadmapAddress === null) {
                return;
            }
            const meta = metadataMap[roadmapAddress];
            setMetadata(meta);
        };
        getMetadata();
    }, [roadmapAddress, metadataMap]);


    return (
        <>
            {phase && roadmap && metadata ? (
                <>
                    <Link
                        to={`/roadmap/${phase?.account.roadmap}`}
                        className="flex items-center relative gap-4 mb-6"
                    >
                        <div className="rounded-lg w-12 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-maroon-flush-500 overflow-hidden">
                            <img
                                src={metadata.image as unknown as string}
                                alt=""
                                className="object-cover pointer-events-none group-hover:opacity-75"
                            />
                        </div>
                        <h3 className="block text-base font-medium text-white truncate pointer-events-none">
                            {roadmap?.account.name}
                        </h3>
                    </Link>

                    <div className="justify-between items-start">
                        <div className="flex">
                            <h1 className="text-4xl font-white text-white font-black mb-4">
                                {phase?.account.config.name}{" "}
                            </h1>
                        </div>

                        <>
                            <Progress status={phase?.account.state} />
                        </>
                    </div>
                    <ActionPanel />
                    <div
                        className="prose prose-invert prose-md max-w-full text-gray-200 mt-6"
                        dangerouslySetInnerHTML={{
                            __html:
                                phase?.account.config.taskDescriptionLink || "",
                        }}
                    ></div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
