import { Connection } from "@solana/web3.js";

export const connection = new Connection(process.env.REACT_APP_SOLANA_RPC!, {
    commitment: "confirmed",
});
export const prodConn = new Connection(process.env.REACT_APP_MAINNET_RPC!, {
    commitment: "max",
});
export const RPC_URL_DEFAULT = process.env.REACT_APP_SOLANA_RPC!;
export const BROKEN_TEAM_AUTHORITIES = [
    "9zgReUrEZtdaT1egD2LmZ177qtK6WRy1LfACLhz8piaF",
    "7sKQeTWAxkzaHihUqHBV8VTxjo4dmpYWFr2k1qXAgEsk",
];
