import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    AccountDTO,
    InitPhaseArgs,
    Phase,
    PhaseConfig,
    PhaseProtocolSDK,
    Roadmap,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import toast from "react-hot-toast";
import { truncateKey } from "../libs/utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
    isCreatingPhase as phaseCreate,
    phases as phaseState,
} from "../state/roadmaps";
import { usePhase, useRefreshPhases } from "../state/hooks/phases";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { lastValueFrom } from "rxjs";
import { useRoadmap } from "../state/hooks/roadmaps";

export default function PhaseCreateEditForm({
    phase,
    setIsEditingPhase,
}: {
    phase?: AccountDTO<Phase>;
    setIsEditingPhase?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    let { id } = useParams();
    const wallet = useWallet();
    const [roadmap, setRoadmap] = useRoadmap();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [solAmount, setSolAmount] = useState(0);
    const [usdcAmount, setUsdcAmount] = useState(0);
    const [, setIsCreatingPhase] = useRecoilState(phaseCreate);
    const refreshPhases = useRefreshPhases();

    const currencyOpts = ["SOL", "USDC"];
    const [currency, setCurrency] = useState(currencyOpts[0]);
    const [, setPhases] = usePhase();
    const sdk = usePhaseSdk();

    useEffect(() => {
        if (phase) {
            setName(phase?.account.config.name);
            setDescription(phase?.account.config.taskDescriptionLink);
            setSolAmount(
                phase?.account.config.phaseSolVaultDepositAmount.toNumber() /
                LAMPORTS_PER_SOL
            );
            setUsdcAmount(
                phase?.account.config.phaseUsdcVaultDepositAmount.toNumber()
            );
        }
    }, [phase]);

    const submitPhase = async (
        name: string,
        solAmount: number,
        usdcAmount: number,
        description: string
    ) => {
        if (!wallet.connected || !wallet.publicKey) {
            return;
        }

        let roadmap: AccountDTO<Roadmap> = await sdk.getRoadmap(
            new PublicKey(id as string)
        );

        let initPhaseArgs: InitPhaseArgs = {
            name: name,
            taskDescriptionUri: description,
            solDepositAmount: new BN(solAmount * LAMPORTS_PER_SOL),
            usdcDepositAmount: new BN(usdcAmount),
            phaseIndex: roadmap.account.phaseCount,
            roadmapAddress: roadmap.address,
            usdcPoolAddress: roadmap.account.usdcRoadmapPool,
            solPoolAddress: roadmap.account.solRoadmapPool,
            realmAddress: roadmap.account.realm,
        };

        /*
      Init phase returns an observable that you can use to get notifications on when transactions are being sent, confirmed and errored.
      Once all transactions have been sent and confirmed, the last event to be submited in the stream
      is the account dto 
  */

        const phaseTxStatus$ = await sdk.initPhase(initPhaseArgs);

        const toasts = new Map<string, string>();
        const failed: string[] = [];

        phaseTxStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhases(notification);
                }

                if (notification.type === "Information") {
                    const toastId = toast.loading(
                        `Sending transaction ${truncateKey(notification.sig)}`
                    );
                    toasts.set(notification.sig, toastId);
                    return;
                }

                if (notification.type === "Success") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.success(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} confirmed`,
                            { id: toastId }
                        );
                    }
                    return;
                }

                if (notification.type === "Error") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.error(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} failed`,
                            {
                                id: toastId,
                            }
                        );
                    }
                    failed.push(notification.sig);
                    return;
                }

                // we have the roadmap object
                if (failed.length > 0) {
                    toast.error(
                        "Roadmap name is already in use. Please try again."
                    );
                } else {
                    toast.success("Roadmap created successfully!");
                    // navigate(`/roadmap/${roadmap.address.toBase58()}`);
                }
                setIsCreatingPhase(false);
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
                setIsCreatingPhase(false);
            },
        });
    };

    const editPhase = async (
        name: string,
        solAmount: number,
        usdcAmount: number,
        description: string
    ) => {
        if (!wallet.connected || !wallet.publicKey || !phase || !roadmap) {
            return;
        }

        const newPhaseConfig: PhaseConfig = {
            name: name,
            taskDescriptionLink: description,
            phaseSolVaultDepositAmount: new BN(solAmount * LAMPORTS_PER_SOL),
            phaseUsdcVaultDepositAmount: new BN(usdcAmount),
        };

        let phaseTxStatus$;
        if (phase?.account.state?.draft) {
            phaseTxStatus$ = await sdk.setPhaseConfig(
                phase.address,
                newPhaseConfig,
                roadmap
            );
        } else if (roadmap?.account.state?.resolution) {
            const phaseCopy = { ...phase };
            const phaseAccountCopy = { ...phaseCopy.account };

            phaseAccountCopy.config = newPhaseConfig;
            phaseCopy.account = phaseAccountCopy;

            phaseTxStatus$ = await sdk.setLockedPhaseConfigInResolution(
                phase.address,
                roadmap,
                phaseCopy
            );
        }

        const toasts = new Map<string, string>();
        const failed: string[] = [];
        if (!phaseTxStatus$) return;
        phaseTxStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    const rm = await sdk.getRoadmap(roadmap.address);
                    setRoadmap(rm);
                    setPhases(notification);
                }

                if (notification.type === "Information") {
                    const toastId = toast.loading(
                        `Sending transaction ${truncateKey(notification.sig)}`
                    );
                    toasts.set(notification.sig, toastId);
                    return;
                }

                if (notification.type === "Success") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.success(
                            `Phase update ${truncateKey(
                                notification.sig
                            )} confirmed`,
                            { id: toastId }
                        );
                    }
                    return;
                }

                if (notification.type === "Error") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.error(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} failed`,
                            {
                                id: toastId,
                            }
                        );
                    }
                    failed.push(notification.sig);
                    return;
                }
            },
            error: (error) => {
                console.log(error);
                toast.error(error.toString());
            },
            complete: () => {
                refreshPhases();

                if (setIsEditingPhase) setIsEditingPhase(false);
            },
        });
    };

    // match with roadmapTeamAuthority

    return (
        <>
            <div className="">



                <form
                    className="mt-8"
                    action="/roadmap/new-roadmap-1"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (phase) {
                            editPhase(name, solAmount, usdcAmount, description);
                        } else {
                            submitPhase(
                                name,
                                solAmount,
                                usdcAmount,
                                description
                            );
                        }
                    }}
                >

                    <label
                        htmlFor="email"
                        className="mt-8 block font-medium text-gray-200"
                    >
                        Phase Title
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="phase"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="input"
                        />
                    </div>

                    <label
                        htmlFor="email"
                        className="mt-8 block font-medium text-gray-200"
                    >
                        Phase Description
                    </label>
                    <div className="mt-2">
                        <textarea
                            name="description"
                            rows={8}
                            className="input"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>

                        {/* <Editor  /> */}
                    </div>


                    <label className="inline-flex relative items-center cursor-pointer mt-5">
                        <input onClick={() => {
                            if (currency === "USDC") {
                                setCurrency("SOL");
                            } else {
                                setCurrency("USDC");
                            }
                        }} type="checkbox" value="" className="sr-only peer" />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Currency: {currency}</span>
                    </label>


                    {currency === "USDC" && (
                        <>
                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                USDC Payout Amount
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="phase"
                                    type="number"
                                    className="input"
                                    value={usdcAmount}
                                    onChange={(e) =>
                                        setUsdcAmount(parseInt(e.target.value))
                                    }
                                />
                            </div>
                        </>
                    )}
                    {currency === "SOL" && (
                        <>
                            <label
                                htmlFor="email"
                                className="mt-8 block font-medium text-gray-200"
                            >
                                SOL Payout Amount
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="phase"
                                    type="number"
                                    step={0.01}
                                    className="input"
                                    value={solAmount}
                                    onChange={(e) =>
                                        setSolAmount(parseFloat(e.target.value))
                                    }
                                />
                            </div>
                        </>
                    )}

                    <input type="hidden" name="phases" value="1" />

                    <div className="mt-8">
                        <button
                            type="submit"
                            className="bg-maroon-flush border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-maroon-flush-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
