import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Communities from "./pages/Communities";
import Community from "./pages/Community";

import RoadmapView from "./partials/Roadmap";
import PhaseView from "./partials/Phase";
import Feed from "./partials/Feed";

import RoadmapCreate from "./partials/RoadmapCreate";
import Login from "./pages/Login";
import PhaseCreate from "./partials/PhaseCreate";

import { Toaster } from "react-hot-toast";

// import { Roadmap, AccountDTO } from "@dedmonkes/phase-protocol-sdk";
import Nav from "./components/Nav";
import JupiterSwap from "./pages/JupiterSwap";
import LoadingSpinner from "./components/LoadingSpinner";
import { Link } from "react-router-dom";
import { TOS } from "./pages/TOS";
import Footer from "./partials/Footer";
import RefundVotePanel from "./components/RefundVotePanel";
import { FirebaseDataProvider } from "./context/FirebaseDataProvider";

export default function Layout() {
    return (
        <div className="bg-[#101010] min-h-screen font-poppins">
            

            <BrowserRouter>
                <Nav />
                <FirebaseDataProvider>
                    <RefundVotePanel/>
                </FirebaseDataProvider>

                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/feed" element={<Feed />} />
                    <Route path="/roadmap/new" element={<RoadmapCreate />} />
                    <Route path="/communities" element={<Communities />} />
                    <Route path="/community/:id/*" element={<Community />}>
                        {/* <Route path="" element={<Roadmap />} /> */}
                        {/* <Route path="roadmap" element={<Proposals/>} /> */}
                        {/* <Route path="roadmap/:pid" element={<Roadmap />} /> */}
                        {/* <Route path="roadmap/:pid/new" element={<PhaseCreate />} /> */}
                        {/* <Route path="feed" element={<Feed />} /> */}
                    </Route>
                    <Route path="" element={<RoadmapView />} />
                    <Route path="roadmap/:id" element={<RoadmapView />} />
                    <Route
                        path="roadmap/:id/phase/new"
                        element={<PhaseCreate />}
                    />
                    <Route
                        path="roadmap/:id/phase/:pid"
                        element={<PhaseView />}
                    />
                    <Route path="feed" element={<Feed />} />
                    <Route path="/jupiter-swap" element={<JupiterSwap />} />
                    <Route path="/terms-of-service" element={<TOS />} />
                </Routes>
                <Footer />
            </BrowserRouter>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        backgroundColor: "#000",
                        color: "#fff",
                        border: "2px solid rgb(148 34 91)",
                    },
                }}
            />
        </div>
    );
}
