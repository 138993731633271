import { useEffect, useState } from "react";
import {
    AccountDTO,
    Roadmap,
    MintingAccountPlugin,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { useWallet } from "@solana/wallet-adapter-react";
import { LockClosedIcon } from "@heroicons/react/outline";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import {
    useRefreshSolPoolAmount,
    useRefreshUsdcPoolAmount,
    useRoadmap,
    useTreasuryTotal,
} from "../state/hooks/roadmaps";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { getProgressPerc, handleTxNotifications } from "../libs/utils";
import { useRefreshPhases } from "../state/hooks/phases";
import NextStepsBox from "./NextStepsBox";
import { async } from "rxjs";

const RoadmapLockedNotice = ({}: {}) => {
    const wallet = useWallet();
    const [roadmap, setRoadmap] = useRoadmap();
    const [mintingInfo, setMintingInfo] =
        useState<AccountDTO<MintingAccountPlugin>>();
    const [canUnlock, setCanUnlock] = useState<boolean>(false);
    const sdk = usePhaseSdk();
    const refreshPhases = useRefreshPhases();
    const [solTreasuryTotal] = useTreasuryTotal();
    const [solPoolAmount, setSolPoolAmount] = useState(0);
    const refreshSolPool = useRefreshSolPoolAmount();
    const refreshUsdcPool = useRefreshUsdcPoolAmount();

    useEffect(() => {
        if (!roadmap) return;
        const getSolPoolBalance = async () => {
            if (roadmap.account.solRoadmapPool) {
                const solPoolValue = await connection.getAccountInfo(
                    roadmap.account.solRoadmapPool
                );
                if (solPoolValue) {
                    setSolPoolAmount(solPoolValue.lamports / LAMPORTS_PER_SOL);
                }
            }
        };
        getSolPoolBalance();
    }, [roadmap]);

    useEffect(() => {
        const getMintingPlugin = async () => {
            if (!roadmap?.account.config.mintingAccountPluginProgramAddress)
                return;
            try {
                const mintProgramPluginAccount: AccountDTO<MintingAccountPlugin> =
                    await sdk.getMintAccountPlugin(
                        roadmap.address,
                        roadmap.account.config
                            .mintingAccountPluginProgramAddress
                    );
                if (mintProgramPluginAccount) {
                    setMintingInfo(mintProgramPluginAccount);
                    if (
                        mintProgramPluginAccount.account.isClosed &&
                        wallet.publicKey?.toBase58() ===
                            roadmap.account.teamAuthority.toBase58() &&
                        roadmap.account.state?.lockedForMint
                    ) {
                        setCanUnlock(true);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        getMintingPlugin();
    }, [roadmap]);
    // Fetch the plugin interface of the minting program
    // Shows information on the minting process
    const unlockRoadmap = async (roadmap: AccountDTO<Roadmap> | undefined) => {
        if (roadmap) {
            const toasts = new Map<string, string>();

            const unlockRoadmap$ = await sdk.unlockRoadmap(roadmap);
            // @ts-ignore
            unlockRoadmap$.subscribe({
                next: async (notification) => {
                    if (notification.type === undefined) {
                        setRoadmap(notification);
                    }
                    await handleTxNotifications(
                        notification,
                        toasts,
                        "Unlocked Roadmap!",
                        "Unlock Roadmap failed"
                    );
                },
                complete: (data: any) => {
                    refreshPhases();
                    refreshSolPool();
                    refreshUsdcPool();
                },
            });
        }
    };

    const getFundsRaised = (
        mintingInfo: AccountDTO<MintingAccountPlugin>
    ): number => {
        return (
            (mintingInfo.account.price.toNumber() / LAMPORTS_PER_SOL) *
            (mintingInfo.account.maxSupply.toNumber() -
                mintingInfo.account.quantityLeft.toNumber())
        );
    };

    return (
        <>
            {roadmap?.account.state?.lockedForMint ? (
                <>
                    {canUnlock ? (
                        <NextStepsBox>
                            <p>
                                Now that you succesfully minted enough NFTs to
                                fulfill the total amount of sol in each of the
                                phases you can unlock the roadmap which will
                                mark each phase as "approved". Approved phases
                                allow you to start the process of unlocking
                                funds as you move each phase to actioned.
                            </p>
                        </NextStepsBox>
                    ) : (
                        <></>
                    )}
                    <div className="bg-[#111111] rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-lg border-2 border-maroon-flush transform transition-all sm:mb-12 w-full sm:p-6 mt-6">
                        <LockClosedIcon
                            className="h-6 w-6 text-gray-400 absolute right-6"
                            aria-hidden="true"
                        />

                        <div className="text-left">
                            <h3 className="text-lg font-medium text-gray-200">
                                Roadmap Locked
                            </h3>

                            {canUnlock ? (
                                <div className="mt-4">
                                    <p className="text-gray-200">
                                        Congratulations on finishing mint!
                                    </p>

                                    {!!mintingInfo && (
                                        <>
                                            <p className=" text-gray-200 gap-4 leading-loose">
                                                You raised:{" "}
                                                <span className="sol inline-block align-middle mr-[2px]">
                                                    ◎
                                                </span>
                                                {solTreasuryTotal +
                                                    solPoolAmount}
                                            </p>
                                            <hr className="border border-white opacity-10 my-5" />

                                            {
                                                <p className="flex justify-between items-center">
                                                    <button
                                                        onClick={(e) =>
                                                            unlockRoadmap(
                                                                roadmap
                                                            )
                                                        }
                                                        className="btn btn-fill !mt-0"
                                                    >
                                                        Proceed
                                                    </button>
                                                </p>
                                            }
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="mt-4">
                                    <p className="text-gray-200">
                                        The roadmap is currently minting. As
                                        soon as the contract is complete
                                        <br /> the roadmap can begin starting
                                        with phases below.
                                    </p>

                                    {!!mintingInfo && (
                                        <>
                                            <hr className="border border-white opacity-10 my-5" />
                                            <p className=" text-gray-200 mt-4 gap-4 leading-loose">
                                                Mint Price:{" "}
                                                <span className="sol inline-block align-middle mr-[2px]">
                                                    ◎
                                                </span>
                                                {mintingInfo.account.price.toNumber() /
                                                    LAMPORTS_PER_SOL}
                                            </p>

                                            <div className="text-left text-sm text-gray-200 my-2 gap-4 leading-loose flex justify-between items-center">
                                                <p>{`Quantity Remaining: ${mintingInfo.account.quantityLeft}`}</p>
                                                <p>{`Max Supply: ${mintingInfo.account.maxSupply}`}</p>
                                            </div>

                                            <div className="w-full bg-gray-500 rounded-full h-2.5 bg-opacity-50">
                                                <div
                                                    className="bg-maroon-flush-500 h-2.5 rounded-full"
                                                    style={{
                                                        width: getProgressPerc(
                                                            mintingInfo.account.maxSupply.sub(
                                                                mintingInfo
                                                                    .account
                                                                    .quantityLeft
                                                            ),
                                                            mintingInfo.account
                                                                .maxSupply
                                                        ),
                                                    }}
                                                ></div>
                                            </div>

                                            {/* <p className="text-left text-gray-200">
                  Collection:{" "}
                  <span className="underline">Official Collection Name</span>
                </p> */}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};
export default RoadmapLockedNotice;
