import { truncateKey } from "../libs/utils";
import { usePhase } from "../state/hooks/phases";

export default function PhaseInfo() {
    const network = "?cluster=devnet";
    // check for other data to show here
    // name prop added to roadmap so we can ditch realm
    const [phase] = usePhase();
    return (
        <>
            {phase ? (
                <div className="py-2 px-0 leading-3 text-white">
                    <table className="table table-auto w-full">
                        <tbody>
                            <tr className="py-2 flex justify-between">
                                <td>Roadmap</td>
                                <td>
                                    <a
                                        href={`https://solscan.io/account/${phase.account.roadmap.toString()}/${network}`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        {truncateKey(
                                            phase.account.roadmap.toString()
                                        )}
                                    </a>
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>DAO Auth</td>
                                <td>
                                    <a
                                        href={`https://solscan.io/account/${phase.account.doaAccountAuthority.toString()}/${network}`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        {truncateKey(
                                            phase.account.doaAccountAuthority.toString()
                                        )}
                                    </a>
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>USDC Vault</td>
                                <td>
                                    <a
                                        href={`https://solscan.io/account/${phase.account.usdcPhaseVault.toString()}/${network}`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        {truncateKey(
                                            phase.account.usdcPhaseVault.toString()
                                        )}
                                    </a>
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>SOL Vault</td>
                                <td>
                                    <a
                                        href={`https://solscan.io/account/${phase.account.solPhaseVault.toString()}/${network}`}
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                    >
                                        {truncateKey(
                                            phase.account.solPhaseVault.toString()
                                        )}
                                    </a>
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>Revision Count</td>
                                <td>{phase.account.revisionCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
