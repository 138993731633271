const RoadmapRefundSkeleton = () => (
    <div className="animate-pulse">
        <div className="p-8 relative rounded-md max-w-4xl bg-[#111111] shadow-md border-2 border-gray-700 block my-4">
            <div className="h-4 bg-gray-700 rounded w-60 mb-6"></div>
            <div className="h-4 bg-gray-700 rounded-full w-6 absolute top-6 right-6"></div>
            <div className="h-3 bg-gray-700 rounded w-96 mb-4"></div>
            <div className="h-3 bg-gray-700 rounded w-96 mb-4"></div>
            <div className="h-3 bg-gray-700 rounded w-60"></div>
            <div className="h-10 bg-gray-700 rounded w-24 mt-12"></div>
        </div>
    </div>
);
export default RoadmapRefundSkeleton;
