import {
    Jupiter,
    LAMPORTS_PER_SIGNATURE,
    RouteInfo,
    TOKEN_LIST_URL,
} from "@jup-ag/core";
import { Tooltip } from "../components/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import debounce from "lodash/debounce";
import { prodConn } from "../constants";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import JSBI from "jsbi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export interface JupiterToken {
    chainId: number; // 101,
    address: string; // 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    symbol: string; // 'USDC',
    name: string; // 'Wrapped USDC',
    decimals: number; // 6,
    logoURI: string; // 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
    tags: string[]; // [ 'stablecoin' ]
}
export const WRAPPED_SOL: string =
    "So11111111111111111111111111111111111111112";
export const SHDW_TOKEN: string = "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y";

export default function JupiterSwap() {
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();

    const [amount] = watch(["solInput"]);
    const navigate = useNavigate();
    const wallet = useWallet();

    const { signAllTransactions, sendTransaction, signTransaction } =
        useWallet();

    const [route, setRoute] = useState<RouteInfo>();

    const [tokens, setTokens] = useState<JupiterToken[]>([]);
    const [jupiter, setJupiter] = useState<Jupiter>();

    const createJupiterObject = useCallback(async () => {
        const j = await Jupiter.load({
            connection: prodConn,
            cluster: "mainnet-beta",
            routeCacheDuration: 1000 * 100,
            user: wallet?.publicKey || undefined,
        });
        setJupiter(j);
    }, [wallet]);

    const inputToken = new PublicKey(WRAPPED_SOL);
    const outputToken = new PublicKey(SHDW_TOKEN);

    const init = useCallback(async () => {
        createJupiterObject();
    }, [createJupiterObject]);

    useEffect(
        () => {
            init();
        },
        [
            /* eslint-disable-line react-hooks/exhaustive-deps */
        ]
    );

    const debouncedRouteCompute = useRef(
        debounce(
            async (
                jupiter: Jupiter,
                inputMint: PublicKey,
                outputMint: PublicKey,
                amount: number
            ) => {
                const r = await jupiter.computeRoutes({
                    inputMint,
                    outputMint,
                    slippage: 1,
                    amount: JSBI.BigInt(
                        Math.round(Number(amount) * LAMPORTS_PER_SOL)
                    ),
                });

                let newRoute;

                for (const route of r.routesInfos) {
                    const markets = route.marketInfos;
                    const outputFee =
                        JSBI.toNumber(
                            markets[markets.length - 1].platformFee.amount
                        ) > 0;
                    if (!outputFee) {
                        continue;
                    }
                    newRoute = route;
                    break;
                }

                if (!newRoute) {
                    newRoute = r.routesInfos[0];
                }
                setRoute(newRoute);
            },
            250
        )
    ).current;

    const handleJupiterSwap = useCallback(async () => {
        if (!jupiter || !wallet || !wallet.publicKey) {
            toast.error("Jupiter or wallet pubkey not found");
            return;
        }

        const toastId0 = toast.loading(`Swapping ${amount} SOL to SHDW...`);

        if (!route) {
            toast.error(`Failed to find route to swap SOL to SHDW.`, {
                id: toastId0,
            });
            return;
        }

        try {
            const { execute, transactions } = await jupiter.exchange({
                routeInfo: route,
                userPublicKey: wallet.publicKey,
            });

            const { setupTransaction, cleanupTransaction } = transactions;

            let txsNeeded = 1;

            if (setupTransaction) {
                txsNeeded++;
            }

            if (cleanupTransaction) {
                txsNeeded++;
            }

            let txsSent = 0;

            const swapResult: any = await execute({
                wallet: {
                    sendTransaction,
                    signAllTransactions,
                    signTransaction,
                } as any,
                onTransaction: async () => {
                    txsSent++;
                    if (txsNeeded === 1) {
                        console.log(`Confirming transaction...`);
                    } else {
                        console.log(
                            `Confirming transaction ${txsSent} of ${txsNeeded}...`
                        );
                    }
                },
            });

            if (swapResult.error) {
                toast.error(`Error performing swap: ${swapResult.error}`, {
                    id: toastId0,
                });
                return;
            } else {
                toast.success(`Successfully swapped SOL to SHDW!`, {
                    id: toastId0,
                });
                navigate("/roadmap/new");
            }
        } catch (err) {
            console.log((err as any).stack);
            console.log(err);
            toast.error(
                `Error performing Jupiter swap: ${(err as any).toString()}`,
                {
                    id: toastId0,
                }
            );
            throw err;
        }
    }, [
        outputToken,
        jupiter,
        wallet.publicKey,
        route,
        sendTransaction,
        signAllTransactions,
        signTransaction,
    ]);
    const fetchRoutes = useCallback(async () => {
        const required = [jupiter, inputToken, outputToken];

        if (required.some((i) => !i) || amount === 0) {
            setRoute(undefined);
            return;
        }

        if (jupiter) {
            try {
                debouncedRouteCompute(
                    jupiter,
                    new PublicKey(inputToken),
                    new PublicKey(outputToken),
                    amount
                );
            } catch (err) {
                console.error(err);
            }
        }
    }, [
        // eslint-disable-line react-hooks/exhaustive-deps
        amount,
        inputToken,
        jupiter,
        outputToken,
    ]);

    useEffect(() => {
        fetchRoutes();
    });

    return (
        <>
            <main className="">
                <div className="max-w-5xl mx-auto py-8 px-6 lg:px-8 mt-10">
                    {/* <Breadcrumbs pages={pages}/> */}
                    <h1 className="text-2xl font-white text-white font-black mb-4">
                        Swap SOL → SHDW
                    </h1>

                    <div className="block lg:flex gap-16 pb-12 mt-12">
                        <div className="flex-auto">
                            <div className="relative">
                                <p className="text-white">
                                    You'll need a small amount of SHDW in order
                                    to set up your metadata accounts (required
                                    for phase). $1-2 worth is typically more
                                    than enough.
                                </p>

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleJupiterSwap();
                                    }}
                                >
                                    <>
                                        <label
                                            htmlFor="roadmapTitle"
                                            className="mt-8 flex items-center font-medium text-gray-200"
                                        >
                                            You pay
                                            <Tooltip text="Amount of SOL you'd like to trade for SHDW">
                                                <BiInfoCircle />
                                            </Tooltip>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="solInput"
                                                type="text"
                                                {...register("solInput", {
                                                    required: true,
                                                })}
                                                placeholder="0.05"
                                                className="input"
                                            />
                                            {errors.name && (
                                                <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                                    Project name is required
                                                </p>
                                            )}
                                        </div>
                                    </>

                                    <>
                                        <label
                                            htmlFor="roadmapTitle"
                                            className="mt-8 flex items-center font-medium text-gray-200"
                                        >
                                            You receive
                                            <Tooltip text="Amount of SHDW you will receive">
                                                <BiInfoCircle />
                                            </Tooltip>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="shdwOutput"
                                                type="number"
                                                placeholder="6"
                                                className="input"
                                                onChange={() => {}}
                                                value={
                                                    route
                                                        ? (
                                                              JSBI.toNumber(
                                                                  route.outAmount
                                                              ) / 1000000000
                                                          ).toString()
                                                        : 0
                                                }
                                            />
                                        </div>
                                    </>

                                    <div className="mt-8">
                                        <button
                                            type="submit"
                                            className="bg-maroon-flush border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-maroon-flush-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Swap
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
