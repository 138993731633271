// import PageHeader from '../components/PageHeader'
import RoadmapCards from "../components/RoadmapCards";
import Nav from "../components/Nav";
export default function Communities() {
    return (
        <>
            <main className="bg-gray-800">
                <div className="max-w-7xl mx-auto py-8 px-6 lg:px-8">
                    <RoadmapCards />
                </div>
            </main>
        </>
    );
}
