import { useEffect, useState } from "react";
import RoadmapList from "./RoadmapList";

export default function RoadmapsActive() {
    // fetch featured pubkeys
    const [featured, setFeatured] = useState<string[]>(["C6ryt1EWciJW4n57tageWxe2ypKwzPiJEAKUdhgbMAL2"]);

    // useEffect(() => {
    //     const getFeatured = async () => {
    //         try {
    //             // const res = await fetch(
    //             //     `${process.env.REACT_APP_PHASE_API!}/featured`
    //             // );
    //             // const data = await res.json();
    //             const ballonsvilleRoadmap = "C6ryt1EWciJW4n57tageWxe2ypKwzPiJEAKUdhgbMAL2"

    //             const data = [ballonsvilleRoadmap]
    //             setFeatured(data);
    //         } catch (err) {
    //             console.warn(
    //                 "error reaching phase api: ",
    //                 (err as any).toString()
    //             );
    //         }
    //     };

    //     getFeatured();
    // }, []);

    return (
        <div className="p-8">
            <RoadmapList max={featured.length} pubkeys={featured} />
        </div>
    );
}

// view example votes in progress or something for non wallet connected
