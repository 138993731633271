import { Tooltip as TooltipUI } from "@mantine/core";
export const Tooltip = ({
    text,
    children,
    width = 320,
    position = "right",
}: any) => {
    return (
        <TooltipUI
            wrapLines
            width={width}
            color="gray"
            label={text}
            withArrow
            arrowSize={4}
            gutter={0}
            position={position}
            className="px-2"
        >
            {children}
        </TooltipUI>
    );
};
