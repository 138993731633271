import { RoadmapMetadata } from "@dedmonkes/phase-protocol-sdk";
import { useEffect, useState } from "react";
import { RiLink, RiTwitterFill } from "react-icons/ri";
import {
    useCurrentRoadmapKey,
    useRoadmap,
    useRoadmapsMetadata,
} from "../state/hooks/roadmaps";
import RoadmapBadge from "./RoadmapBadge";
import RoadmapHeaderSkeleton from "./skeleton/RoadmapHeaderSkeleton";

export default function RoadmapHeader() {
    const [roadmap, setRoadmap] = useRoadmap();
    const [roadmapAddress] = useCurrentRoadmapKey();
    const metadataMap = useRoadmapsMetadata();
    const [metadata, setMetadata] = useState<any>(undefined);

    useEffect(() => {
        const getMetadata = async () => {
            if (roadmapAddress === null) {
                return;
            }
            const meta = metadataMap[roadmapAddress];
            setMetadata(meta);
        };
        getMetadata();
    }, [roadmapAddress, metadataMap]);

    return (
        <>
            {!roadmap || !metadata ? (
                <RoadmapHeaderSkeleton />
            ) : (
                <div className="">
                    <div className="md:flex items-center relative gap-4 mb-6">
                        <div className="absolute top-2 right-0"></div>
                        <div className="rounded-lg w-12 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-maroon-flush-500 overflow-hidden">
                            <img
                                src={metadata.image as unknown as string}
                                alt=""
                                className="w-12 h-12 object-cover pointer-events-none group-hover:opacity-75"
                            />
                        </div>
                        <h3 className="font-syne text-4xl font-white text-white font-black mr-2">
                            {roadmap.account.name}
                        </h3>
                        <div className="flex flex-1 items-center justify-between my-4 w-full">
                            <RoadmapBadge state={roadmap.account.state} />
                            <div className="flex ml-auto items-center gap-2 text-white">
                                {metadata.website && (
                                    <a
                                        href={
                                            metadata.website as unknown as string
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="transition-all hover:text-maroon-flush-500"
                                    >
                                        <RiLink className="w-6 h-6" />
                                    </a>
                                )}
                                {metadata.twitter && (
                                    <a
                                        href={
                                            metadata.twitter as unknown as string
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="transition-all hover:text-maroon-flush-500"
                                    >
                                        <RiTwitterFill className="w-6 h-6" />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="prose prose-invert prose-md max-w-full text-gray-200">
                        {metadata.description}
                    </div>
                </div>
            )}
        </>
    );
}
