import { useState, useEffect } from "react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
    Roadmap,
    AccountDTO,
    SwapContextEnum,
    Phase,
} from "@dedmonkes/phase-protocol-sdk";
import SwapModal from "./SwapModal";

import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { RiExchangeDollarLine } from "react-icons/ri";
import {
    useRoadmap,
    useSolPoolAmount,
    useTreasuryTotal,
    useUsdcPoolAmount,
} from "../state/hooks/roadmaps";
import { usePhases } from "../state/hooks/phases";
import { Metaplex, Nft } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useSolanaPrice } from "../state/hooks/coins";

export default function RoadmapLockupTotal({}: {}) {
    // const USDC_PROGRAM_ID = new PublicKey('Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr');
    const solPoolAmount = useSolPoolAmount();
    const usdcPoolAmount = useUsdcPoolAmount();
    const [pieData, setPieData] = useState<any>(null);
    const [isSwapOpen, setIsSwapOpen] = useState<boolean>(false);
    const [roadmap, setRoadmap] = useRoadmap();
    const [phases, setPhases] = usePhases();
    const [solTreasuryTotal, usdcTreasuryTotal] = useTreasuryTotal();
    const [inheritNftPrice, setInheritNftPrice] = useState<number>(0);
    const { connection } = useConnection();
    const solanaPrice = useSolanaPrice();
    const [isFounder, setIsFounder] = useState(false);
    const wallet = useWallet();

    useEffect(() => {
        const getPhaseVaults = async () => {
            if (roadmap === undefined) {
                return;
            }

            const totalRoadmapSolValue =
                solPoolAmount + (usdcPoolAmount / solanaPrice);

            const phasePools = await Promise.all(
                phases.map(async (phase: AccountDTO<Phase>) => {
                    let solBalance = await connection.getBalance(
                        phase.account.solPhaseVault
                    );
                    let usdcBalance = await connection.getTokenAccountBalance(
                        phase.account.usdcPhaseVault
                    );
                    if (usdcBalance.value.uiAmount == null) {
                        usdcBalance.value.uiAmount = 0;
                    }
                    const totalSolValue =
                        solBalance / LAMPORTS_PER_SOL +
                        usdcBalance.value.uiAmount / solanaPrice;

                    return {
                        name: phase.account.config.name,
                        vaultAmount: totalSolValue,
                    };
                })
            );

            const pieDataSet = {
                labels: ["Roadmp Pool", ...phasePools.map((x) => x.name)],
                datasets: [
                    {
                        label: "Treasurey Split",
                        data: [
                            totalRoadmapSolValue,
                            ...phasePools.map((x) => x.vaultAmount),
                        ],
                        backgroundColor: [
                            "rgba(155, 99, 132, 0.2)",
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)",
                        ],
                        borderColor: [
                            "rgba(155, 99, 132, 1)",
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)",
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setPieData(pieDataSet);
        };
        getPhaseVaults();
    }, [
        phases,
        solTreasuryTotal,
        usdcTreasuryTotal,
        solPoolAmount,
        usdcPoolAmount,
        roadmap,
    ]);

    useEffect(() => {
        const getInheritNftValue = async () => {
            if (roadmap === undefined) {
                return;
            }

            const nft: Nft = await new Metaplex(connection)
                .nfts()
                .findByMint(roadmap.account.collection)
                .run();

            const collectionSize = nft.collectionDetails?.size.toNumber();

            if (collectionSize == undefined) {
                return;
            }

            const solUsdcTreasuryValue = solTreasuryTotal + solPoolAmount;
            const usdcTreasuryValue =
                (usdcTreasuryTotal + usdcPoolAmount) / solanaPrice;
            const totalTreasurySolValue =
                solUsdcTreasuryValue + usdcTreasuryValue;
            if (totalTreasurySolValue !== 0 && collectionSize !== 0) {
                setInheritNftPrice(
                    Math.floor((totalTreasurySolValue / collectionSize) * 100) /
                        100
                );
            } else {
                setInheritNftPrice(0);
            }
        };
        getInheritNftValue();
    }, [
        roadmap,
        solPoolAmount,
        usdcPoolAmount,
        usdcTreasuryTotal,
        solTreasuryTotal,
        solanaPrice,
    ]);

    // useEffect(() => {
    //   if (!roadmap) return;
    //   const getSolPoolBalance = async () => {
    //     if (roadmap.account.solRoadmapPool) {
    //       const solPoolValue = await connection.getAccountInfo(
    //         roadmap.account.solRoadmapPool
    //       );
    //       if (solPoolValue) {
    //         setSolPoolAmount(solPoolValue.lamports / LAMPORTS_PER_SOL);
    //       }
    //       else{
    //         setSolPoolAmount(0)
    //       }
    //     }
    //   };
    //   const getUSDCPoolBalance = async () => {
    //     if (roadmap.account.solRoadmapPool) {
    //       const usdcPoolValue = await connection.getTokenAccountBalance(
    //         roadmap.account.usdcRoadmapPool
    //       );
    //       if (usdcPoolValue.value.uiAmount) {
    //         setUsdcPoolAmount(usdcPoolValue.value.uiAmount);
    //       }
    //       else {
    //         setUsdcPoolAmount(0);
    //       }
    //     }
    //   };
    //   getSolPoolBalance();
    //   getUSDCPoolBalance();
    // }, [roadmap]);

    useEffect(() => {
        if (roadmap && wallet.publicKey) {
            if (
                wallet.publicKey.toBase58() ===
                roadmap.account.teamAuthority.toString()
            ) {
                setIsFounder(true);
            }
        }
    }, [roadmap, wallet.publicKey]);

    return (
        <>
            <div className=" relative bg-gray-700 bg-opacity-50 shadow p-8 rounded-md text-sm block mb-6">
                <div className="flex justify-between items-center text-white font-bold text-lg">
                    <span className="">
                        Inherent NFT
                        <br />
                        Floor Value
                    </span>
                    <span className="text-2xl">
                        <span className="-mt-[0.4em] inline-block align-middle mr-[2px]">
                            ◎
                        </span>
                        {inheritNftPrice}
                    </span>
                </div>
            </div>
            <div className=" relative bg-gray-700 bg-opacity-50 shadow p-8 rounded-md text-sm block mb-6">
                <h4 className="text-white pb-2 px-0 font-bold text-lg">
                    Roadmap Treasury
                </h4>
                {isFounder && (
                    <span
                        title="Swap USDC/SOL held in your roadmap vaults"
                        className="absolute top-9 right-5 inline-flex items-center justify-center p-3 pt-0 rounded-md text-gray-300 cursor-pointer hover:text-white"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setIsSwapOpen(!isSwapOpen);
                        }}
                    >
                        <RiExchangeDollarLine className="w-6 h-6" />
                    </span>
                )}
                <div className="py-2 px-0 leading-3 text-white">
                    <table className="table table-auto w-full">
                        <tbody>
                            <tr className="py-2 flex justify-between">
                                <td>Treasury SOL Total</td>
                                <td>
                                    <span className="sol inline-block align-middle mr-[2px]">
                                        ◎
                                    </span>
                                    {Math.floor(
                                        (solTreasuryTotal + solPoolAmount) * 100
                                    ) / 100}
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>Treasury USDC Total</td>
                                <td>
                                    <span className="sol inline-block align-middle mr-[2px]">
                                        $
                                    </span>
                                    {usdcTreasuryTotal + usdcPoolAmount}
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>SOL Pool</td>
                                <td>
                                    <span className="sol inline-block align-middle mr-[2px]">
                                        ◎
                                    </span>
                                    {solPoolAmount.toFixed()}
                                </td>
                            </tr>
                            <tr className="py-2 flex justify-between">
                                <td>USD Pool</td>
                                <td>${usdcPoolAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="px-6">
                    {pieData && (
                        <Pie
                            data={pieData}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    )}
                </div>

                {roadmap !== undefined ? (
                    <SwapModal
                        pdaContext={roadmap.address}
                        startSwapContext={SwapContextEnum.roadmapPool}
                        isOpen={isSwapOpen}
                        roadmap={roadmap.address}
                        onClose={() => setIsSwapOpen(false)}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
