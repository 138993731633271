import { PhaseProtocolSDK } from "@dedmonkes/phase-protocol-sdk";
import { useConnection } from "@solana/wallet-adapter-react";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import { selectorFamily, selector } from "recoil";
import { connectionUrl, phaseWallet, connection } from "./wallet";

export const phaseContext = selector<PhaseProtocolSDK | undefined>({
    key: "phaseContext",
    get: ({ get }: any) => {
        const wallet = get(phaseWallet);
        const connectionState = get(connection);

        if (wallet !== undefined && connection) {
            const phase = new PhaseProtocolSDK(
                connectionState,
                wallet,
                "mainnet-beta",
                10000
            );
            phase.init();
            return phase;
        }
    },
});
