import { createContext, useContext, useMemo } from "react";
import {
    PhaseProtocolSDK,
    SOLANA_NETWORK_TYPES,
} from "@dedmonkes/phase-protocol-sdk";
import type { FC, ReactNode } from "react";
import {
    useAnchorWallet,
    useConnection,
    useWallet,
    WalletContext,
} from "@solana/wallet-adapter-react";
import { web3 } from "@project-serum/anchor";
import { RPC_URL_DEFAULT } from "../constants";
import { useRecoilValue } from "recoil";
import { phaseWallet } from "../state/wallet";

export interface PhaseSDKProviderProps {
    children: ReactNode;
    pollingTime: number;
    network: SOLANA_NETWORK_TYPES;
}

export const PhaseSDKContext = createContext<PhaseProtocolSDK>(
    {} as PhaseProtocolSDK
);

/**
 * TODO add phase object that implements the classes public props
 * Use as defafult value
 */
const PhaseSDKProvider: FC<PhaseSDKProviderProps> = ({
    children,
    pollingTime,
    network,
}) => {
    const { connection } = useConnection();
    const wallet = useRecoilValue(phaseWallet);


    const sdk = useMemo(() => {
        if (wallet !== undefined  && network && pollingTime) {
            const phase = new PhaseProtocolSDK(
                connection ? connection : new web3.Connection(RPC_URL_DEFAULT, "confirmed"),
                wallet,
                network,
                pollingTime
            );
            phase.init();
            return phase;
        }

    }, [connection, network, pollingTime, wallet?.publicKey]);

    return (
        <PhaseSDKContext.Provider value={sdk as PhaseProtocolSDK}>
            {children}
        </PhaseSDKContext.Provider>
    );
};

export default PhaseSDKProvider;
