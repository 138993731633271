import { RoadmapState } from "@dedmonkes/phase-protocol-sdk";
import { useEffect, useState } from "react";

const RoadmapBadge = ({ state }: { state: RoadmapState }) => {
    const [roadmapState, setRoadmapState] = useState<string>("Draft");

    useEffect(() => {
        if (state?.draft) {
            setRoadmapState("Draft");
        } else if (state?.actioned || state?.actioning) {
            setRoadmapState("Approved");
        } else if (state?.canceled) {
            setRoadmapState("Canceled");
        } else if (state?.complete) {
            setRoadmapState("Complete");
        } else if (state?.draft) {
            setRoadmapState("Draft");
        } else if (state?.lockedForMint) {
            setRoadmapState("Finalized");
        } else if (state?.refunding) {
            setRoadmapState("Refunding");
        } else if (state?.resolution) {
            setRoadmapState("Resolution");
        } else if (state?.stagedForResolution) {
            setRoadmapState("Voting For Resolution");
        }
    }, [state]);

    return (
        <div className="text-maroon-flush-400 outline outline-1 px-1 py-1 rounded-md text-sm">
            {roadmapState}
        </div>
    );
};

export default RoadmapBadge;
