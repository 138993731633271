import { MetadataStorage } from "@dedmonkes/shadow-helper";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Wallet } from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhaseSkeleton from "../components/skeleton/PhaseSkeleton";
import { prodConn } from "../constants";
import { prettyPrintNumber } from "../utils/prettyPrintNumber";
import RoadmapReviewForm from "./RoadmapCreateForm/RoadmapCreationForm";

export default function RoadmapCreate() {
    const navigate = useNavigate();

    const wallet = useAnchorWallet();
    const [balances, setShdwBal] =
        useState<{
            SHDW: number;
            SOL: number;
        } | null>();

    const getInfo = async () => {
        if (wallet?.publicKey) {
            const bal = await MetadataStorage.getBalances(
                wallet as Wallet,
                prodConn
            );
            setShdwBal(bal);
        }
    };

    useEffect(() => {
        getInfo();
    }, [wallet]);

    return (
        <>
            <div className="max-w-4xl mx-auto py-8 px-6 lg:px-8">
                <main className="pb-12">
                    <div className="py-8">
                        <div className=" text-white">
                            <h1 className="text-4xl font-black mb-2">
                                Create New Roadmap
                            </h1>
                        </div>

                        {balances && (
                            <div className="text-sm flex justify-start text-white gap-4 mt-4">
                                Balances:
                                <div className="">
                                    <strong>$SHDW:</strong>{" "}
                                    {prettyPrintNumber(balances.SHDW)}
                                </div>
                                <div style={{ display: "inline-block" }}>
                                    <strong>$SOL:</strong>{" "}
                                    {prettyPrintNumber(balances.SOL)}
                                </div>
                            </div>
                        )}

                        {balances && balances?.SHDW < 1 && (
                            <div className="relative shadow-lg  border-2 border-maroon-flush bg-maroon-flush-700 bg-opacity-50 text-maroon-flush-50 p-6 rounded-lg my-6">
                                <div className="mb-3 flex justify-between items-center">
                                    <h5 className="font-bold uppercase text-white leading-none">
                                        Hold Up!
                                    </h5>
                                    <ExclamationCircleIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <>
                                    You will need a small amount of $SHDW so
                                    that we can store your roadmap and
                                    collection metadata on-chain.
                                    <p>
                                        <a
                                            className="text-white underline mt-4 inline-block"
                                            href="https://jup.ag/swap/SOL-SHDW"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Swap SOL on Jupiter
                                        </a>
                                    </p>
                                    <p className="text-xs mt-1">
                                        Note: $1-2 worth is typically more than
                                        enough
                                    </p>
                                </>
                            </div>
                        )}
                        <Suspense fallback={<PhaseSkeleton />}>
                            <RoadmapReviewForm />
                        </Suspense>
                    </div>
                </main>
            </div>
        </>
    );
}
