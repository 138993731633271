import { useEffect, useState } from "react";
import {
    AccountDTO,
    Roadmap,
    MintingAccountPlugin,
    PhaseProtocolSDK,
    RefundManager,
    Phase,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { useWallet } from "@solana/wallet-adapter-react";
import { LockClosedIcon } from "@heroicons/react/outline";
import { BigNumber, Metadata, Metaplex, Nft } from "@metaplex-foundation/js";
import { BN, Wallet, web3 } from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { useNfts, useRoadmapScopedNfts } from "../state/hooks/nfts";
import { handleTxNotifications } from "../libs/utils";
import { lastValueFrom } from "rxjs";
import toast from "react-hot-toast";
import RefundClaimModal from "./RefundClaimModal";
import {
    useRefreshSolPoolAmount,
    useRefreshUsdcPoolAmount,
    useRoadmap,
} from "../state/hooks/roadmaps";
import { useRecoilState } from "recoil";
import { claimModalIsOpen } from "../state/ui";
import RoadmapRefundSkeleton from "./skeleton/RoadmapRefundSkeleton";

const RoadmapRefundNotice = ({}: {}) => {
    const wallet = useWallet();
    const sdk = usePhaseSdk();
    const [nfts, refreshNfts] = useRoadmapScopedNfts();
    const [roadmap, setRoadmap] = useRoadmap();
    const [canClaim, setCanClaim] = useState<boolean>(false);
    const [refundManager, setRefundManager] =
        useState<AccountDTO<RefundManager>>();
    const [isClaimsOpen, setIsClaimsOpen] = useState<boolean>(false);
    const [collectionSize, setCollectionSize] = useState<number>(0);
    const [redeemedCount, setRedeemedCount] = useState<number>(0);
    const [solPaymentPerClaim, setSolPaymentPerClaim] = useState<number>(0);
    const [usdcPaymentPerClaim, setUsdcPaymentPerClaim] = useState<number>(0);
    const [solPoolTotal, setSolPoolTotal] = useState<number>(0);
    const [usdcPoolTotal, setUsdcPoolTotal] = useState<number>(0);
    const [showClaimModal, setShowClaimModal] =
        useRecoilState<boolean>(claimModalIsOpen);

    const fetchSolPool = useRefreshSolPoolAmount();
    const fetchUsdcPool = useRefreshUsdcPoolAmount();

    // Fetch the plugin interface of the minting program
    // Shows information on the minting process

    const getProgressPerc = (left: number, total: number) => {
        return `${(left / total) * 100}%`;
    };

    useEffect(() => {
        const getRefundManager = async () => {
            if (sdk?.globalConfig && roadmap) {
                try {
                    const refundManager: AccountDTO<RefundManager> =
                        await sdk.getRefundManager(roadmap.address);
                    setIsClaimsOpen(
                        refundManager.account.state?.complete === undefined &&
                            refundManager !== null
                    );
                    setCollectionSize(
                        refundManager.account.collectionSize.toNumber()
                    );
                    setRedeemedCount(
                        refundManager.account.redeemedCount.toNumber()
                    );
                    setSolPaymentPerClaim(
                        refundManager.account.solPaymentPerClaim
                            .div(
                                new BN(LAMPORTS_PER_SOL).div(
                                    new BN(Math.pow(10, 2))
                                )
                            )
                            .toNumber() / 100
                    );
                    setUsdcPaymentPerClaim(
                        refundManager.account.usdcPaymentPerClaim
                            .div(new BN(1_000_000).div(new BN(Math.pow(10, 2))))
                            .toNumber() / 100
                    );
                    setSolPoolTotal(
                        refundManager.account.solPoolTotal
                            .div(new BN(LAMPORTS_PER_SOL))
                            .toNumber()
                    );
                    setUsdcPoolTotal(
                        refundManager.account.usdcPoolTotal
                            .div(new BN(1_000_000))
                            .toNumber()
                    );
                } catch (e) {
                    console.log(e);
                }
            }
        };

        getRefundManager();
    }, [wallet?.publicKey, roadmap, sdk?.globalConfig, nfts]);

    useEffect(() => {
        if (wallet?.publicKey === undefined) {
            return;
        }
        if (nfts.length === 0 && isClaimsOpen) {
            setCanClaim(false);
            return;
        }

        setCanClaim(true);
    }, [nfts, wallet?.publicKey, isClaimsOpen]);

    return (
        <>
            {roadmap?.account.state?.refunding ? (
                <div className="bg-[#111111] text-white rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-lg border-2 border-maroon-flush transform transition-all sm:my-12 w-full sm:p-6">
                    <LockClosedIcon
                        className="h-6 w-6 text-gray-400 absolute right-6"
                        aria-hidden="true"
                    />

                    <div className="text-left">
                        <h3 className="text-lg font-medium text-gray-200">
                            Roadmap Refund
                        </h3>

                        {isClaimsOpen ? (
                            <div className="mt-4">
                                <p className="text-gray-200">
                                    You have {nfts.length} refund claims
                                    avaliable{" "}
                                </p>

                                <>
                                    <p className=" text-gray-200 gap-4 leading-loose">
                                        <span className="sol inline-block align-middle mr-[2px]">
                                            ◎
                                        </span>
                                        {solPaymentPerClaim.toString()} per
                                        claim
                                    </p>
                                    <p className=" text-gray-200 gap-4 leading-loose">
                                        <span className="sol inline-block align-middle mr-[2px]">
                                            USDC
                                        </span>
                                        {usdcPaymentPerClaim.toString()} per
                                        claim
                                    </p>

                                    <hr className="border border-white opacity-10 my-5" />

                                    <div className="text-left text-sm text-gray-200 my-2 gap-4 leading-loose flex justify-between items-center">
                                        <p>{`Redeemed Claims: ${redeemedCount}`}</p>
                                        <p>{`Total Claims Avaliable: ${
                                            collectionSize + redeemedCount
                                        }`}</p>
                                    </div>

                                    <div className="w-full bg-gray-500 rounded-full h-2.5 bg-opacity-50">
                                        <div
                                            className="bg-maroon-flush-500 h-2.5 rounded-full"
                                            style={{
                                                width: getProgressPerc(
                                                    redeemedCount,
                                                    collectionSize +
                                                        redeemedCount
                                                ),
                                            }}
                                        ></div>
                                    </div>

                                    {canClaim ? (
                                        <p className="mt-5">
                                            <button
                                                onClick={(e) =>
                                                    setShowClaimModal(true)
                                                }
                                                className="bg-maroon-flush inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
                                            >
                                                Claim Refund
                                            </button>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            </div>
                        ) : (
                            <></>
                            // <div className="mt-4">
                            //   <p className="text-gray-200">
                            //     <br /> Roadmap is being
                            //   </p>
                            //     <>
                            //       <hr className="border border-white opacity-10 my-5" />
                            //       <p className=" text-gray-200 mt-4 gap-4 leading-loose">
                            //         Mint Price:{" "}
                            //         <span className="sol inline-block align-middle mr-[2px]">
                            //           ◎
                            //         </span>
                            //         {/* {mintingInfo.account.price.toNumber() / LAMPORTS_PER_SOL} */}
                            //       </p>
                            //     </>
                            // </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default RoadmapRefundNotice;
