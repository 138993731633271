import { useState, useMemo, useEffect } from "react";
import Layout from "./Layout";
import { DataContext } from "./DataContext";
import { ViewContext } from "./ViewContext";

import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    BackpackWalletAdapter,
    BraveWalletAdapter,
    ExodusWalletAdapter,
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import PhaseSDKProvider from "./context/PhaseSDKProvider";
import WalletSubscriber from "./state/subscribers/WalletSubscriber";
import { useRecoilState } from "recoil";
import { featuredState } from "./state/featured";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
    const [appState, setAppState] = useState<any>({
        loading: false,
        data: [],
        users: null,
    });

    const [view, setView] = useState<string>("draft");
    const [user, setUser] = useState<string>("team");

    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(
        () => process.env.REACT_APP_SOLANA_RPC!,
        [network]
    );

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new ExodusWalletAdapter(),
            new BraveWalletAdapter({}),
            new SolflareWalletAdapter({ network }),
            new GlowWalletAdapter(),
            new TorusWalletAdapter(),
            new BackpackWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletSubscriber />
                <WalletModalProvider>
                    <ViewContext.Provider
                        value={{ view, setView, user, setUser }}
                    >
                        <DataContext.Provider
                            value={{ ...appState, setAppState }}
                        >
                            <PhaseSDKProvider
                                pollingTime={10000}
                                network={network}
                            >
                                <Layout />
                            </PhaseSDKProvider>
                        </DataContext.Provider>
                    </ViewContext.Provider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}

export default App;
