import CommunityMembers from "../components/CommunityMembers";
import PageHeader from "../components/PageHeader";
import Nav from "../components/Nav";
import { useContext } from "react";
import { DataContext } from "../DataContext";
import { useParams, Link, Outlet } from "react-router-dom";

import {
    ChatAltIcon,
    ClipboardCheckIcon,
    HomeIcon,
    UserGroupIcon,
    CollectionIcon,
    LibraryIcon,
} from "@heroicons/react/outline";

const navigation = [
    { name: "Dashboard", href: "", icon: HomeIcon, current: true },
    { name: "Feed", href: "feed", icon: ChatAltIcon, current: false },
    {
        name: "Roadmap",
        href: "roadmap",
        icon: ClipboardCheckIcon,
        current: false,
    },
    { name: "Treasury", href: "treasury", icon: LibraryIcon, current: false },
    { name: "Members", href: "members", icon: UserGroupIcon, current: false },
    { name: "Gallery", href: "gallery", icon: CollectionIcon, current: false },
];

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function Roadmap({ children }: any) {
    const appState = useContext<any>(DataContext);
    let { id } = useParams();
    const community =
        appState.data && appState.data.filter((x: any) => x.id == id)[0];

    return (
        <>
            {community && (
                <>
                    {/* <PageHeader title={community.title} /> */}
                    <main className="bg-gray-800">
                        {/* 3 column wrapper */}
                        <div className="max-w-7xl mx-auto py-8 px-6 lg:px-8">
                            {/* Left sidebar & main wrapper */}
                            <div className="flex-1 min-w-0 xl:flex">
                                <div className="bg-gray-800 lg:min-w-0 lg:flex-1">
                                    <div className="h-full">
                                        {/* Start main area*/}
                                        {/* <div className="overflow-auto h-screen [scrollbar-width:thin] relative"> */}
                                        <Outlet />
                                        {/* </div> */}
                                        {/* End main area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
}
