const PhaseSkeleton = () => (
    <div className="animate-pulse">
        <div className="p-8 rounded-md max-w-4xl bg-[#111111] shadow-md border border-gray-700 block my-4">
            <div className="h-5 bg-gray-700 rounded w-60 mb-6"></div>
            <div className="h-4 bg-gray-700 rounded w-10 mb-6 absolute top-6 right-6"></div>
            <div className="h-3 bg-gray-700 rounded w-96 mb-4"></div>
            <div className="h-3 bg-gray-700 rounded w-60"></div>
        </div>
        <div className="p-8 rounded-md max-w-4xl bg-[#111111] shadow-md border border-gray-700 block my-4">
            <div className="h-8 bg-gray-700 rounded w-60 mb-6"></div>
            <div className="h-4 bg-gray-700 rounded w-10 mb-6 absolute top-6 right-6"></div>
            <div className="h-4 bg-gray-700 rounded w-96 mb-2"></div>
            <div className="h-2 bg-gray-700 rounded w-60"></div>
        </div>
    </div>
);
export default PhaseSkeleton;
