import { collection, onSnapshot, query, where } from "firebase/firestore";
import { database } from "../libs/firebase";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
export interface IVote {
    id: string
    mintId: string
    ownerPubkey: string
    vote: boolean
    votedAt: number,
}
export interface FirebaseData {
  ownVotes: IVote[];
  count: number;
  allVotes: IVote[];
}
export const FirebaseDataContext = createContext<FirebaseData>({} as any);
export const FirebaseDataProvider = ({ children = null as any }: any) => {
    
    const { publicKey } = useWallet()

    const [ownVotes, setOwnVotes] = useState<IVote[]>([]);
    const [allVotes, setAllVotes] = useState<IVote[]>([])
    const [count, setCount] = useState(0)
    
    const fetchAllVotes = useCallback(async () => {
        const querySnapshot = query(collection(database, 'votes'));
        const unSub = onSnapshot(querySnapshot, (qs: any) => {

            const voteState: IVote[] = qs.docs.map((doc: any) => {
                let data = doc.data();
                let newData: IVote = {
                    id: doc.id,
                    mintId: data.mintId,
                    ownerPubkey: data.ownerPubkey,
                    vote: data.vote,
                    votedAt: data.votedAt,
                }
                return newData;
            });

            setAllVotes(voteState)
            setCount(voteState.length)
        });
        return () => unSub();
    }, [])

    const fetchUserVotes = useCallback(async () => {
        if(!publicKey || allVotes.length === 0) return;
        let voteState = allVotes;
        let userFilteredeVotes = voteState.filter((vote) => {
            return vote.ownerPubkey === publicKey.toBase58() && vote.vote === true;
        });
        setOwnVotes(userFilteredeVotes);
    },[allVotes, publicKey])

    useEffect(() => {
        fetchAllVotes();
    }, [fetchAllVotes]);

    useEffect(() => {
        fetchUserVotes();
    },[fetchUserVotes])

    return (
      <FirebaseDataContext.Provider
        value={{ ownVotes, count, allVotes }}>
        {children}
      </FirebaseDataContext.Provider>
    );
}
export const useFirebaseDataContext = () => useContext(FirebaseDataContext);