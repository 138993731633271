import { Nft } from "@metaplex-foundation/js";
import { web3 } from "@project-serum/anchor";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import usePhaseSdk from "../../hooks/usePhaseProtocol";
import { NftType } from "../../partials/RoadmapCreateForm/NFTPicker";
import { collectionNfts, nftsForRoadmapQuery, nftsQueryRequestIDState, nftStore } from "../nft";
import { usePhase } from "./phases";
import { useCurrentRoadmapKey } from "./roadmaps";

export const useRoadmapScopedNfts = (): [any[], () => void] => {
    const scopedNfts = useRecoilValue<Nft[]>(nftsForRoadmapQuery);
    const [queryId, setQueryId] = useRecoilState(nftsQueryRequestIDState);
    const refreshNfts = () => setQueryId((requestId) => requestId + 1);
    return [scopedNfts, refreshNfts];
};

export const useNfts = () => {
    const nfts = useRecoilValue(nftStore);
    return nfts;
};

export const useCollectionNfts = ()=> {
    const nfts = useRecoilValue(collectionNfts);
    const [resolvedNfts, setResolvedNfts] = useState<{nft : NftType, metadata : any}[]>([]);

    useEffect(() => {
        const fetchUri = async () =>{
            const promises = nfts.map(async (nft) => {
                const md = await fetch(nft.uri);
                if (md.status === 200) {
                    return {
                        nft,
                        metadata: await md.json()
                    }
            }
                throw new Error("Metadata failed to fetch");
            });
            const resolved = await Promise.allSettled(promises);
            const results : {nft : NftType, metadata : any}[] = resolved
                .filter((result) => result.status === "fulfilled")
                .map((result) => (result as any).value);
                setResolvedNfts(results)
        }
        fetchUri()
    }, [nfts]);
    
    return resolvedNfts;
}

export const useUnvotedNfts = (): [web3.PublicKey[], () => void] => {
    const [currentRoadmap, currentPhase] = useCurrentRoadmapKey();
    const [nfts] = useRoadmapScopedNfts();
    const [phase] = usePhase();
    const [unvotedNfts, setUnvotedNfts] = useState<web3.PublicKey[]>([]);
    const [refresh, setRefresh] = useState(0);
    const sdk = usePhaseSdk();

    useEffect(() => {
        const fetchUnvotedNfts = async (
            roadmapAddress: string,
            nfts: web3.PublicKey[],
            phaseAddress: string | null
        ) => {
            const unvotedNfts = await sdk.filterForUnvotedNftMints(
                new web3.PublicKey(roadmapAddress),
                nfts,
                phaseAddress !== null
                    ? new web3.PublicKey(phaseAddress)
                    : undefined
            );
            setUnvotedNfts(unvotedNfts);
        };
        if (sdk && currentRoadmap) {
            fetchUnvotedNfts(
                currentRoadmap,
                nfts.map((x) => x.mintAddress),
                currentPhase
            );
        }
    }, [nfts, currentRoadmap, currentPhase, sdk, phase, refresh]);

    return [unvotedNfts, () => setRefresh((x) => x + 1)];
};

export const useWalletHasNfts = () => {
    const nfts = useRecoilValue(nftStore);
    return nfts.size > 0;
};
