import { AnchorWallet, Wallet } from "@solana/wallet-adapter-react";
import { Connection } from "@solana/web3.js";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { RPC_URL_DEFAULT } from "../constants";

export const connectionUrl = atom<string>({
    key: "connectionUrl",
    default: RPC_URL_DEFAULT,
});

export const phaseWallet = atom<AnchorWallet | undefined>({
    key: "phaseWallet",
    default: undefined,
});

export const connection = selector<Connection | undefined>({
    key: "connection",
    get: ({ get }: any) => {
        const endpoint = get(connectionUrl);
        const connection = new Connection(endpoint, "confirmed");
        return connection;
    },
});
