import {
    AccountDTO,
    Deliverable,
    InitDeliverablesArgs,
    Phase,
    PhaseState,
    Proof,
    Roadmap,
} from "@dedmonkes/phase-protocol-sdk";
import { Dialog } from "@headlessui/react";
import { web3 } from "@project-serum/anchor";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
    FingerPrintIcon,
    CheckCircleIcon,
    MinusIcon,
    PlusIcon,
    XCircleIcon,
    PlusCircleIcon,
    PaperClipIcon,
    LinkIcon,
    InformationCircleIcon,
    TrashIcon,
} from "@heroicons/react/outline";
import { useDeliverables, useProofs } from "../state/hooks/deliverables";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { useWallet } from "@solana/wallet-adapter-react";
import { handleTxNotifications } from "../libs/utils";
import { lastValueFrom } from "rxjs";
import { usePhase } from "../state/hooks/phases";
import { useCurrentRoadmapKey, useRoadmap } from "../state/hooks/roadmaps";
import ConfirmationModal from "./ConfirmationModal";
import { Tooltip } from "./Tooltip";

const AddProofModal = ({
    roadmap,
    deliverable,
    show,
    onClose,
}: {
    roadmap: AccountDTO<Roadmap>;
    deliverable: AccountDTO<Deliverable>;
    show: boolean;
    onClose: () => void;
}) => {
    const sdk = usePhaseSdk();
    const wallet = useWallet();
    const [inputDescription, setInputDescription] = useState<string>("");
    const [proofState, setProofState] = useProofs();

    const handleCreateProof = async (
        roadmap: AccountDTO<Roadmap>,
        deliverable: AccountDTO<Deliverable>,
        description: string
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }

        try {
            new URL(description.trim());
        } catch (e) {
            toast.error("Invalid URL provided");
            return;
        }

        const initProof$ = await sdk.initProof(
            description.trim(),
            deliverable.address,
            deliverable.account.phase,
            roadmap.address
        );
        const toasts = new Map<string, string>();

        initProof$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setProofState(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Proof successfully addeded to phase",
                    "Adding proof to phase failed"
                );
            },
            error: (error) => {
                toast.error(error.toString());
            },
        });
    };

    return (
        <>
            {show ? (
                <div className="relative z-10 top-0">
                    <div className="fixed top-0 left-0 w-full h-full">
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                        <div className="fixed inset-0 h-full"></div>
                        <div className="flex min-h-full items-center justify-center p-4 text-center ">
                            <div
                                className="flex-row items-between w-full h-full 
                        max-w-xl transform 
                        rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all"
                            >
                                <div className="text-2xl font-bold leading-6 text-white">
                                    Add Proof
                                </div>
                                <div className="pt-3 text-md font-semi leading-6 text-white">
                                    <p className="font-light text-sm">
                                        Add evidence of the completion of your
                                        deliverables
                                    </p>
                                    <br></br>
                                    <p className="prose prose-invert">
                                        Attach upto five proofs to your
                                        deliverable to prove your completion of
                                        the task. This can be any web link that
                                        show proof of your delivery.
                                    </p>
                                    <br></br>
                                    <p className="text-gray-300 font-light text-sm italic">
                                        Eg. Demo link, Audit report, Google
                                        Drive Document link or social proof of
                                        delivery
                                    </p>
                                </div>

                                <div className="mt-5 flex-row justify-start gap-2 h-fit w-full ">
                                    <div className="flex outline outline-1 h-fit outline-maroon-flush-400 rounded-md">
                                        <input
                                            className="w-full outline-none bg-[#111111] text-white pl-3 py-2 text-sm"
                                            value={inputDescription}
                                            onChange={(e) =>
                                                setInputDescription(
                                                    e.target.value
                                                )
                                            }
                                        ></input>
                                    </div>

                                    <button
                                        onClick={(e) => {
                                            handleCreateProof(
                                                roadmap,
                                                deliverable,
                                                inputDescription
                                            );
                                            setInputDescription("");
                                            onClose();
                                        }}
                                        className="bg-maroon-flush mt-5 w-full inline-flex items-center justify-center 
                                    p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600
                                     focus:ring-white"
                                    >
                                        Create Proof
                                    </button>
                                    <button
                                        className="absolute right-0 top-0 p-4"
                                        onClick={() => onClose()}
                                    >
                                        <XCircleIcon className="text-white w-8" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

const AddDeliverableModal = ({
    handleAddDeliverable,
    phase,
    show,
    onClose,
}: {
    phase: AccountDTO<Phase>;
    handleAddDeliverable: (
        phase: web3.PublicKey,
        description: string[]
    ) => void;
    show: boolean;
    onClose: () => void;
}) => {
    const [inputDescription, setInputDescription] = useState<string>("");
    const [deliverables, setDeliverables] = useState<string[]>([]);

    const addDeliverable = useCallback(
        (input: string, deliverables: string[]) => {
            if (input === "") {
                return;
            }

            if (
                phase.account.activeDeliverables.length + deliverables.length >=
                5
            ) {
                toast.error(
                    "You can only have up to 5 deliverables per phase."
                );
                return;
            }

            setDeliverables((dels) => [...dels, input]);
            setInputDescription("");
        },
        [phase]
    );

    return (
        <>
            {show ? (
                <div className="fixed top-0 left-0 w-full h-full z-50">
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                    <div className="fixed inset-0 h-full"></div>
                    <div className="flex min-h-full items-center justify-center p-4 text-center ">
                        <div
                            className="flex-row items-between w-full h-full 
                        max-w-xl transform 
                        rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all"
                        >
                            <div className="h3 text-2xl font-bold leading-6 text-white">
                                Add Deliverables
                            </div>
                            <div className="pt-3 text-md font-semi leading-6 text-white">
                                <p className="font-light text-sm">
                                    Add objective deliverables for your phase
                                </p>
                                <br></br>
                                <p className="prose prose-invert">
                                    Before payout, upon completion of the phase
                                    you will be required to provide a link
                                    proiding proof of your delivery. Allowing
                                    your collection holders to vote objectivly
                                    as to whether the phase has been completed
                                    to a satisfactory standard.
                                </p>
                                <br></br>

                                <p className="text-gray-300 font-light text-sm italic">
                                    Eg. Provide a demo video of our Dapps
                                    feature xyz
                                </p>
                            </div>

                            <div className="flex-row w-full gap-3 h-fit mt-5">
                                {/* <div className="text-sm font-light text-gray-600">Deliverables</div> */}
                                {deliverables.map((deliverable, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="flex h-fit w-full justify-between items-center pl-3 mt-2 outline-1 outline rounded-md
                                    outline-gray-800 "
                                        >
                                            <div className="text-gray-300 py-2">
                                                {deliverable}
                                            </div>
                                            <button
                                                onClick={() =>
                                                    setDeliverables((dels) =>
                                                        dels.filter(
                                                            (x, index) =>
                                                                index !== i
                                                        )
                                                    )
                                                }
                                                className="w-8 text-center h-full font-bold text-gray-400 text-sm cursor-pointer
                                            hover:text-white"
                                            >
                                                <MinusIcon className="w-4" />
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="mt-5 flex-row justify-start gap-2 h-fit w-full ">
                                <div className="flex outline outline-1 h-fit outline-maroon-flush-400 rounded-md">
                                    <input
                                        className="w-full outline-none bg-[#111111] text-white pl-3 py-2 text-sm"
                                        value={inputDescription}
                                        onChange={(e) =>
                                            setInputDescription(e.target.value)
                                        }
                                    ></input>
                                    <button
                                        onClick={(e) =>
                                            addDeliverable(
                                                inputDescription,
                                                deliverables
                                            )
                                        }
                                        className="bg-maroon-flush inline-flex text-sm items-center justify-center p-1 px-4 rounded-md text-white 
                                    hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 rounded-l-none
                                    focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white font-bold"
                                    >
                                        <PlusIcon className="w-4" />
                                    </button>
                                </div>

                                <button
                                    onClick={(e) => {
                                        handleAddDeliverable(
                                            phase?.address,
                                            deliverables
                                        );
                                        setDeliverables([]);
                                        setInputDescription("");
                                        onClose();
                                    }}
                                    className="bg-maroon-flush mt-5 w-full inline-flex items-center justify-center 
                                    p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600
                                     focus:ring-white"
                                >
                                    Create Deliverables
                                </button>
                                <button
                                    className="absolute right-0 top-0 p-4"
                                    onClick={() => onClose()}
                                >
                                    <XCircleIcon className="text-white w-8" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

interface ProofItemArgs {
    proof: AccountDTO<Proof>;
    index : number,
    isTeam : boolean,
    isDeliverableComplete : boolean,
    onClick : (proof : AccountDTO<Proof>) => void
}

const ProofItem: FC<ProofItemArgs> = ({
    proof,
    index,
    isTeam,
    isDeliverableComplete,
    onClick
}) => {

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    return   <a
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className="flex gap-1 justify-center items-center text-gray-300 text-xs font-bold outline outline-1 outline-gray-500 
                    rounded-md px-2 py-1 mt-1 hover:text-white hover:bg-maroon-flush
                    hover:outline-maroon-flush"
                target="_blank"
                href={
                    proof.account.proof.slice(0, 7) !==
                        "http://" &&
                    proof.account.proof.slice(0, 8) !==
                        "https://"
                        ? "https://" + proof.account.proof
                        : proof.account.proof
                }
                rel="noreferrer"
            >
                <span className="font-black text-sm">

                {isHovering && isTeam && !isDeliverableComplete && (
                    <TrashIcon onClick={(e) => {
                        e.preventDefault()
                        onClick(proof)
                    }} className="w-5"/>
                )}
                {(!isHovering || !isTeam || isDeliverableComplete) && (
                    <FingerPrintIcon className="w-5" />
                )}
                </span>
                {`Proof ${index + 1}`}
          
</a>
}


interface DeliverableArgs {
    deliverable: AccountDTO<Deliverable>;
    description: string;
    isComplete: boolean;
    proofs: AccountDTO<Proof>[];
}

const DeliverableItem: FC<DeliverableArgs> = ({
    deliverable,
    description,
    isComplete,
    proofs,
}) => {
    const [isProofModalShowing, setProofModal] = useState(false);
    const [phase, setPhase] = usePhase();
    const [roadmap, setRoadmap] = useRoadmap();
    const [_, setDeliverables] = useDeliverables();
    const [_p, _updateProofs, removeProof] = useProofs()
    const [showCompleteWarningModal, setCompleteWarningModal] = useState(false);
    const sdk = usePhaseSdk();
    const {publicKey} = useWallet()

    const isTeam = useMemo(() => publicKey?.toBase58() == roadmap?.account.teamAuthority.toBase58(), [publicKey, roadmap?.account.teamAuthority])

    const handleCompleteDeliverable = async (
        roadmap: AccountDTO<Roadmap> | undefined,
        phase: AccountDTO<Phase> | undefined,
        deliverable: AccountDTO<Deliverable>
    ) => {
        if (
            !sdk?.globalConfig ||
            phase === undefined ||
            roadmap === undefined
        ) {
            return;
        }

        const completeDeliverable$ = await sdk.completeDeliverable(
            deliverable.address,
            phase.address,
            roadmap.address
        );
        const toasts = new Map<string, string>();

        completeDeliverable$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setDeliverables([notification]);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Deliverable was marked as complete",
                    "Marking deliverable as complete failed!"
                );
            },
            error: (error) => {
                toast.error(error.toString());
            },
        });
    };

    const handleRevertCompletedDeliverable = async (
        roadmap: AccountDTO<Roadmap> | undefined,
        phase: AccountDTO<Phase> | undefined,
        deliverable: AccountDTO<Deliverable>
    ) => {
        if (
            !sdk?.globalConfig ||
            phase === undefined ||
            roadmap === undefined
        ) {
            return;
        }

        const revertDeliverable$ = await sdk.revertCompletedDeliverable(
            roadmap.address,
            phase.address,
            deliverable.address
        );
        const toasts = new Map<string, string>();

        revertDeliverable$.subscribe({
            next: async (notification) => {
                console.log(notification)
                if (notification.type === undefined && notification?.length > 0) {
                    setDeliverables(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Deliverable was marked as uncomplete",
                    "Marking deliverable as uncomplete failed!"
                );
            },
            error: (error) => {
                toast.error(error.toString());
            },
        });
    };

    const handleRemoveProof = async (
        roadmap: AccountDTO<Roadmap> | undefined,
        phase: AccountDTO<Phase> | undefined,
        deliverable: AccountDTO<Deliverable>,
        proofAddress : web3.PublicKey
    ) => {
        if (
            !sdk?.globalConfig ||
            phase === undefined ||
            roadmap === undefined
        ) {
            return;
        }

        if (deliverable.account.isComplete) {
            return
        }

        const removeProofs$ = await sdk.removeProofs(
            roadmap.address,
            phase.address,
            deliverable.address,
            [proofAddress]
        );
        const toasts = new Map<string, string>();

        removeProofs$.subscribe({
            next: async (notification) => {
                console.log(notification)
                if (notification.type === undefined && notification?.length > 0) {
                    removeProof(proofAddress, deliverable.address);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Proof removed from deliverable",
                    "Removing proof from deliverable failed!"
                );
            },
            error: (error) => {
                toast.error(error.toString());
            },
        });
    };

    return (
        <>
            <ConfirmationModal
                show={showCompleteWarningModal}
                title="Complete Deliverable"
                message="Are you sure you want to complete this deliverable you 
            will not be able to attached anymore proofs."
                onClose={() => setCompleteWarningModal(false)}
                onConfirmation={() =>
                    handleCompleteDeliverable(roadmap, phase, deliverable)
                }
            />
            <div className="relative flex flex-col p-5 shadow bg-gray-700 bg-opacity-50 rounded-md">
                <div className="">
                    <label className="text-sm font-light text-gray-400">
                        Deliverable
                    </label>
                    <div>{description}</div>
                </div>

                <div className="absolute top-2 right-3 flex gap-2">
                    {phase?.account.state?.actioned && isTeam && !isComplete ? (
                        <span
                            onClick={() => setProofModal(true)}
                            title="Attach proof to your deliverable"
                            className="text-gray-300 bg-gray-600 rounded-full p-1 hover:bg-gray-500 hover:text-white cursor-pointer "
                        >
                            <LinkIcon className="w-5" />
                        </span>
                    ) : (
                        <></>
                    )}
                    {isComplete ? (
                        <span className={`${isTeam && phase?.account.state?.actioned ? " hover:text-red-400 cursor-pointer hover:bg-gray-500" : ""} text-green-300 bg-gray-600 rounded-full p-1`}
                            onClick={() => isTeam ? handleRevertCompletedDeliverable(roadmap, phase, deliverable) : null}
                        >
                            <CheckCircleIcon className="w-5" />
                        </span>
                    ) : (
                        <></>
                    )}
                    {!isComplete && phase?.account.state?.actioned ? (
                        <span className={`${isTeam ? "hover:text-green-300 cursor-pointer hover:bg-gray-500" : ""}  text-gray-300 bg-gray-600 rounded-full p-1`}>
                            <CheckCircleIcon
                                className="w-5"
                                onClick={() =>
                                    isTeam ? 
                                    proofs.length === 0
                                        ? toast.error(
                                              "You need to attach proof(s) of completion of deliverable"
                                          )
                                        : setCompleteWarningModal(true)
                                    : null 
                                }
                            />
                        </span>
                    ) : (
                        <></>
                    )}
                </div>

                {phase && roadmap ? (
                    <AddProofModal
                        roadmap={roadmap}
                        deliverable={deliverable}
                        show={isProofModalShowing}
                        onClose={() => setProofModal(false)}
                    />
                ) : (
                    <></>
                )}

                <div className="flex flex-wrap justify-start gap-3 mt-auto pt-4">
                    {proofs.map((proof, i) => {
                        return (
                          <ProofItem key={proof.address.toBase58()} proof={proof} index={i} isDeliverableComplete={isComplete} onClick={(proof) => handleRemoveProof(roadmap, phase, deliverable, proof.address)} isTeam={isTeam}/>
                        );
                    })}
                </div>

                {/* </div> */}
            </div>
        </>
    );
};

interface PhaseDeliverablesArgs {}

const PhaseDeliverables: FC<PhaseDeliverablesArgs> = ({}) => {
    const [modalIsShowing, setModalIsShowing] = useState<boolean>(false);

    const [deliverables, setDeliverables] = useDeliverables();
    const [proofs, setProofs] = useProofs();
    const [roadmap] = useRoadmap();
    const [phase, setPhase] = usePhase();
    const sdk = usePhaseSdk();
    const wallet = useWallet();
    const isTeamWallet = useMemo(
        () =>
            wallet.publicKey?.toBase58() ===
            roadmap?.account.teamAuthority.toBase58(),
        [wallet.publicKey, roadmap]
    );

    const handleAddDeliverable = async (
        phase: web3.PublicKey,
        descriptions: string[]
    ) => {
        if (!wallet.connected || !wallet.publicKey || !sdk?.globalConfig) {
            return;
        }
        const deliverableArgs: InitDeliverablesArgs = {
            descriptions: descriptions,
            phaseAddress: phase,
        };
        const initDeliverable$ = await sdk.initDeliverables(deliverableArgs);
        const toasts = new Map<string, string>();

        initDeliverable$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setDeliverables(notification);
                }

                await handleTxNotifications(
                    notification,
                    toasts,
                    "Deliverables added successfully!",
                    "Deliverables failed to be added!"
                );
            },
            error: (error) => {
                toast.error(error.toString());
            },
        });
    };

    return (
        <div className="mt-6 mb-2">
            <div className="flex justify-between h-fit items-center">
                <h1 className="text-2xl py-3 font-md text-white font-syne flex items-center">
                    Deliverables
                    <Tooltip
                        text="Deliverables are the concrete items that will qualify the completion of a phase. When a deliverable is complete you can add a proof to it for users to verify."
                        position="top"
                        width={320}
                    >
                        <InformationCircleIcon className="w-4 h-4" />
                    </Tooltip>
                </h1>
                {isTeamWallet && phase && phase.account.state?.draft ? (
                    <button
                        onClick={(e) => setModalIsShowing(true)}
                        className="outline outline-1 text-sm outline-maroon-flush inline-flex items-center justify-center p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
                    >
                        New Deliverable
                    </button>
                ) : (
                    <></>
                )}
            </div>
            {phase ? (
                <AddDeliverableModal
                    phase={phase}
                    handleAddDeliverable={handleAddDeliverable}
                    show={modalIsShowing}
                    onClose={() => setModalIsShowing(false)}
                />
            ) : (
                <></>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 text-white">
                {deliverables.map((deliverable) => {
                    return (
                        <DeliverableItem
                            key={deliverable.address.toBase58()}
                            proofs={Object.values(proofs)
                                .flat()
                                .filter(
                                    (proof) =>
                                        proof.account.deliverable.toBase58() ===
                                        deliverable.address.toBase58()
                                )}
                            description={deliverable.account.description}
                            isComplete={deliverable.account.isComplete}
                            deliverable={deliverable}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PhaseDeliverables;
