import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { XCircleIcon } from "@heroicons/react/outline";
import { useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import { truncateKey } from "../../libs/utils";
import { Transition, Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Tooltip } from "../../components/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { useForm } from "react-hook-form";
import usePhaseSdk from "../../hooks/usePhaseProtocol";
import { lastValueFrom } from "rxjs";
import { AccountDTO, InitRoadmapArgs, PhaseProtocolSDK, PHASE_MACHINE_ADDRESSS_DEVNET, PHASE_PROGRAM_ADDRESS_DEVNET, Roadmap } from "@dedmonkes/phase-protocol-sdk";
import { useRoadmaps } from "../../state/hooks/roadmaps";
import { MetadataStorage } from "@dedmonkes/shadow-helper";
import { Connection, PublicKey } from "@solana/web3.js";
import bs58 from "bs58";
import { NFTPicker, NftType, WalletNFT } from "./NFTPicker";
import { web3 } from "@project-serum/anchor";

export enum CollectionType {
    NEW,
    EXISTING,
}

const RoadmapCreationForm = () => {
    const navigate = useNavigate();
    const [form] = useState<{}>({});
    const [roadmapCreated, setRoadmapCreated] = useState(false);
    const [, setRoadmap] = useRoadmaps();
    const [onChainCollectionSize, setOnChainCollectionSize] = useState<number | null>(null);

    const [tosAgreed, setTosAgreed] = useState(false);

    const [roadmapCreatedData] = useState<{
        roadmapAddress: string;
        collectionMintAddress: string;
    }>({
        roadmapAddress: "",
        collectionMintAddress: "",
    });
    const wallet = useWallet();
    const phase = usePhaseSdk();

    const [collectionPFP, setCollectionPFP] = useState<File | null>(null);

    const [collectionType, setCollectionType] = useState(CollectionType.NEW);
    const [selectedNFT, setSelectedNFT] = useState< {nft : NftType, metadata : any} | null>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if(selectedNFT){
            setOnChainCollectionSize(selectedNFT.nft?.collectionDetails?.size ? selectedNFT.nft.collectionDetails.size : null)
        }
    }, [selectedNFT])

    const createRoadmap = async (form: any) => {
        if (!tosAgreed) {
            toast.error("Please agree to the Terms and Conditions first.");
            return;
        }

        if (!wallet.connected || !wallet.publicKey || !form) {
            toast.error("Connect your wallet to continue.");
            return;
        }

        let { name, symbol, description, twitter, website, size, launchpad } =
            form as Record<string, string>;

        if (collectionType === CollectionType.EXISTING){
            if(selectedNFT){
                symbol = selectedNFT?.nft.symbol
            }
            
        }
        if (symbol.includes(" ") || symbol.length > 3) {
            toast.error(
                "Symbol must be max 3 characters and cannot contain spaces"
            );
            return;
        }

        if (Number(size) === 0 && onChainCollectionSize === 0) {
            toast.error("Existing collection size must not be 0");
            return;
        }

        if (collectionPFP === null) {
            toast.error("Collection PFP is required");
            return;
        }

        if (!collectionPFP.type.includes("image")) {
            toast.error("Collection PFP must be an image");
        }

        const conn = new Connection(process.env.REACT_APP_MAINNET_RPC!);
        const ms = await MetadataStorage.create(conn, wallet as any);

        let md;
        const mdToastId = toast.loading(
            "Uploading metadata for roadmap to Shadow Drive. This can take a while and several approvals."
        );

        try {
            md = await ms.createCollectionMetadata(collectionPFP, {
                name,
                description,
                symbol,
                website,
                twitter,
            });
        } catch (err: any) {
            toast.error(
                "An error occured creating metadata: " + err.toString(),
                {
                    id: mdToastId,
                }
            );
            return;
        }

        toast.success("Metadata created succesfully.", {
            id: mdToastId,
        });

        const observable = await phase.initRoadmap({
            name: name,
            collectionSymbol: symbol,
            authority: wallet.publicKey,
            devAuthority: wallet.publicKey,
            collectionUri: md.collectionUri,
            metadataShadowDrive: ms.getStorageKey(),
            maxVotingTime: 15,
            mintProgram : launchpad ? new PublicKey(launchpad) : new web3.PublicKey("6mT9Fd6upb5PrMwmFNovYVXadoW2AQtJtCSUrWYqYxqd"),
            collectionSize: Number(size),
            collectionMint: selectedNFT
                ? selectedNFT.nft.mintAddress
                : undefined,
        });

        const toasts = new Map<string, string>();
        const failed: string[] = [];

        observable.subscribe({
            next: async (notification) => {
                if (!notification.type) {
                    toast.success("Process completed successfully!");
                    setRoadmap(notification);
                    navigate(`/roadmap/${notification.address.toBase58()}`);
                    return;
                }

                if (notification.type === "Information") {
                    const toastId = toast.loading(
                        `Sending transaction ${truncateKey(notification.sig)}`
                    );
                    toasts.set(notification.sig, toastId);
                    return;
                }

                if (notification.type === "Success") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.success(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} confirmed`,
                            { id: toastId }
                        );
                    }
                    return;
                }

                if (notification.type === "Error") {
                    const toastId = toasts.get(notification.sig);
                    if (toastId) {
                        toast.error(
                            `Transaction ${truncateKey(
                                notification.sig
                            )} failed`,
                            {
                                id: toastId,
                            }
                        );
                    }
                    failed.push(notification.sig);
                    return;
                }

                // we have the roadmap object
                if (failed.length > 0) {
                    toast.error(
                        "Roadmap name is already in use. Please try again."
                    );
                    return;
                }
            },
            error: (error: any) => {
                console.log(error);
                toast.error(error.toString());
            },
        });
    };

    return (
        <>
            {form && (
                <>
                    <form onSubmit={handleSubmit(createRoadmap)}>
                    <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Roadmap Title{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Choose a generic name for your roadmap in case each phase within it needs to be flexible">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="roadmapTitle"
                                    type="text"
                                    {...register("name", { required: true })}
                                    placeholder="DedMonkes"
                                    className="input"
                                />
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Project name is required
                                    </p>
                                )}
                            </div>
                        </>
                    <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Roadmap Description{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="A short description of your NFT project.">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="roadmapDescription"
                                    type="text"
                                    {...register("description", {
                                        required: true,
                                    })}
                                    placeholder="Ded Monkes are here to revive the Solana Blockchain"
                                    className="input"
                                />
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Roadmap description is required
                                    </p>
                                )}
                            </div>
                        </>
                        <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Pre mint roadmap or post mint?{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Is the roadmap to be associated to an existing collection or a pre-mint collection that is to minted out with a phase integrated launchpad. Existing collections must be on chain collectons an have nfts associated.">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <label className="block mb-2 text-sm font-medium text-gray-400 dark:text-gray-400">
                                Select an option
                            </label>
                            <select
                                value={collectionType}
                                id="countries"
                                className="input"
                                onChange={(e) => {
                                    setCollectionType(Number(e.target.value));
                                }}
                            >
                                <option value={CollectionType.NEW}>
                                    Pre Mint
                                </option>
                                <option value={CollectionType.EXISTING}>
                                    Post Mint
                                </option>
                            </select>
                        </>

                        {collectionType === CollectionType.EXISTING &&
                            wallet.publicKey &&  (
                                <>
                                    <NFTPicker
                                        publicKey={wallet.publicKey}
                                        onChange={(nft) => {
                                            setSelectedNFT(nft);
                                        }}
                                    />
                                
                                    <>
                                    <label
                                        htmlFor="roadmapTitle"
                                        className="mt-8 flex items-center font-medium text-gray-200"
                                    >
                                        Collection Size{" "}
                                        <span className="text-maroon-flush-400">
                                            *
                                        </span>
                                        <Tooltip text="Input the size of your existing collection. This will be used for governance and displayed to your fundraise contributors.">
                                            <BiInfoCircle />
                                        </Tooltip>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="roadmapTitle"
                                            type="number"
                                            {...register("size", {
                                                required: onChainCollectionSize === null ? true : false,
                                            })}
                                            disabled={onChainCollectionSize !== null ? true : false }
                                            placeholder={ onChainCollectionSize !== null ? onChainCollectionSize.toString() : undefined }
                                            defaultValue={ onChainCollectionSize !== null ? onChainCollectionSize : undefined }
                                            className="input"
                                        />
                                        {errors.size && (
                                            <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                                Collection size is required
                                            </p>
                                        )}
                                    </div>
                                    </>
                                
                                </>
                            )}

  
                        {collectionType === CollectionType.NEW &&
                            wallet.publicKey && (
                                <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Collection Symbol{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Three character or less symbol to define your NFT collection.">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="roadmapTitle"
                                    type="text"
                                    {...register("symbol", {
                                        required: true,
                                    })}
                                    placeholder={"DM"}
                                    className="input"
                                />
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Collection symbol is required
                                    </p>
                                )}
                            </div>
                            </>
                            )}


                        <label className="mt-8 block font-medium text-gray-200">
                            Roadmap Image / PFP (recommended at least
                            500x500){" "}
                            <span className="text-maroon-flush-400">*</span>
                        </label>
                        <label className="mt-2 flex justify-center w-full h-32 px-4 transition border-2 border-gray-600 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                                <span className="font-medium text-gray-400">
                                    <span>
                                        {collectionPFP !== null &&
                                            collectionPFP.name}
                                    </span>
                                    <span className="text-maroon-flush underline ml-1">
                                        Browse for image
                                    </span>
                                </span>
                            </span>
                            <input
                                onChange={(e) => {
                                    if (!e.target.files) {
                                        return;
                                    }
                                    setCollectionPFP(e.target.files[0]);
                                }}
                                type="file"
                                className="hidden"
                            />
                        </label>

                        <>
                            <label
                                htmlFor="roadmapLaunchpad"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Launchpad{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Please indicate what launchpad you want to use for your mint">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <select
                                    id="roadmapLaunchpad"
                                    {...register("launchpad")}
                                    defaultValue="6mT9Fd6upb5PrMwmFNovYVXadoW2AQtJtCSUrWYqYxqd"
                                    className="input appearance-none"
                                >
                                    <option value="eERFprSmhDX7an71Kqg5ZjG3JoeMqLAZN4DGkvmqr3M" disabled={true}>
                                        Phase Machine
                                    </option>
                                    <option value="6mT9Fd6upb5PrMwmFNovYVXadoW2AQtJtCSUrWYqYxqd">Raise Plugin</option>

                                    <option value="" disabled={true}>
                                        Trust Labs
                                    </option>
                                </select>
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Project launchpad is required
                                    </p>
                                )}
                            </div>
                        </>

                        <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Website{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Choose a generic name for your roadmap in case each phase within it needs to be flexible">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="roadmapTitle"
                                    type="text"
                                    {...register("website", { required: true })}
                                    placeholder="https://dedmonkes.com"
                                    className="input"
                                />
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Project website is required
                                    </p>
                                )}
                            </div>
                        </>
                        <>
                            <label
                                htmlFor="roadmapTitle"
                                className="mt-8 flex items-center font-medium text-gray-200"
                            >
                                Twitter URL{" "}
                                <span className="text-maroon-flush-400">*</span>
                                <Tooltip text="Choose a generic name for your roadmap in case each phase within it needs to be flexible">
                                    <BiInfoCircle />
                                </Tooltip>
                            </label>
                            <div className="mt-2">
                                <input
                                    id="roadmapTitle"
                                    type="text"
                                    {...register("twitter", { required: true })}
                                    placeholder="https://twitter.com/dedmonkes"
                                    className="input"
                                />
                                {errors.name && (
                                    <p className="text-sm py-2 text-maroon-flush-100 block rounded">
                                        Project twitter url is required
                                    </p>
                                )}
                            </div>
                        </>

                        <div className="flex items-center mt-12">
                            <input
                                onChange={async (e) => {
                                    if (tosAgreed) {
                                        e.preventDefault();
                                        return;
                                    }

                                    if (!wallet.publicKey) {
                                        toast.error(
                                            "Please connect your wallet."
                                        );
                                        return;
                                    }
                                    const toastId = toast.loading(
                                        "Please sign a message to agree to our Terms of Service..."
                                    );

                                    try {
                                        const message = `By signing this message, I, ${wallet.publicKey?.toBase58()}, am agreeing to abide by Phase Protocol's terms of service (https://app.phaseprotocol.io/terms-of-service) on ${new Date(
                                            Date.now()
                                        ).toUTCString()}`;
                                        const sig = await (
                                            wallet as any
                                        ).signMessage(
                                            new TextEncoder().encode(message)
                                        );

                                        const agreement = {
                                            pubkey: wallet.publicKey.toBase58(),
                                            message,
                                            signature: bs58.encode(sig),
                                        };

                                        const res = await fetch(
                                            `${process.env
                                                .REACT_APP_PHASE_API!}/tos`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type":
                                                        "application/json",
                                                },
                                                body: JSON.stringify(agreement),
                                            }
                                        );

                                        if (res.status === 200) {
                                            toast.success(
                                                "Agreement accepted!",
                                                {
                                                    id: toastId,
                                                }
                                            );
                                            setTosAgreed(true);
                                        }
                                    } catch (err) {
                                        toast.error((err as any).toString(), {
                                            id: toastId,
                                        });
                                    }
                                }}
                                checked={tosAgreed}
                                id="checked-checkbox"
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="checked-checkbox"
                                className="ml-2 text-sm font-medium text-white"
                            >
                                I agree to the{" "}
                                <Link
                                    to="/terms-of-service"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Terms of Service
                                </Link>
                            </label>
                        </div>

                        <p className="mt-3">
                            <button
                                type="submit"
                                className="bg-maroon-flush border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-maroon-flush-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500"
                            >
                                Create Roadmap
                            </button>
                        </p>
                        <p className="text-white mt-2">
                            <small>
                                There will be a total of 4 transactions.
                            </small>
                        </p>
                    </form>
                </>
            )}

            <Transition appear show={roadmapCreated} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setRoadmapCreated(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl p-8 bg-[#111111] shadow-md text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold leading-6 text-white"
                                    >
                                        Awesome! Your Roadmap is Ready.
                                    </Dialog.Title>
                                    <button
                                        className="absolute right-0 top-0 p-4"
                                        onClick={() => setRoadmapCreated(false)}
                                    >
                                        <XCircleIcon className="text-white w-8" />
                                    </button>
                                    <p className="text-gray-400 my-5">
                                        Please take down this information. You
                                        will need these keys for your launchpad.
                                    </p>
                                    <p className="text-white">
                                        <strong>Roadmap Address:</strong>
                                        <br />
                                        {/* ts-ignore */}
                                        {roadmapCreatedData.roadmapAddress}
                                    </p>
                                    <p className="text-white mt-4">
                                        <strong>
                                            Collection Mint Address:
                                        </strong>
                                        <br />
                                        {
                                            roadmapCreatedData.collectionMintAddress
                                        }
                                    </p>

                                    <Link
                                        to={`/roadmap/${roadmapCreatedData.roadmapAddress}`}
                                        className="mt-8 bg-maroon-flush border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-maroon-flush-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500"
                                    >
                                        Visit Your Roadmap
                                    </Link>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default RoadmapCreationForm;
