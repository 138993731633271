import {
    AccountDTO,
    PhaseProtocolSDK,
    Roadmap,
} from "@dedmonkes/phase-protocol-sdk";
import { PublicKey } from "@solana/web3.js";

export async function getFeaturedRoadmaps(
    sdk: PhaseProtocolSDK
): Promise<AccountDTO<Roadmap>[]> {
    /**
     * Lets put this back in with some error handling for now only need some hardcoded 
     * roadmaps since we are closed beta
     */
    // const res = await fetch(`${process.env.REACT_APP_PHASE_API}/featured`);
    // const ftd = await res.json();

    // const pubkeys: PublicKey[] = ftd.map(
    //     (rmd: any) => new PublicKey(rmd.pubkey)
    // );

    const ballonsvilleRoadmap = new PublicKey("C6ryt1EWciJW4n57tageWxe2ypKwzPiJEAKUdhgbMAL2")
    const fudBoisRoadmap = new PublicKey("5YosLc3NAtah1Nuqi66o18W9CjdpX1xULQEwDd6ieTzS")

    const pubkeys = [ballonsvilleRoadmap, fudBoisRoadmap]

    const roadmaps = await sdk.getRoadmap(pubkeys);
    return roadmaps.map((rm: any) => {
        (rm as any).featured = true;
        return rm;
    });
}
