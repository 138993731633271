export default function Feed() {
    return (
        <>
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    Feed
                </div>
            </main>
        </>
    );
}
