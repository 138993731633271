import { truncateKey } from "../libs/utils";
import { Roadmap, AccountDTO } from "@dedmonkes/phase-protocol-sdk";
import { useRoadmap } from "../state/hooks/roadmaps";
import { useEffect } from "react";

export default function RoadmapInfo({}: {}): JSX.Element {
    const network = "?cluster=devnet";
    const [roadmap, setRoadmap] = useRoadmap();


  return roadmap && roadmap.account.collection ? (
    <div className="py-2 px-0 leading-3 text-white">
      <table className="table table-auto w-full">
        <tbody>
        <tr className="py-2 flex justify-between">
            <td>Roadmap</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.address.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.address.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
          <td>Collection</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.collection.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.collection.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>Realm</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.realm.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.realm.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>Team Auth</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.teamAuthority.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.teamAuthority.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>DAO Auth</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.daoAuthority.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.daoAuthority.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>USDC Pool</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.usdcRoadmapPool.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.usdcRoadmapPool.toString())}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>SOL Pool</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.solRoadmapPool.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {truncateKey(roadmap.account.solRoadmapPool.toString())}
              </a>
            </td>
          </tr>
          
          <tr className="py-2 flex justify-between">
            <td>Fundraising Plugin</td>
            <td>
              <a
                href={`https://solscan.io/account/${roadmap.account.config.mintingAccountPluginProgramAddress?.toString()}/${network}`}
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {roadmap.account.config.mintingAccountPluginProgramAddress? truncateKey(roadmap.account.config.mintingAccountPluginProgramAddress.toString()) : "n/a"}
              </a>
            </td>
          </tr>
          <tr className="py-2 flex justify-between">
            <td>Phases Complete</td>
            <td>
              {roadmap.account.phasesComplete}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
}
