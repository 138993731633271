import { useEffect, useState } from "react";

const ConfirmationModal = ({
    title,
    message,
    show,
    onClose,
    onConfirmation,
    stringMatchConfirm,
}: {
    title: string;
    message: string;
    show: boolean;
    onClose: () => void;
    onConfirmation: () => void;
    stringMatchConfirm?: string;
}) => {
    const [stringMatch, setStringMatch] = useState("");
    return (
        <>
            {show ? (
                <div className="fixed top-0 left-0 w-full h-full z-50">
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                    <div className="fixed inset-0 h-full"></div>
                    <div className="flex relative min-h-full items-center justify-center p-4 text-center">
                        <div
                            className="flex-row items-between w-full h-full text-white
                        max-w-xl transform 
                        rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all"
                        >
                            <div className="text-xl font-semibold">{title}</div>
                            <br></br>
                            <div className="text-white">{message}</div>
                            {stringMatchConfirm && (
                                <>
                                    <p className="my-2 font-sm text-maroon-flush-300">
                                        Type "{stringMatchConfirm}" and press
                                        confirm.
                                    </p>
                                    <input
                                        type="text"
                                        name="stringMatch"
                                        className="input font-sm h-10"
                                        onChange={(e) =>
                                            setStringMatch(e.target.value)
                                        }
                                    />
                                </>
                            )}
                            <div className="flex gap-4 w-full justify-center">
                                <button
                                    className="btn w-full btn-fill"
                                    disabled={
                                        stringMatchConfirm !== undefined &&
                                        stringMatch !== stringMatchConfirm
                                    }
                                    onClick={() => {
                                        onConfirmation();
                                        onClose();
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="border border-maroon-flush mt-5 w-full inline-flex items-center justify-center 
                                    p-2 px-6 rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600
                                     focus:ring-white"
                                    onClick={onClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default ConfirmationModal;
