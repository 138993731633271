import { Fragment, Suspense } from "react";
import { Disclosure } from "@headlessui/react";
import {
  BellIcon,
  ChevronDownIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline";
import CollectionSearch from "../components/CollectionSearch";
import { Link } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Menu, Transition } from "@headlessui/react";
import { UriImage } from "../utils";
import { projectsState } from "../state/roadmaps";
import { useRecoilState } from "recoil";
import { useRoadmapsByAuthority } from "../state/hooks/roadmaps";
import LoadingSpinner from "./LoadingSpinner";
import LoadingSpinnerButton from "./LoadingSpinnerButton";

const navigation = [
  {
    name: "Feeds",
    href: "#",
  },
];

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const Menuitems = () => {
  const projects = useRoadmapsByAuthority();
  return (
    <Menu as="div" className="relative ml-auto">
      <div>
        {projects.length ? (
          <Menu.Button className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-600 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500">
            Manage
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        ) : (
          <Link
            to={`/roadmap/new`}
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-maroon-flush-600 hover:bg-maroon-flush-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-maroon-flush-500"
          >
            Create New Roadmap
          </Link>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-[#111111] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {projects.length &&
            projects.map((rm, i) => (
              <Menu.Item key={`nav-${i}`}>
                {({ active }) => (
                  <Link
                    to={`/roadmap/${rm.address}`}
                    className={classNames(
                      active ? "bg-gray-800" : "",
                      "block px-4 py-2 text-sm text-gray-200"
                    )}
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-6 rounded-sm overflow-hidden">
                        {/* {UriImage(rm.account)} */}
                      </div>
                      {rm.account.name}
                    </div>
                  </Link>
                )}
              </Menu.Item>
            ))}
          <Menu.Item>
            {({ active }) => (
              <Link
                to={`/roadmap/new`}
                className={classNames(
                  active ? "bg-gray-800" : "",
                  "block px-4 py-2 text-sm text-gray-200 border-t border-gray-800"
                )}
              >
                Create New Roadmap
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default function Nav() {
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16 relative z-10">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="h-10 w-10"
                      src="/phase-logo.svg"
                      alt="Workflow"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex-1 px-2 justify-center hidden md:block mr-3">
                <div className="w-full lg:max-w-2xl mx-auto">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <CollectionSearch />
                </div>
              </div>
              <Suspense fallback={<LoadingSpinnerButton />}>
                <Menuitems />
              </Suspense>

              <div className="">
                <div className="ml-2 flex items-center">
                  <WalletMultiButton className="" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 px-2 justify-center md:hidden">
            <div className="w-full lg:max-w-2xl mx-auto">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <CollectionSearch />
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
