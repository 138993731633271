/**
 *
 * @param n a number
 * @returns A comma separated number string
 */
export function prettyPrintNumber(n: number, decimals = 2): string {
    return Number(n.toFixed(decimals))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
