import { AccountDTO, Phase } from "@dedmonkes/phase-protocol-sdk";
import { Tab } from "@headlessui/react";
import { web3 } from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { usePhases, useRefreshPhases } from "../state/hooks/phases";
import { isCreatingPhase as phaseCreate } from "../state/roadmaps";
import { useCurrentRoadmapKey, useRoadmap } from "../state/hooks/roadmaps";
import PhaseCard from "./PhaseCard";

const PhaseList = () => {
    const [phases, setPhases] = usePhases();
    const [roadmap, setRoadmap] = useRoadmap();
    const wallet = useWallet();
    const [canEditPhase, setCanEditPhase] = useState(false);
    const [isFounder, setIsFounder] = useState(false);
    const [isCreatingPhase, setIsCreatingPhase] =
        useRecoilState<boolean>(phaseCreate);

    useEffect(() => {
        if (roadmap && wallet.publicKey) {
            if (
                wallet.publicKey.toBase58() ===
                roadmap.account.teamAuthority.toString()
            ) {
                setIsFounder(true);
            }
        }
    }, [roadmap, wallet.publicKey]);

    useEffect(() => {
        if (roadmap?.account.state?.resolution && isFounder) {
            setCanEditPhase(true);
        }
    }, [roadmap, isFounder]);

    const phasesVotingInProgress = useMemo(
        () =>
            phases?.filter(
                (p: AccountDTO<Phase>) =>
                    p.account.state &&
                    (!!p.account.state.stagedForCompletion ||
                        !!p.account.state.stagedForApproval)
            ),
        [phases]
    );

    const phasesWorkInProgress = useMemo(
        () =>
            phases?.filter(
                (p: AccountDTO<Phase>) =>
                    p.account.state && !!p.account.state.actioned
            ),
        [phases]
    );

    const phasesApproved = useMemo(
        () =>
            phases?.filter(
                (p: AccountDTO<Phase>) =>
                    p.account.state && !!p.account.state.approved
            ),
        [phases]
    );

    const phasesDraft = useMemo(
        () =>
            phases?.filter(
                (p: AccountDTO<Phase>) =>
                    p.account.state && !!p.account.state.draft
            ),
        [phases]
    );

    const phasesComplete = useMemo(
        () =>
            phases?.filter(
                (p: AccountDTO<Phase>) =>
                    p.account.state && !!p.account.state.complete
            ),
        [phases]
    );

    const phaseFilters = [
        {
            title: "All",
            count: phases.length,
        },
        {
            title: "Voting In Progress",
            count: phasesVotingInProgress.length,
        },
        {
            title: "Work In Progress",
            count: phasesWorkInProgress.length,
        },
        {
            title: "Approved",
            count: phasesApproved.length,
        },
        {
            title: "Draft",
            count: phasesDraft.length,
        },
        {
            title: "Completed",
            count: phasesComplete.length,
        },
    ];

    return (
        <>
            <Tab.Group>
                <Tab.List className="flex items-center justify-start gap-4 text-white mb-4 overflow-auto min-w-full md:max-w-xl">
                    {phaseFilters.map((pt): any => (
                        <Tab
                            key={pt.title}
                            className="focus:outline-none text-sm"
                        >
                            {({ selected }) => (
                                <span
                                    className={`
                      ${
                          selected
                              ? "bg-gray-700 bg-opacity-50"
                              : "opacity-75 focus:none"
                      }
                        px-4 py-2 rounded-md flex-1 block whitespace-nowrap
                    `}
                                >
                                    {pt.title}
                                    <span className="ml-1 rounded text-maroon-flush-200 font-bold">
                                        {pt.count}
                                    </span>
                                </span>
                            )}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="text-white mb-8">
                    <Tab.Panel>
                        <>
                            {phases.map((p: AccountDTO<Phase>, i: any) => (
                                <PhaseCard
                                    phase={p}
                                    key={p.address.toBase58()}
                                    idx={i}
                                    canEdit={
                                        (!!p.account.state?.draft &&
                                            isFounder) ||
                                        canEditPhase
                                    }
                                />
                            ))}
                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            {phasesVotingInProgress.length ? (
                                phasesVotingInProgress.map(
                                    (p: AccountDTO<Phase>, i: any) => (
                                        <PhaseCard
                                            phase={p}
                                            key={p.address.toBase58()}
                                            idx={i}
                                        />
                                    )
                                )
                            ) : (
                                <p className="p-8 bg-gray-700 bg-opacity-50 rounded-md">
                                    There are no phases to vote on right now.
                                </p>
                            )}
                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            {phasesWorkInProgress.length ? (
                                phasesWorkInProgress.map((p: any, i: any) => (
                                    <PhaseCard
                                        phase={p}
                                        key={p.address.toBase58()}
                                        idx={i}
                                    />
                                ))
                            ) : (
                                <p className="p-8  bg-gray-700 bg-opacity-50 rounded-md">
                                    There are no active phases being worked on.
                                </p>
                            )}
                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            {phasesApproved.length ? (
                                phasesApproved.map((p: any, i: any) => (
                                    <PhaseCard
                                        phase={p}
                                        key={p.address.toBase58()}
                                        idx={i}
                                    />
                                ))
                            ) : (
                                <p className="p-8  bg-gray-700 bg-opacity-50 rounded-md">
                                    There are no approved phases yet.
                                </p>
                            )}
                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            {phasesDraft.length ? (
                                phasesDraft.map((p: any, i: any) => (
                                    <PhaseCard
                                        phase={p}
                                        key={p.address.toBase58()}
                                        idx={i}
                                        canEdit={true}
                                    />
                                ))
                            ) : (
                                <p className="p-8  bg-gray-700 bg-opacity-50 rounded-md">
                                    There are no phases currently drafted.
                                </p>
                            )}
                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            {phasesComplete.length ? (
                                phasesComplete.map((p: any, i: any) => (
                                    <PhaseCard
                                        phase={p}
                                        key={p.address.toBase58()}
                                        idx={i}
                                    />
                                ))
                            ) : (
                                <p className="p-8  bg-gray-700 bg-opacity-50 rounded-md">
                                    There are no phases currently completed.
                                </p>
                            )}
                        </>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>

            {wallet.publicKey &&
                roadmap &&
                wallet?.publicKey?.toBase58() ===
                    roadmap?.account.teamAuthority.toBase58() &&
                (!phases.length ? (
                    <div className="p-16 border-2 border-dashed border-gray-700 rounded-md bg-[#111111]">
                        <div className="text-center">
                            <h4 className="text-lg text-white mb-2">
                                There are no phases for this roadmap yet.
                            </h4>
                            <p className="text-sm text-gray-200">
                                You will need to at least 5 phases in order to
                                lock a roadmap for voting
                            </p>
                            <p>
                                <button
                                    onClick={(e) => setIsCreatingPhase(true)}
                                    className="btn"
                                >
                                    Add Phase
                                </button>
                            </p>
                        </div>
                    </div>
                ) : (
                    <p>
                        <button
                            onClick={(e) => setIsCreatingPhase(true)}
                            className="btn"
                        >
                            Add Phase
                        </button>
                    </p>
                ))}
        </>
    );
};

export default PhaseList;
