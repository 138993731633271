// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBu334Btsoj0tgJXUh-2AlQZVZaPRNzqfs",
  authDomain: "guides-407817.firebaseapp.com",
  projectId: "guides-407817",
  storageBucket: "guides-407817.appspot.com",
  messagingSenderId: "831651155005",
  appId: "1:831651155005:web:fc9a79a4cb3fac9160ee54",
  measurementId: "G-DG7DVDHTK8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app)
const analytics = getAnalytics(app);