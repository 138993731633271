/* This example requires Tailwind CSS v2.0+ */
// import { CalendarIcon, ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
// import CommunityMembers from '../components/CommunityMembers';
import { useEffect, useState, useMemo, Suspense } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import RoadmapVotes from "../components/RoadmapVotes";
import RoadmapLockup from "../components/RoadmapLockup";
import AccordionBox from "../components/AccordionBox";
import Progress from "../components/Progress";
import {
    AccountDTO,
    Deliverable,
    InitDeliverablesArgs,
    Phase,
    PhaseProtocolSDK,
    Proof,
    Roadmap,
    Roadmap as RoadmapType,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { getRealm, Realm, ProposalState } from "@dedmonkes/spl-governance";
import toast from "react-hot-toast";
import PhaseInfo from "../components/PhaseInfo";
import { handleTxNotifications } from "../libs/utils";
import { useRecoilValue } from "recoil";
import { phases, roadmapState, solVaultAmountState } from "../state/roadmaps";
import VotingInfoPanel from "../components/VotingInfoPanel";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { web3 } from "@project-serum/anchor";
import PhaseDeliverables from "../components/PhaseDeliverables";
import {
    useCurrentRoadmapKey,
    useRoadmap,
    useRoadmaps,
} from "../state/hooks/roadmaps";
import { usePhase, usePhases } from "../state/hooks/phases";
import { useDeliverables, useProofs } from "../state/hooks/deliverables";
import { lastValueFrom } from "rxjs";
import PhaseHeader from "../components/PhaseHeader";
import RoadmapHeaderSkeleton from "../components/skeleton/RoadmapHeaderSkeleton";
import PhaseSkeleton from "../components/skeleton/PhaseSkeleton";
import NextStepsBox from "../components/NextStepsBox";
import SidebarSkeleton from "../components/skeleton/SidebarSkeleton";

export default function PhaseView() {
    return (
        <>
            <main className="">
                <div className="max-w-7xl mx-auto py-8 px-6 lg:px-8">
                    {/* <Breadcrumbs pages={pages}/> */}

                    <div className="block lg:flex gap-16 pb-12 mt-12">
                        <div className="flex-1">
                            <Suspense fallback={<RoadmapHeaderSkeleton />}>
                                <PhaseHeader />
                            </Suspense>

                            <Suspense fallback={<PhaseSkeleton />}>
                                <PhaseDeliverables />
                            </Suspense>
                        </div>

                        <div className="flex-0 lg:max-w-[360px] w-full">
                            <h3 className="text-2xl font-white text-white mb-4 font-black font-syne">
                                Phase Information
                            </h3>
                            <div className="pb-6">
                                {/* Start right column area */}
                                <div
                                    className="h-full relative"
                                    style={{ minHeight: "16rem" }}
                                >
                                    <Suspense
                                        fallback={
                                            <SidebarSkeleton classes="w-full h-24 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                        }
                                    >
                                        <VotingInfoPanel />
                                    </Suspense>
                                    <AccordionBox title="Locked Up">
                                        <Suspense
                                            fallback={
                                                <SidebarSkeleton classes="w-full h-24 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                            }
                                        >
                                            <RoadmapLockup />
                                        </Suspense>
                                    </AccordionBox>
                                    <AccordionBox title="Phase Info">
                                        <Suspense
                                            fallback={
                                                <SidebarSkeleton classes="w-full h-24 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                            }
                                        >
                                            <PhaseInfo />
                                        </Suspense>
                                    </AccordionBox>

                                    {/* <CommunityMembers /> */}
                                </div>
                                {/* End right column area */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
