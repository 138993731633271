import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { HiChevronRight, HiChevronDown } from "react-icons/hi";

interface AccordionProps {
    title: string;
    classes?: string;
    children: JSX.Element;
    openByDefault?: boolean;
    confirmToClose?: boolean;
}

const AccordionBox = ({
    title,
    classes = "bg-gray-700 bg-opacity-50 shadow p-8 rounded-md text-sm block mb-6",
    children,
    openByDefault = false,
    confirmToClose = false,
}: AccordionProps) => {
    return (
        <Disclosure defaultOpen={openByDefault}>
            {({ open }) => (
                <div className={classes}>
                    <Disclosure.Button className="flex w-full justify-between items-center cursor-pointer">
                        <h4 className="text-white px-0 font-bold text-lg">
                            {title}
                        </h4>
                        <div className="flex items-center justify-center text-white">
                            {open ? (
                                <HiChevronDown className="w-6 h-6" />
                            ) : (
                                <HiChevronRight className="w-6 h-6" />
                            )}
                        </div>
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel static>
                            {({ close }) => (
                                <>
                                    {children}
                                    {confirmToClose && (
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </>
                            )}
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    );
};

export default AccordionBox;
