import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Keypair, Transaction } from "@solana/web3.js";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { phaseWallet } from "../wallet";

const WalletSubscriber = () => {
    const wallet = useAnchorWallet();

    const setPhaseWalletState = useSetRecoilState(phaseWallet);

    useEffect(() => {
        if (wallet?.publicKey) {
            setPhaseWalletState(wallet);
        }
        else {
            setPhaseWalletState({
                publicKey: new Keypair().publicKey,
                signAllTransactions: async (tx : Transaction[]) => tx,
                signTransaction : async (tx : Transaction) => tx
    
            });
        }

    }, [wallet?.publicKey]);

    useEffect(() => {
        setPhaseWalletState({
            publicKey: new Keypair().publicKey,
            signAllTransactions: async (tx : Transaction[]) => tx,
            signTransaction : async (tx : Transaction) => tx

        });
    }, [])
    return <></>;
};

export default WalletSubscriber;
