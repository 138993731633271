import {
    AccountDTO,
    Deliverable,
    Phase,
    PhaseProtocolSDK,
    Proof,
} from "@dedmonkes/phase-protocol-sdk";
import { web3 } from "@project-serum/anchor";
import { profile } from "console";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { prodConn } from "../../constants";
import { deliverablesStore, phaseStore, proofsStore } from "../phases";
import { useCurrentRoadmapKey } from "./roadmaps";

export const useDeliverables = (): [
    AccountDTO<Deliverable>[],
    (deliverables: AccountDTO<Deliverable>[]) => void
] => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const [phases, setPhases] = useRecoilState<AccountDTO<Phase>[]>(
        phaseStore(roadmapAddress as string)
    );
    const [deliverables, setDeliverables] = useRecoilState<
        AccountDTO<Deliverable>[]
    >(deliverablesStore(phaseAddress as string));

    const updateDeliverables = (deliverables: AccountDTO<Deliverable>[]) => {
        if (roadmapAddress === null) {
            return;
        }

        const phaseIndex = phases.findIndex(
            (phase) => phase.address.toBase58() === phaseAddress
        );

        const mergeDeliverables = (prev: AccountDTO<Phase>[]) => {
            if (phaseIndex === -1) {
                return phases;
            }

            const prevPhases = [...prev];
            const phase = { ...prevPhases[phaseIndex] };
            const account = { ...phase.account };

            //Pretty shit lets come back to this
            const nonDups = phase.account.activeDeliverables
                .map((del) => del.toBase58())
                .filter(
                    (val) =>
                        !deliverables
                            .map((x) => x.address.toBase58())
                            .includes(val)
                );

            const mergedDeliverables = [
                ...nonDups.map((x) => new web3.PublicKey(x)),
                ...deliverables.map((del) => del.address),
            ];

            account.totalDeliverables = account.totalDeliverables + 1;
            account.activeDeliverables = mergedDeliverables;
            phase.account = account;
            prevPhases[phaseIndex] = phase;

            return prevPhases;
        };

        setDeliverables((prev: AccountDTO<Deliverable>[]) => {
            const prevDeliverables: AccountDTO<Deliverable>[] =
                prev === null ? [] : prev;

            const index = prevDeliverables.findIndex((val) =>
                deliverables
                    .map((x) => x.address.toBase58())
                    .includes(val.address.toBase58())
            );
            let merged = [...prev, ...deliverables];

            if (index !== -1) {
                merged = [...prev];
                merged[index] = deliverables[0];
            }
            return merged;
        });

        setPhases(mergeDeliverables);
    };

    return [deliverables, updateDeliverables];
};

export const useProofs = (): [
    Record<string, AccountDTO<Proof>[]>,
    (proofs: AccountDTO<Proof>[]) => void,
    (proof : web3.PublicKey, deliverable : web3.PublicKey) => void
] => {
    const [roadmapAddress, phaseAddress] = useCurrentRoadmapKey();
    const [proofs, setProofs] = useRecoilState<
        Record<string, AccountDTO<Proof>[]>
    >(proofsStore(phaseAddress as string));
    const [deliverables, setDeliverables] = useRecoilState<
        AccountDTO<Deliverable>[]
    >(deliverablesStore(phaseAddress as string));

    const updateProofs = (proofs: AccountDTO<Proof>[]) => {
        if (roadmapAddress === null) {
            return;
        }
        if (proofs.length === 0) {
            return;
        }
        

        const deliverableIndex = deliverables.findIndex(
            (del) =>
                del.address.toBase58() ===
                proofs[0].account.deliverable.toBase58()
        );

        const updateDeliverables = (prev: AccountDTO<Deliverable>[]) => {
            if (deliverableIndex === -1) {
                return prev;
            }

            const prevDeliverables = [...prev];
            const deliverable = { ...prevDeliverables[deliverableIndex] };
            const account = { ...deliverable.account };
            const prevProofs =
                deliverable?.account.proofs === null
                    ? []
                    : deliverable?.account.proofs;
            const mergedProofs = [
                ...(prevProofs as web3.PublicKey[]),
                ...proofs.map((proof) => proof.address),
            ]

            account.proofCount = account.proofCount + 1;
            account.proofs = mergedProofs;
            deliverable.account = account;
            prevDeliverables[deliverableIndex] = deliverable;

            return prev;
        };

        setProofs((prev) => {
            const previousProofs: AccountDTO<Proof>[] =
                prev[proofs[0].account.deliverable.toBase58()] || [];

            return {
                ...prev,
                [proofs[0].account.deliverable.toBase58()]: [
                    ...previousProofs,
                    ...proofs,
                ],
            };
        });

        setDeliverables(updateDeliverables);
    };
    

        const removeProof = (proof: web3.PublicKey, deliverable : web3.PublicKey) => {
            if (roadmapAddress === null) {
                return;
            }
            
            const deliverableIndex = deliverables.findIndex(
                (del) =>
                    del.address.toBase58() ===
                    deliverable.toBase58(),
            );
    
            const updateDeliverables = (prev: AccountDTO<Deliverable>[]) => {
                if (deliverableIndex === -1) {
                    return prev;
                }
    
                const prevDeliverables = [...prev];
                const deliverable = { ...prevDeliverables[deliverableIndex] };
                const account = { ...deliverable.account };
                const prevProofs =
                    deliverable?.account.proofs === null
                        ? []
                        : deliverable?.account.proofs;

                const mergedProofs = [...prevProofs.filter(p => p.toBase58() != proof.toBase58())]
                account.proofs = mergedProofs;
                deliverable.account = account;
                prevDeliverables[deliverableIndex] = deliverable;
    
                return prev;
            };
        

        setProofs((prev) => {
            const previousProofs: AccountDTO<Proof>[] =
                prev[deliverable.toBase58()] || [];
            const copy = {...prev}
            copy[deliverable.toBase58()] = [...previousProofs.filter(p => p.address.toBase58() != proof.toBase58())];
            return {...copy};
        });

        setDeliverables(updateDeliverables);
    };



    return [proofs, updateProofs, removeProof];
};
