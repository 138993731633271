const RoadmapHeaderSkeleton = () => {
    return (
        <div className="animate-pulse">
            <div className="flex items-center relative gap-5 mb-6 mt-12">
                <div className="rounded-lg w-12">
                    <div className="w-12 h-12 bg-gray-700 rounded"></div>
                </div>
                <div className="h-8 bg-gray-700 rounded w-60"></div>
            </div>
            <div className="h-4 bg-gray-700 rounded w-96 mb-2"></div>
        </div>
    );
};

export default RoadmapHeaderSkeleton;
