import {
    PhaseState,
    RoadmapState,
    VotingInfo,
    Option,
    AccountDTO,
    Roadmap,
    Phase,
    Deliverable,
    Proof,
} from "@dedmonkes/phase-protocol-sdk";
import { BN, web3 } from "@project-serum/anchor";
import { FC, useEffect, useState } from "react";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import {
    getGovernance,
    getProposal,
    ProposalState,
    getVoterWeightRecord,
    getVoteRecordAddress,
} from "@dedmonkes/spl-governance";
import AccordionBox from "./AccordionBox";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import Countdown from "../Countdown";
import { Metaplex, Nft } from "@metaplex-foundation/js";
import { handleTxNotifications, truncateKey } from "../libs/utils";
import toast from "react-hot-toast";
import { lastValueFrom } from "rxjs";
import { Dialog } from "@headlessui/react";
import { GovernanceType } from "../types";
import { Link } from "react-router-dom";
import { useDeliverables, useProofs } from "../state/hooks/deliverables";
import { phases, proposalVotingState } from "../state/roadmaps";
import { useRecoilState, useRecoilValue } from "recoil";
import { usePhase, usePhases, useRefreshPhases } from "../state/hooks/phases";
import { useRoadmap } from "../state/hooks/roadmaps";
import { useUnvotedNfts } from "../state/hooks/nfts";
import { connection } from "../state/wallet";

export interface VotingInfoPanelProps {
    roadmap?: AccountDTO<Roadmap>;
    phase?: AccountDTO<Phase>;
}

export const ProgressBar = ({
    percentageVetoed,
    threshold,
}: {
    percentageVetoed: number | undefined;
    threshold: number | undefined;
}) => {
    const [percentageComplete, setPercentageComplete] = useState(0);
    useEffect(() => {
        if (percentageVetoed === undefined || threshold === undefined) {
            return;
        }
        if (threshold < percentageVetoed) {
            setPercentageComplete(100);
        } else {
            setPercentageComplete((percentageVetoed / threshold) * 100);
        }
    }, [percentageVetoed, threshold]);
    return (
        <div className="w-full bg-gray-900 rounded-full h-2.5">

            <div
                className="bg-maroon-flush h-2.5 rounded-full"
                style={{ width: percentageComplete + "%" }}
            ></div>
        </div>
    );
};

const VotingButton = ({
    roadmap,
    phase,
    proposalState,
    nfts,
    hasProposalEnded,
    handleExecuteTransaction,
    handleVote,
    handlePayout,
    handleApprove,
    handleResolution,
    handleAbortCompletePhase
}: {
    roadmap?: AccountDTO<Roadmap>;
    phase?: AccountDTO<Phase>;
    proposalState: ProposalState | {};
    nfts?: web3.PublicKey[];
    hasProposalEnded: boolean;
    handleExecuteTransaction: (
        phase: AccountDTO<Phase> | undefined,
        roadmap: AccountDTO<Roadmap> | undefined
    ) => void;
    handleVote: (nfts: web3.PublicKey[]) => void;
    handlePayout: (phase: AccountDTO<Phase>) => void;
    handleApprove: (phase: AccountDTO<Phase>) => void;
    handleResolution: (phase: AccountDTO<Roadmap>) => void;
    handleAbortCompletePhase: (phase: AccountDTO<Phase>) => void;
}) => {
    const { publicKey } = useWallet();

    if (proposalState == ProposalState.Succeeded && roadmap) {
        return (
            <button
                disabled={
                    publicKey?.toBase58() !==
                    roadmap.account.teamAuthority.toBase58()
                }
                onClick={() => handleExecuteTransaction(phase, roadmap)}
                className="w-full bg-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6 
                rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
            >
                Reset Phase State
            </button>
        );
    } else if (
        proposalState == ProposalState.Voting &&
        (phase || roadmap) &&
        !hasProposalEnded
    ) {
        return (
            <button
                onClick={() => (nfts ? handleVote(nfts) : null)}
                disabled={
                    (nfts && nfts.length === 0) ||
                    publicKey?.toBase58() ===
                        roadmap?.account.teamAuthority.toBase58()
                }
                className="w-full bg-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6
                rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
            >
                Reject
            </button>
        );
    } else if (
        (proposalState == ProposalState.Defeated || hasProposalEnded) &&
        phase &&
        roadmap &&
        roadmap.account.teamAuthority.toBase58() === publicKey?.toBase58() &&
        phase.account.state?.stagedForCompletion
    ) {
        return (
            <>
                <button
                    onClick={() => handlePayout(phase)}
                    disabled={
                        (proposalState == ProposalState.Defeated ||
                            hasProposalEnded) &&
                        phase?.account.state.stagedForCompletion &&
                        publicKey?.toBase58() !==
                            roadmap.account.teamAuthority.toBase58()
                    }
                    className="w-full bg-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6
                    rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
                >
                    Payout Phase
                </button>
                <button 
                    onClick={() => handleAbortCompletePhase(phase)}
                      disabled={
                        (proposalState == ProposalState.Defeated ||
                            hasProposalEnded) &&
                        phase?.account.state.stagedForCompletion &&
                        publicKey?.toBase58() !==
                            roadmap.account.teamAuthority.toBase58()
                    }
                    className="w-full outline outline-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6
                        rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"

                >
                    Abort Completion
                </button>
            </>
        );
    } else if (
        (proposalState == ProposalState.Defeated || hasProposalEnded) &&
        phase &&
        roadmap &&
        phase.account.state?.stagedForApproval
    ) {
        return (
            <button
                onClick={() => handleApprove(phase)}
                disabled={
                    (proposalState == ProposalState.Defeated ||
                        hasProposalEnded) &&
                    phase?.account.state.stagedForApproval &&
                    publicKey?.toBase58() !==
                        roadmap.account.teamAuthority.toBase58()
                }
                className="w-full bg-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6
                rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
            >
                Approve Draft
            </button>
        );
    } else if (
        (proposalState == ProposalState.Defeated || hasProposalEnded) &&
        roadmap &&
        roadmap.account.state?.stagedForResolution
    ) {
        return (
            <button
                onClick={() => handleResolution(roadmap)}
                disabled={
                    (proposalState == ProposalState.Defeated ||
                        hasProposalEnded) &&
                    phase?.account.state.stagedForApproval &&
                    publicKey?.toBase58() !==
                        roadmap.account.teamAuthority.toBase58()
                }
                className="w-full bg-maroon-flush mt-5 inline-flex items-center justify-center p-2 px-6
                rounded-md text-white hover:bg-maroon-flush-500 hover:bg-opacity-75 focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-offset-maroon-flush-600 focus:ring-white"
            >
                Approve Roadmap
            </button>
        );
    } else {
        return <></>;
    }
};

const VoteTitle = ({
    phaseState,
    roadmapState,
}: {
    phaseState?: PhaseState;
    roadmapState?: RoadmapState;
}) => {
    if (phaseState?.complete)
        return (
            <div className="w-fit text-center mb-3 p-3 rounded-lg text-xl">
                Payout Vote Results
            </div>
        );
    else if (phaseState?.stagedForApproval) {
        return (
            <div className="w-fit text-center mb-3 p-3 rounded-lg text-xl">
                Approve Draft Phase Vote
            </div>
        );
    } else if (phaseState?.stagedForCompletion) {
        return (
            <div className="w-fit text-center mb-3 p-3 rounded-lg text-xl">
                Payout Vote
            </div>
        );
    } else if (roadmapState?.stagedForResolution) {
        return (
            <div className="w-fit text-center mb-3 p-3 rounded-lg text-xl">
                Roadmap Resolution
            </div>
        );
    } else {
        return <></>;
    }
};

const RoadmapInfo = ({
    votingPeriod,
    votingStartTime,
}: {
    votingPeriod: number;
    votingStartTime: number;
}) => {
    const [infoIsShowing, setinfoIsShowing] = useState<boolean>(false);
    const [isDescriptionShowing, setIsDescriptionShowing] =
        useState<boolean>(false);

    const [roadmap] = useRoadmap();

    const [phases] = usePhases();

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    const sdk = usePhaseSdk();

    useEffect(() => {
        if (roadmap === undefined || !sdk.globalConfig) {
            return;
        }
    }, [roadmap, sdk.globalConfig]);

    return (
        <>
            {roadmap !== undefined ? (
                <div className="relative flex-row w-full mb-3">
                    <div
                        onClick={() => setinfoIsShowing(!infoIsShowing)}
                        className="flex w-full items-center justify-between p-1 px-2 rounded-md outline-1 outline outline-gray-500  mb-2 cursor-pointer"
                    >
                        <div className="text-sm text-gray-200">Phases</div>
                        <div className="w-5 text-right text-gray-200 font-semibold">
                            {" "}
                            {infoIsShowing ? "-" : "+"}{" "}
                        </div>
                    </div>
                    <div className="flex-row gap-1 w-full h-full justify-between mb-2 items-center">
                        {infoIsShowing ? (
                            phases
                                .filter((x) => x.account.state?.lockedForMint)
                                .map((phase) => (
                                    <>
                                        <div className="flex h-full w-full justify-between rounded-md p-2">
                                            <div className="w-18 flex-row text-sm p-2">
                                                <div className="text-gray-400 text-xs">
                                                    Name{" "}
                                                </div>
                                                <Link
                                                    to={`/roadmap/${phase?.account.roadmap.toBase58()}/phase/${phase?.address.toBase58()}`}
                                                    className="font-light"
                                                >
                                                    {phase?.account.config.name}
                                                </Link>
                                            </div>

                                            <div className="w-18 flex-row  text-sm p-2">
                                                <div className="text-gray-400 text-xs">
                                                    SOL Payout
                                                </div>
                                                <Link
                                                    to={`/roadmap/${phase?.account.roadmap.toBase58()}`}
                                                    className="font-light "
                                                >
                                                    {phase?.account.config.phaseSolVaultDepositAmount
                                                        .div(
                                                            new BN(
                                                                web3.LAMPORTS_PER_SOL
                                                            )
                                                        )
                                                        .toNumber()}
                                                </Link>
                                            </div>

                                            <div className="w-18 flex-row text-sm p-2">
                                                <div className="text-gray-400 text-xs">
                                                    USDC Payout
                                                </div>
                                                <Link
                                                    to={`/roadmap/${phase?.account.roadmap.toBase58()}`}
                                                    className="font-light "
                                                >
                                                    {phase?.account.config.phaseUsdcVaultDepositAmount
                                                        .div(new BN(1_000_000))
                                                        .toNumber()}
                                                </Link>
                                            </div>
                                            {/* 
              <div className="w-18 flex-row text-sm p-2">
                <div className="text-gray-400 text-xs">No. Deliverables</div>
                <div className="w-full font-light ">
                  {phase?.account.activeDeliverables.length}
                </div>
              </div>
            </div>
            <div className="flex h-full w-1/2 justify-start flex-wrap rounded-md p-2">
              <div className="w-18 flex-row text-sm p-2 py-1">
                <div className="text-gray-400  text-xs">Vote Type</div>
                <div className="w-full font-light ">
                 Roadmap Resolution
                </div>
              </div>
              <div className="w-18 flex-row text-sm p-2">
                <div className="text-gray-400 text-xs">Voting Peroid</div>
                <div className="font-light ">{`d ${Math.trunc(
                  votingPeriod / day
                )} : h ${Math.trunc((votingPeriod % day) / hour)}`}</div>
              </div>

              <div className="w-18 flex-row text-sm p-2 py-1">
                <div className="text-gray-400 text-xs">Address</div>
                <div className="font-light ">
                  {truncateKey(phase?.address.toBase58() as string)}
                </div>
              </div> */}
                                        </div>
                                    </>
                                ))
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* <div className="mb-8">
        <div className="text-md font-semibold text-white text-center">
          Phase Totals
        </div>
        <div className="flex-wrap flex my-3 justify-center gap-5">
          <div className="flex items-center w-fit h-8 sm:text-sm rounded- p-2 text-white px-4">
            <img className="w-5 h-5 mr-2" src="/usdc.webp" />
            {roadmap.account.lockedTotalSol}
          </div>

          <div className="flex items-center w-fit h-8 sm:text-sm rounded- p-2 text-white px-4">
            <img className="w-5 h-5 mr-2" src="/Sol.webp" />
            {roadmap.account.lockedTotalSol}
          </div>
        </div>
      </div> */}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

const PhaseInfo = ({
    phase,
    isStagedForCompletion,
    votingPeriod,
    votingStartTime,
}: {
    phase: AccountDTO<Phase> | undefined;
    isStagedForCompletion: boolean;
    votingPeriod: number;
    votingStartTime: number;
}) => {
    const [infoIsShowing, setinfoIsShowing] = useState<boolean>(false);
    const [isDescriptionShowing, setIsDescriptionShowing] =
        useState<boolean>(false);
    const [isProofsShowing, setisProofsShowing] = useState(false);
    const [deliverables, setDeliverables] = useDeliverables();
    const [proofs, setProofs] = useProofs();
    const [solVaultBalance, setSolVaultBalance] = useState<number>(0);
    const [usdcVaultBalance, setUsdcVaultBalance] = useState<number>(0);

    const conn = useRecoilValue(connection);

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    const sdk = usePhaseSdk();

    useEffect(() => {

        if (phase === undefined  || conn == undefined) {
            return;
        }

        const getBalance = async () => {

            const solBalanceResponse = await conn.getTokenAccountBalance(
                phase.account.solPhaseVault
            );
            const usdcBalanceResponse = await conn.getTokenAccountBalance(
                phase.account.usdcPhaseVault
            );

            if (solBalanceResponse.value.uiAmount !== null) {
                setSolVaultBalance(solBalanceResponse.value.uiAmount);
            }

            if (usdcBalanceResponse.value.uiAmount !== null) {
                setUsdcVaultBalance(usdcBalanceResponse.value.uiAmount);
            }
        };
        if (phase.account.state.stagedForCompletion) {
            getBalance();
        } else if (
            phase.account.state.stagedForApproval ||
            phase.account.state.complete
        ) {
            setSolVaultBalance(
                phase.account.config.phaseSolVaultDepositAmount
                    .div(new BN(web3.LAMPORTS_PER_SOL))
                    .toNumber()
            );
            setUsdcVaultBalance(
                phase.account.config.phaseUsdcVaultDepositAmount
                    .div(new BN(1_000_000))
                    .toNumber()
            );
        }
    }, [phase, sdk?.globalConfig, conn]);

    return (
        <>
            <div className="relative flex-row w-full mb-3">
                <div
                    onClick={() => setinfoIsShowing(!infoIsShowing)}
                    className="flex w-full items-center justify-between p-1 px-2 rounded-md outline-1 outline outline-gray-500  mb-2 cursor-pointer"
                >
                    <div className="text-sm text-gray-200">
                        Phase Information
                    </div>
                    <div className="w-5 text-right text-gray-200 font-semibold">
                        {" "}
                        {infoIsShowing ? "-" : "+"}{" "}
                    </div>
                </div>
                {infoIsShowing ? (
                    <div className="flex gap-1 w-full h-full justify-between mb-2 items-center">
                        <div className="flex h-full w-1/2 justify-start flex-wrap rounded-md p-2">
                            <div className="w-18 flex-row text-sm p-2">
                                <div className="text-gray-400 text-xs">
                                    Name{" "}
                                </div>
                                <Link
                                    to={`/roadmap/${phase?.account.roadmap.toBase58()}/phase/${phase?.address.toBase58()}`}
                                    className="font-light"
                                >
                                    {phase?.account.config.name}
                                </Link>
                            </div>

                            <div className="w-18 flex-row text-sm p-2">
                                <div className="text-gray-400 text-xs">
                                    Roadmap
                                </div>
                                <Link
                                    to={`/roadmap/${phase?.account.roadmap.toBase58()}`}
                                    className="font-light "
                                >
                                    {truncateKey(
                                        phase?.account.roadmap.toBase58() as string
                                    )}
                                </Link>
                            </div>

                            <div className="w-18 flex-row text-sm p-2">
                                <div className="text-gray-400 text-xs">
                                    No. Deliverables
                                </div>
                                <div className="w-full font-light ">
                                    {phase?.account.activeDeliverables.length}
                                </div>
                            </div>
                        </div>
                        <div className="flex h-full w-1/2 justify-start flex-wrap rounded-md p-2">
                            <div className="w-18 flex-row text-sm p-2 py-1">
                                <div className="text-gray-400  text-xs">
                                    Vote Type
                                </div>
                                <div className="w-full font-light ">
                                    {isStagedForCompletion
                                        ? "Team Payout"
                                        : "Draft Approval"}
                                </div>
                            </div>
                            <div className="w-18 flex-row text-sm p-2">
                                <div className="text-gray-400 text-xs">
                                    Voting Peroid
                                </div>
                                <div className="font-light ">{`d ${Math.trunc(
                                    votingPeriod / day
                                )} : h ${Math.trunc(
                                    (votingPeriod % day) / hour
                                )}`}</div>
                            </div>

                            <div className="w-18 flex-row text-sm p-2 py-1">
                                <div className="text-gray-400 text-xs">
                                    Address
                                </div>
                                <div className="font-light ">
                                    {truncateKey(
                                        phase?.address.toBase58() as string
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div
                    onClick={() =>
                        setIsDescriptionShowing(!isDescriptionShowing)
                    }
                    className="flex w-full items-center justify-between p-1 px-2 rounded-md outline-1 outline outline-gray-500 mb-2 cursor-pointer"
                >
                    <div className="text-sm text-gray-200">Description</div>
                    <div className="w-5 text-right text-gray-200 font-semibold">
                        {" "}
                        {isDescriptionShowing ? "-" : "+"}{" "}
                    </div>
                </div>
                {isDescriptionShowing ? (
                    <div className="flex gap-4 w-full h-fit justify-between p-3 text-sm mb-2">
                        {phase?.account.config.taskDescriptionLink.slice(
                            0,
                            phase?.account.config.taskDescriptionLink.length <
                                256
                                ? phase?.account.config.taskDescriptionLink
                                      .length
                                : 256
                        )}
                        {phase &&
                        phase?.account.config.taskDescriptionLink.length < 512
                            ? "..."
                            : ""}
                    </div>
                ) : (
                    <></>
                )}

                <div
                    onClick={() => setisProofsShowing(!isProofsShowing)}
                    className="flex w-full items-center justify-between p-1 px-2 rounded-md outline-1 outline outline-gray-500  cursor-pointer"
                >
                    <div className="text-sm text-gray-200">Deliverables</div>
                    <div className="w-5 text-right text-gray-200 font-semibold">
                        {" "}
                        {isProofsShowing ? "-" : "+"}{" "}
                    </div>
                </div>

                <div className="flex gap-4 flex-wrap w-full h-fit justify-between p-3 text-sm mb-2">
                    {isProofsShowing ? (
                        deliverables.map(
                            (deliverable: AccountDTO<Deliverable>, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="w-full h-full text-gray-50"
                                    >
                                        <div className="text-gray-400 text-xs">
                                            Deliverable {i + 1}
                                        </div>
                                        <div>
                                            {deliverable.account.description}
                                        </div>
                                        {/* <div className="text-gray-400 text-xs">Proofs</div> */}
                                        <div className="flex gap-3 text-xs">
                                            {Object.values(proofs)
                                                .flat()
                                                .filter(
                                                    (x) =>
                                                        x.account.deliverable.toBase58() ===
                                                        deliverable.address.toBase58()
                                                )
                                                .map((proof, i) => {
                                                    return (
                                                        <a
                                                            className="flex gap-1 justify-center items-center text-maroon-flush-300 font-bold outline outline-1 outline-maroon-flush 
                                                                rounded-lg px-2 py-1 mt-3 hover:text-white hover:bg-maroon-flush-400 hover:outline-maroon-flush-400"
                                                            target="_blank"
                                                            href={
                                                                proof.account.proof.slice(
                                                                    0,
                                                                    7
                                                                ) !==
                                                                    "http://" &&
                                                                proof.account.proof.slice(
                                                                    0,
                                                                    8
                                                                ) !== "https://"
                                                                    ? "https://" +
                                                                      proof
                                                                          .account
                                                                          .proof
                                                                    : proof
                                                                          .account
                                                                          .proof
                                                            }
                                                        >
                                                            <span className="font-black text-sm material-symbols-outlined">
                                                                fingerprint
                                                            </span>
                                                            {`Proof ${i + 1}`}
                                                        </a>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                );
                            }
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div className="mb-8">
                <div className="text-md font-semibold text-white text-center">
                    Payout Amounts
                </div>
                <div className="flex-wrap flex my-3 justify-center gap-5">
                    <div className="flex items-center w-fit h-8 sm:text-sm rounded- p-2 text-white px-4">
                        <img className="w-5 h-5 mr-2" src="/usdc.webp" />
                        {usdcVaultBalance}
                    </div>

                    <div className="flex items-center w-fit h-8 sm:text-sm rounded- p-2 text-white px-4">
                        <img className="w-5 h-5 mr-2" src="/SOL.webp" />
                        {solVaultBalance}
                    </div>
                </div>
            </div>
        </>
    );
};

const VotingInfoPanel = () => {
    const sdk = usePhaseSdk();

    const { connection } = useConnection();
    const metaplex = new Metaplex(connection);
    const { publicKey } = useWallet();
    const [phase, setPhase] = usePhase();
    const [roadmap, setRoadmap] = useRoadmap();
    const refreshPhases = useRefreshPhases();
    const [voteInfo, setVoteInfo] = useState<VotingInfo>();
    const [isStagedForCompletion, setIsStagedForCompletion] = useState(
        phase?.account.state.stagedForCompletion !== undefined
    );
    const [isStagedForApproval, setIsStagedForApproval] = useState(
        phase?.account.state.stagedForApproval !== undefined
    );
    const [isStagedForResolution, setIsStagedForResolution] = useState(
        roadmap?.account.state.stagedForResolution !== undefined
    );
    const [votingPeriod, setVotingPeriod] = useState<number>(0);
    const [votingStartTime, setVotingStartTime] = useState<number>(0);
    const [proposalState, setProposalState] =
        useRecoilState(proposalVotingState);
    const [voteThreshold, setVoteThreshold] = useState<number>();
    const [nfts, refreshunvotedNfts] = useUnvotedNfts();
    // const [phase, setphase] = usePhase();
    const [hasProposalEnded, setHasProposalEnded] = useState<boolean>(false);

    useEffect(() => {
        const getVotingInfo = async () => {

            if (roadmap === undefined ) {
                return;
            }

            let proposalKey;

            if (
                phase?.account.approveProposal !== null &&
                phase?.account.approveProposal !== undefined
            ) {
                proposalKey = phase?.account.approveProposal;
                setIsStagedForApproval(true);
            } else if (
                phase?.account.payoutProposal !== null &&
                phase?.account.payoutProposal !== undefined
            ) {
                proposalKey = phase?.account.payoutProposal;
                setIsStagedForCompletion(true);
            } else if (
                roadmap?.account.resolutionProposal !== null &&
                roadmap?.account.resolutionProposal !== undefined
            ) {
                proposalKey = roadmap?.account.resolutionProposal;
                setIsStagedForResolution(true);
            } else {
                return;
            }

            // if (!publicKey) {
            //     return;
            // }

            // const myNfts: Nft[] = await metaplex
            //   .nfts()
            //   .findAllByOwner(publicKey)
            //   .run();

            // const filtered = myNfts.filter(
            //   (nft) =>
            //     nft.collection?.address.toBase58() ===
            //       roadmap?.account.collection?.toBase58() && nft.collection?.verified
            // );

            const voteinfo = await sdk.getVotingProgress(
                roadmap.account.realm,
                proposalKey
            );

            const governanceAccount = await getGovernance(
                connection,
                phase
                    ? phase.account.doaAccountAuthority
                    : roadmap.account.daoAuthority
            );
            const proposalAccount = await getProposal(connection, proposalKey);

            setVoteInfo(voteinfo);
            setVotingPeriod(governanceAccount.account.config.maxVotingTime);
            setVoteThreshold(
                governanceAccount.account.config.communityVoteThreshold.value
            );
            setVotingStartTime(
                proposalAccount.account.votingAt?.toNumber()
                    ? proposalAccount.account.votingAt?.toNumber()
                    : 0
            );
            setProposalState(proposalAccount.account.state);
            // setNfts(filtered);
            if (phase) {
                setPhase(phase);
            }
        };

        getVotingInfo();
    }, [
        roadmap,
        phase?.account,
        publicKey,
        hasProposalEnded,
        phase?.account.state,
    ]);

    const handleExecuteTransaction = async (
        phase: AccountDTO<Phase> | undefined,
        roadmap: AccountDTO<Roadmap> | undefined
    ) => {
        const toasts = new Map<string, string>();
        if (roadmap === undefined) {
            return;
        }
        const exec$ = await sdk.executeProposalTransaction(roadmap, phase);

        exec$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    if (notification.account.collection) {
                        setRoadmap(notification);
                    } else {
                        setPhase(notification);
                    }
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    `Vote Casted`,
                    "Vote"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
            complete: () => refreshPhases(),
        });
    };

    const handlePayout = async (phase: AccountDTO<Phase>) => {
        const toasts = new Map<string, string>();

        const exec$ = await sdk.completePhase(roadmap, phase);

        exec$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }

                await handleTxNotifications(
                    notification,
                    toasts,
                    `Payout Processed`,
                    "Payout"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const handleAbortCompletedPhase = async (phase: AccountDTO<Phase>) => {
        const toasts = new Map<string, string>();

        const exec$ = await sdk.abortCompletePhase(phase, roadmap);

        exec$.subscribe({
            next: async (notification) => {
                console.log(notification)
                if (notification.type === undefined) {
                    setPhase(notification);
                }

                await handleTxNotifications(
                    notification,
                    toasts,
                    `Aborted completion of Phase.`,
                    "Abort"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const handleApprove = async (phase: AccountDTO<Phase>) => {
        const toasts = new Map<string, string>();

        const approve$ = await sdk.approvePhase(roadmap, phase);

        approve$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setPhase(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    `Payout Processed`,
                    "Payout"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const handleVote = async (nfts: web3.PublicKey[]) => {
        if (roadmap === undefined) {
            return;
        }
        const toasts = new Map<string, string>();

        const castVoteStatus$ = await sdk.castVote(roadmap, nfts, phase);

        castVoteStatus$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setVoteInfo(notification);
                    setProposalState(notification.proposalState);
                    refreshunvotedNfts();
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    `Vote Casted`,
                    "Vote"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const handleResolution = async (roadmap: AccountDTO<Roadmap>) => {
        const toasts = new Map<string, string>();

        const approve$ = await sdk.approveRoadmapResolution(roadmap);

        approve$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setRoadmap(notification);
                    refreshPhases();
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    `Revised roadmap approved`,
                    "Error approving revised roadmap"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    return (
        <>
            {(phase &&
                (phase?.account?.state?.stagedForCompletion ||
                    phase?.account?.state?.stagedForApproval ||
                    phase?.account?.state?.complete)) ||
            (roadmap && roadmap?.account.state?.stagedForResolution) ? (
                <div className="bg-gray-700 bg-opacity-50 shadow p-8 rounded-md text-sm block mb-6 text-white">
                    {/* <div className="w-full p-2 pb-3 rounded-full border-gray-200 text-gray-200 text-xl font-bold text-center bg-maroon-flush mb-4">Voting in Progress</div> */}
                    <div className="flex justify-center">
                        <VoteTitle
                            phaseState={phase?.account.state}
                            roadmapState={roadmap?.account.state}
                        />
                    </div>
                    {isStagedForResolution ? (
                        <RoadmapInfo
                            votingPeriod={votingPeriod}
                            votingStartTime={votingStartTime}
                        />
                    ) : (
                        <PhaseInfo
                            phase={phase}
                            isStagedForCompletion={isStagedForCompletion}
                            votingPeriod={votingPeriod}
                            votingStartTime={votingStartTime}
                        />
                    )}

                    <div className="flex-row w-full h-12">
                        <label className="font-bold">Number of Vetos</label>
                        <ProgressBar
                            percentageVetoed={voteInfo?.percentageVetoed}
                            threshold={voteThreshold}
                        />
                    </div>
                    {votingStartTime &&
                    proposalState == ProposalState.Voting ? (
                        <>
                            <div>Your Available Votes: {nfts?.length}</div>

                            <Countdown
                                endTimestamp={votingStartTime + votingPeriod}
                                onCompletion={() => setHasProposalEnded(true)}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    <VotingButton
                        phase={phase}
                        roadmap={roadmap}
                        proposalState={proposalState}
                        nfts={nfts}
                        hasProposalEnded={hasProposalEnded}
                        handleExecuteTransaction={handleExecuteTransaction}
                        handleVote={handleVote}
                        handlePayout={handlePayout}
                        handleApprove={handleApprove}
                        handleResolution={handleResolution}
                        handleAbortCompletePhase={handleAbortCompletedPhase}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default VotingInfoPanel;
