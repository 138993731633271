import { InformationCircleIcon } from "@heroicons/react/outline";
import AccordionBox from "./AccordionBox";
import ReactDOMServer from "react-dom/server";

export default function NextStepsBox({ ...props }) {
    const defaultClass = `shadow-lg border-2 border-maroon-flush bg-maroon-flush-700 bg-opacity-50 text-maroon-flush-50 p-6 rounded-lg`;
    const classes = props.classes ? props.classes : defaultClass;
    const collapsed = props.collapsed ? props.collapsed : false;
    // don't output the box if children is empty
    if (!Boolean(ReactDOMServer.renderToStaticMarkup(props.children)))
        return <></>;
    return (
        <div className={`relative my-6 ${classes}`}>
            {props.collapsed === undefined ? (
                <>
                    <div className="mb-3 flex justify-between items-center">
                        <h5 className="font-bold uppercase text-white leading-none">
                            {props.title ? props.title : "Next Steps"}
                        </h5>
                        <InformationCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                        />
                    </div>
                    <div>{props.children}</div>
                </>
            ) : (
                <>
                    <AccordionBox
                        title={props.title ? props.title : "Next Steps"}
                        openByDefault={collapsed}
                        classes=""
                        confirmToClose={props.confirmToClose}
                    >
                        <div className="pt-4">{props.children}</div>
                    </AccordionBox>
                </>
            )}
        </div>
    );
}
