export default function Progress({ status }: any) {
    const statusString = status ? Object.keys(status)[0] : "";
    const statuses = [
        { key: "draft", title: "Draft", classes: "bg-maroon-flush text-white" },
        {
            key: "lockedForMint",
            title: "Finalized",
            classes: "bg-maroon-flush text-white",
        },
        {
            key: "approved",
            title: "Approved",
            classes: "bg-maroon-flush text-white",
        },
        {
            key: "actioned",
            title: "Actioned",
            classes: "bg-maroon-flush text-white",
        },
        {
            key: "stagedForCompletion",
            title: "Voting For Completion",
            classes: "bg-maroon-flush text-gray-100",
        },
        {
            key: "complete",
            title: "Complete",
            classes: "bg-maroon-flush text-gray-100",
        },
    ];
    const idx = statuses
        .map(function (e) {
            return e.key;
        })
        .indexOf(statusString);

    return (
        <div className="relative mt-8">
            <div className="w-full absolute border-mar bg-gray-800 rounded-full h-1 -translate-y-1/2"></div>
            <div className="flex justify-between items-center -translate-y-1/2 overflow-hidden">
                {statuses.map((s, i) => {
                    const classes =
                        idx >= i ? "" : "bg-gray-800  text-gray-200";
                    const activeClass = idx >= i ? s.classes : "";
                    return (
                        <span
                            key={`progress-${i}`}
                            className={`relative inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${classes} ${activeClass}`}
                        >
                            {i === idx && (
                                <span
                                    className={`bg-maroon-flush h-1 rounded-full absolute right-0 -z-10 w-screen`}
                                ></span>
                            )}
                            {s.title}
                        </span>
                    );
                })}
            </div>
        </div>
    );
}
