/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon } from "@heroicons/react/outline";
import { Suspense, useMemo } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import RoadmapLockupTotal from "../components/RoadmapLockupTotal";
// import RoadmapVotes from "../components/RoadmapVotes";
import RoadmapHeader from "../components/RoadmapHeader";
import ConfirmationModal from "../components/ConfirmationModal";
import AccordionBox from "../components/AccordionBox";
import PhaseCreateEditForm from "./PhaseCreateEditForm";
import { ViewContext } from "../ViewContext";
import {
    AccountDTO,
    getPhases,
    MintingAccountPlugin,
    Phase,
    PhaseProtocolSDK,
    PhaseState,
    Roadmap,
    Roadmap as RoadmapType,
    RoadmapMetadata,
    RoadmapState,
} from "@dedmonkes/phase-protocol-sdk";
import { connection } from "../constants";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { getRealm, Realm } from "@dedmonkes/spl-governance";
import { useRecoilState } from "recoil";
import {
    isCreatingPhase as phaseCreate,
    phases,
    phases as phaseState,
    roadmapState,
} from "../state/roadmaps";
import PhaseCard from "../components/PhaseCard";
import PhaseSkeleton from "../components/skeleton/PhaseSkeleton";
import RoadmapRedraftNotice from "../components/RoadmapRedraftNotice";
import RoadmapLockedNotice from "../components/RoadmapLockedNotice";
import RoadmapInfo from "../components/RoadmapInfo";
import {
    useCurrentRoadmapKey,
    useRefreshSolPoolAmount,
    useRefreshUsdcPoolAmount,
    useRoadmap,
    useRoadmapsMetadata,
    useSolPoolAmount,
} from "../state/hooks/roadmaps";
import { usePhase, usePhases, useRefreshPhases } from "../state/hooks/phases";
import usePhaseSdk from "../hooks/usePhaseProtocol";
import { lastValueFrom } from "rxjs";
import toast from "react-hot-toast";
import RoadmapRefundNotice from "../components/RoadmapRefundNotice";
import { handleTxNotifications } from "../libs/utils";
import PhaseList from "../components/PhaseList";
import RoadmapHeaderSkeleton from "../components/skeleton/RoadmapHeaderSkeleton";
import LoadingSpinner from "../components/LoadingSpinner";
import RefundClaimModal from "../components/RefundClaimModal";
import VotingInfoPanel from "../components/VotingInfoPanel";
import RoadmapRefundSkeleton from "../components/skeleton/RoadmapRefundSkeleton";
import { BN } from "@project-serum/anchor";
import NextStepsBox from "../components/NextStepsBox";
import SidebarSkeleton from "../components/skeleton/SidebarSkeleton";
import { rm } from "fs/promises";

const PhasesEmpty = () => {
    const [isCreatingPhase, setIsCreatingPhase] =
        useRecoilState<boolean>(phaseCreate);
    return (
        <>
            {!isCreatingPhase && (
                <div className="p-16 border-2 border-dashed border-gray-700 rounded-md bg-[#111111]">
                    <div className="text-center">
                        <p className="text-sm text-white">
                            There are no phases yet.
                        </p>
                        <p>
                            <button
                                onClick={(e) => setIsCreatingPhase(true)}
                                className="btn"
                            >
                                Add Phase
                            </button>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

const ActionPanel = () => {
    const sdk = usePhaseSdk();
    const [roadmap, setRoadmap] = useRoadmap();
    const [phases, setPhases] = usePhases();
    const { publicKey } = useWallet();
    const [hasMintingPlugin, setHasMintingPlugin] = useState<boolean>(false);
    const [totalSolFromMint, setTotalSolFromMint] = useState<number>(0);
    const [showLockWarningModal, setShowLockWarningModal] =
        useState<boolean>(false);
    const refreshPhases = useRefreshPhases();
    const refreshSolPool = useRefreshSolPoolAmount();
    const refreshUsdcPool = useRefreshUsdcPoolAmount();
    const solRoadmapPoolAmount = useSolPoolAmount();
    const [isRefundConfirmationModalOpen, setIsRefundConfirmationModalOpen] =
        useState<boolean>(false);

    const totalPhaseDeposits = useMemo(
        () =>
            phases
                .filter((p: AccountDTO<Phase>) => {
                    return !!(p.account.state as any)?.lockedForMint;
                })
                .reduce(
                    (prev: number, curr: AccountDTO<Phase>) =>
                        prev +
                        curr.account.config.phaseSolVaultDepositAmount.toNumber(),
                    0
                ),
        [phases, roadmap, publicKey]
    );

    useEffect(() => {
        const getPlugin = async () => {
            if (
                roadmap &&
                roadmap?.account.config.mintingAccountPluginProgramAddress !==
                null
            ) {
                try {
                    const plugin: AccountDTO<MintingAccountPlugin> =
                        await sdk.getMintAccountPlugin(
                            roadmap.address,
                            roadmap?.account.config
                                .mintingAccountPluginProgramAddress
                        );
                    setHasMintingPlugin(true);
                    setTotalSolFromMint(
                        plugin.account.price
                            .mul(plugin.account.maxSupply)
                            .toNumber()
                    );
                } catch (e: any) { }
            }
        };

        getPlugin();
    }, [roadmap, phases]);

    const openRefundClaims = async (
        roadmap: AccountDTO<Roadmap> | undefined
    ) => {
        if (roadmap === undefined) {
            return;
        }
        const openRefundClaims$ = await sdk.openRefundClaims(roadmap);

        const votingPhases = phases.filter((p: AccountDTO<Phase>) => {
            return (
                !!(p.account.state as PhaseState)?.stagedForApproval ||
                !!(p.account.state as PhaseState)?.stagedForCompletion
            );
        });

        if (votingPhases.length > 0) {
            toast.error(
                "You cannot refund a roadmap with active voting taking place. Please wait for your voting peroid to expire."
            );
            return;
        }

        const toasts = new Map<string, string>();

        openRefundClaims$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setRoadmap(notification);
                    refreshPhases();
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Refund claim is open!",
                    "Opening refund claims failed!"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
            complete: () => {
                refreshSolPool();
                refreshUsdcPool();
            },
        });
    };

    const lockRoadmap = async (roadmap: AccountDTO<Roadmap>) => {
        const toasts = new Map<string, string>();
        const totalLockedSol =  roadmap.account.lockedTotalSol.toNumber();

        if (
            (totalLockedSol + (totalSolFromMint * .15)) >
            totalSolFromMint
        ) {
            toast.error(
                "You cannot fund all your locked phases with current mint revenue. Please adjust your draft phase deposit amounts or cancel some phases."
            );
            return;
        }
        const lockPhase$ = await sdk.lockRoadmapForMint(
            roadmap,
            []
        );
        // @ts-ignore
        lockPhase$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setRoadmap(notification);
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Locking roadmap",
                    "Locking roadmap failed!"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
            complete: (data: any) => refreshPhases(),
        });
    };

    const stageRoadmapForResolutionVoting = async (
        roadmap: AccountDTO<Roadmap>
    ) => {
        const toasts = new Map<string, string>();

        const stageRoadmapForResolutionVoting$ =
            await sdk.stageRoadmapForResolutionVoting(roadmap);
        // @ts-ignore
        stageRoadmapForResolutionVoting$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setRoadmap(notification);
                    refreshPhases();
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Staging revised roadmap for voting",
                    "Staging revised roadmap for voting failed!"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const approveRoadmapResolution = async (roadmap: AccountDTO<Roadmap>) => {
        const toasts = new Map<string, string>();

        const approveRoadmapResolution$ = await sdk.approveRoadmapResolution(
            roadmap
        );
        // @ts-ignore
        approveRoadmapResolution$.subscribe({
            next: async (notification) => {
                if (notification.type === undefined) {
                    setRoadmap(notification);
                    refreshPhases();
                }
                await handleTxNotifications(
                    notification,
                    toasts,
                    "Refund claim is open!",
                    "Opening refund claims failed!"
                );
            },
            error: (error) => {
                console.error(error);
                toast.error(error.toString());
            },
        });
    };

    const rmStateObj = useMemo(
        () => roadmap?.account?.state,
        [roadmap?.account.state]
    );
    const isFounder = useMemo(
        () =>
            publicKey?.toBase58() === roadmap?.account.teamAuthority.toString(),
        [publicKey, roadmap]
    );

    const canLock = useMemo(
        () =>
            !!rmStateObj?.draft &&
            hasMintingPlugin &&
            isFounder &&
            phases.length >= 5,
        [isFounder, rmStateObj, hasMintingPlugin]
    );

    const canResolve =
        (!!rmStateObj?.lockedForMint || !!rmStateObj?.stagedForResolution) &&
        isFounder;
    const canStageForResolution = !!rmStateObj?.resolution && isFounder;

    // needs voting time expiry passed
    const canApproveResolution = useMemo(
        () => !!rmStateObj?.stagedForResolution && isFounder,
        [rmStateObj, isFounder]
    );

    const canOpenRefundClaims = useMemo(
        () => !!rmStateObj?.actioned && isFounder,
        [rmStateObj, isFounder]
    );

    const canClaimRefund = useMemo(() => !!rmStateObj?.refunding, [rmStateObj]);

    return (
        <>
            {roadmap ? (
                <>
                    {rmStateObj?.draft && isFounder && (
                        <NextStepsBox
                            collapsed={true}
                            confirmToClose={true}
                            title="Important Pre-Launch Information"
                        >
                            <>
                                <p>
                                    Please take down this information. You will
                                    need these keys for your launchpad.
                                </p>
                                <p className="text-white mt-4">
                                    <strong>Roadmap Address:</strong>
                                    <br />
                                    {/* ts-ignore */}
                                    {roadmap.address.toString()}
                                </p>
                                <p className="text-white mt-4">
                                    <strong>Collection Mint Address:</strong>
                                    <br />
                                    {roadmap.account.collection?.toString()}
                                </p>
                                <p className="mt-4">
                                    Please Note: You will need to add a minimum
                                    of 5 phases to your roadmap before you can
                                    lock it for mint.
                                </p>
                            </>
                        </NextStepsBox>
                    )}

                    {canLock && (
                        <>
                            <NextStepsBox>
                                <p>
                                    If you are happy with all the phases you
                                    have drafted you need to lock them down so
                                    that you can enter the mint period.
                                </p>
                                <br />
                                <p>
                                    Please cancel any draft phases you do not
                                    wish to be included in your roadmap before
                                    proceeding.
                                </p>
                                <p>
                                    <div className="py-3 px-4 border-l-4 border-white w-fit mt-4 text-white text-sm">
                                        <p>
                                            Total Phase Deposits:{" "}
                                            <span className="sol inline-block align-middle mr-[2px]">
                                                ◎
                                            </span>
                                            {totalPhaseDeposits /
                                                LAMPORTS_PER_SOL}
                                        </p>
                                        <p>
                                            Total Estimated Mint Proceeds:{" "}
                                            <span className="sol inline-block align-middle mr-[2px]">
                                                ◎
                                            </span>
                                            {totalSolFromMint /
                                                LAMPORTS_PER_SOL}
                                        </p>
                                    </div>
                                    {roadmap.account.lockedPhaseCount < 5 && (
                                        <p className="text-sm opacity-70 text-white mt-4">
                                            You must add and lock at least 5
                                            phases to finalize roadmap. (
                                            {roadmap.account.lockedPhaseCount}{" "}
                                            of {roadmap.account.phaseCount}{" "}
                                            locked)
                                        </p>
                                    )}
                                    <button
                                        onClick={(e) =>
                                            setShowLockWarningModal(true)
                                        }
                                        className="btn btn-fill"
                                        disabled={
                                            roadmap.account.lockedPhaseCount < 5
                                        }
                                    >
                                        Finalize & Lock Roadmap
                                    </button>
                                </p>
                            </NextStepsBox>
                            <ConfirmationModal
                                show={showLockWarningModal}
                                title="Lock Roadmap"
                                message="Once you lock in your roadmap, you will be unable to edit it until after the mint, are you sure you'd like to proceed?"
                                onClose={() => setShowLockWarningModal(false)}
                                onConfirmation={() => lockRoadmap(roadmap)}
                            />
                        </>
                    )}

                    {canStageForResolution && (
                        <NextStepsBox>
                            <p>
                                Unfortunately the mint did not go as intended to
                                cover the cost of all phases you entered. Please
                                remove/edit locked phases so that the total
                                capital needed for your roadmap is less than
                                your mint proceeds. Then stage your revised
                                roadmap for approval by your holders.
                            </p>
                            <div className="py-3 px-4 border-l-4 border-white w-fit mt-4 text-white text-sm">
                                <p>
                                    Total Phase Deposits:{" "}
                                    <span className="sol">◎</span>
                                    {roadmap.account.lockedTotalSol.toNumber() /
                                        LAMPORTS_PER_SOL}
                                </p>
                                <p>
                                    Funding From Mint:{" "}
                                    <span className="sol">◎</span>
                                    {solRoadmapPoolAmount}
                                </p>
                            </div>
                            <p>
                                <button
                                    onClick={(e) =>
                                        roadmap.account.lockedTotalSol.toNumber() /
                                            LAMPORTS_PER_SOL <
                                            solRoadmapPoolAmount
                                            ? stageRoadmapForResolutionVoting(
                                                roadmap
                                            )
                                            : toast.error(
                                                "You do not have enough mint proceeds to fund your roadmap. Please edit or remove some locked phases to reduce the capital needed."
                                            )
                                    }
                                    className="btn btn-fill"
                                >
                                    Submit New Roadmap to Vote
                                </button>
                            </p>
                        </NextStepsBox>
                    )}

                    {rmStateObj?.stagedForResolution && (
                        <NextStepsBox>
                            <p>
                                The revised roadmap is currently being voted on.
                            </p>
                            <br></br>
                            <p>
                                If veto threshold is not met within the proposal
                                period you will be able to approve your roadmap
                                in. If threshold is met you will have to revert
                                your roadmap back to resolution and revise the
                                phases.
                            </p>
                        </NextStepsBox>
                    )}

                    {/* {canApproveResolution && (
          <p>
            <button
              onClick={(e) => approveRoadmapResolution(roadmap)}
              className="btn"
            >
              Approve
            </button>
          </p>
        )} */}

                    {canOpenRefundClaims && (
                        <>
                            <NextStepsBox
                                title="What Now?"
                                collapsed={false}
                                classes="shadow-lg border-2 border-gray-800 bg-gray-700 bg-opacity-50 text-gray-50 p-6 rounded-lg"
                            >
                                <p>
                                    Continue working on your roadmap until you
                                    exhaust the phases.
                                </p>
                                <p>
                                    In the event that you no longer wish to
                                    continue the project - you may initiate a
                                    refund and distribute the remaining funds
                                    across all holders. This action formally
                                    concludes the project. If you wish to
                                    proceed{" "}
                                    <a
                                        onClick={(e) =>
                                            setIsRefundConfirmationModalOpen(
                                                true
                                            )
                                        }
                                        className="underline cursor-pointer"
                                    >
                                        click here.{" "}
                                    </a>
                                </p>
                            </NextStepsBox>

                            <ConfirmationModal
                                title="Open Refund Claims"
                                message="This will open up a refund claim for every NFT in the collection. This is not reversible!!"
                                onClose={() =>
                                    setIsRefundConfirmationModalOpen(false)
                                }
                                show={isRefundConfirmationModalOpen}
                                onConfirmation={() => openRefundClaims(roadmap)}
                                stringMatchConfirm="open refunds"
                            />
                        </>
                    )}
                    {/* {canClaimRefund && (
            <NextStepsBox>
              <p>Next steps message here.</p>
              <p>
                <button
                  onClick={(e) => openRefundClaims(roadmap)}
                  className="btn"
                >
                  Claim Refund
                </button>
              </p>
            </NextStepsBox>
          )} */}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const AddPhaseButton = () => {
    const [roadmap, setRoadmap] = useRoadmap();
    const { publicKey } = useWallet();
    const [isCreatingPhase, setIsCreatingPhase] =
        useRecoilState<boolean>(phaseCreate);

    const rmStateObj = useMemo(
        () => roadmap?.account?.state,
        [roadmap?.account.state]
    );
    const isFounder = useMemo(
        () =>
            publicKey?.toBase58() === roadmap?.account.teamAuthority.toString(),
        [publicKey, roadmap]
    );

    const canDraft = useMemo(
        () =>
            (rmStateObj?.draft !== undefined ||
                rmStateObj?.actioned !== undefined) &&
            isFounder,
        [isFounder, rmStateObj]
    );

    return (
        <>
            {publicKey?.toBase58() ===
                roadmap?.account.teamAuthority.toString() &&
                canDraft && (
                    <button
                        onClick={(e) => setIsCreatingPhase(true)}
                        className="btn !mt-0 text-sm"
                    >
                        Add Phase
                    </button>
                )}
            <Transition appear show={isCreatingPhase} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsCreatingPhase(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold leading-6 text-white"
                                    >
                                        Add Phase
                                    </Dialog.Title>
                                    <button
                                        className="absolute right-0 top-0 p-4"
                                        onClick={() =>
                                            setIsCreatingPhase(false)
                                        }
                                    >
                                        <XCircleIcon className="text-white w-8" />
                                    </button>
                                    <PhaseCreateEditForm />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};



const TreasuryHealth = (
    // {hasMintingPlugin, expectedFromMint} : {hasMintingPlugin : boolean, expectedFromMint : number} 
    ) => {
    const [phases] = usePhases();
    const [roadmap, setRoadmap] = useRoadmap();
    const sdk = usePhaseSdk();
    const {publicKey} = useWallet()
    const [hasMintingPlugin, setHasMintingPlugin] = useState<boolean>(false);
    const [totalSolFromMint, setTotalSolFromMint] = useState<number>(0);

    useEffect(() => {
        const getPlugin = async () => {
            if (
                roadmap &&
                roadmap?.account.config.mintingAccountPluginProgramAddress !==
                null
            ) {
                try {
                    const plugin: AccountDTO<MintingAccountPlugin> =
                        await sdk.getMintAccountPlugin(
                            roadmap.address,
                            roadmap?.account.config
                                .mintingAccountPluginProgramAddress
                        );
                    setHasMintingPlugin(true);
                    setTotalSolFromMint(
                        plugin.account.price
                            .mul(plugin.account.maxSupply)
                            .toNumber()
                    );
                } catch (e: any) {}
            }
        };

        getPlugin();
    }, [roadmap, phases]);



    const totalLockedForMint = useMemo(() => phases.filter(phase => phase.account.state?.lockedForMint !== undefined)
    .reduce((acc, val) => acc + (val.account.config.phaseSolVaultDepositAmount.toNumber() * 0.98), 0), [phases])

    const totalDepositRequired = useMemo(() => phases.reduce((acc, val) => acc + val.account.config.phaseSolVaultDepositAmount.toNumber(), 0), [phases]);

    const healthOK = totalSolFromMint * .30;
    const healthWarning = totalSolFromMint * .50;
    const healthBad = totalSolFromMint * 0.70;

    const teamPayout = (totalSolFromMint * .15)* .98;
    const protocolFee = (totalLockedForMint * .02) + (totalSolFromMint * 0.15 * 0.02) ;

    const healthFactor = useMemo(() => { return (totalLockedForMint + teamPayout + protocolFee) / totalSolFromMint}, [totalLockedForMint, totalSolFromMint])


    const getHealthFriendlyName = (n: number) => {
        if (n <= healthOK) {
            return "Low"
        }
        if (n > healthOK && n <= healthWarning) {
            return "Risky"
        }
        if (n <= healthBad) {
            return "Extreme"
        }
    }

    if(!hasMintingPlugin){
        return (<></>)
    }

    if(publicKey?.toBase58() !== roadmap?.account.teamAuthority.toBase58() ){
        return (<></>)
    }

    if(roadmap?.account.state?.draft === undefined && roadmap?.account.state?.lockedForMint === undefined){
        return (<></>)

    }


    return (<div className="relative my-6 shadow-lg border-2 border-gray-800 bg-gray-700 bg-opacity-50 text-gray-50 p-6 rounded-lg">

        <h1 className="inline-block text-xl font-bold">Treasury Health</h1>
        <p className="inline-block float-right">Risk Level: <strong>{getHealthFriendlyName(healthFactor)}</strong> ({(healthFactor * 100).toFixed(2)}%)</p>
        <meter id="treasury-health" value={ totalLockedForMint + teamPayout + protocolFee} optimum={healthOK} low={healthWarning} high={healthBad} min={0} max={totalSolFromMint} ></meter>

        <p>Each phase locked will add to the pre-allocated amount of the expected mint funds. We recommend you only pre-allocate around 35% of what you expect to raise. If you don't raise at least the amount you have allocated, it will require a DAO vote to modify the roadmap.</p>

        <h1 className="inline-block text-xl mt-5 mb-3  font-bold">Funds Breakdown</h1>
        <table className="table-fixed gap-3" style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td>
                        Total Expected Mint
                    </td>
                    <td className="text-right font-mono">
                        ◎{(totalSolFromMint / LAMPORTS_PER_SOL).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Allocated to Phases
                    </td>
                    <td className="text-right font-mono">
                        -◎{(totalLockedForMint / LAMPORTS_PER_SOL).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Initial Team Payout
                    </td>
                    <td className="text-right font-mono">
                        -◎{((teamPayout / LAMPORTS_PER_SOL)).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Protocol Fee
                    </td>
                    <td className="text-right font-mono">
                        -◎{((protocolFee / LAMPORTS_PER_SOL)).toFixed(2)}
                    </td>
                </tr>
            </tbody>
        </table>
        <hr className="mt-3 mb-3" />
        <table className="table-fixed gap-3" style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td>
                        Future Available Funding
                    </td>
                    <td className="text-right font-mono">
                        ◎{(((totalSolFromMint - totalLockedForMint - teamPayout - protocolFee) / LAMPORTS_PER_SOL).toFixed(2))}
                    </td>
                </tr>
            </tbody>
        </table>

    </div>)
}

export default function RoadmapView() {
    return (
        <>
            <main className="">
                <RefundClaimModal />

                <div className="max-w-7xl mx-auto py-8 px-6 lg:px-8">

                    {/* <Breadcrumbs pages={pages}/> */}
                    <div className="block lg:flex gap-16 pb-12">
                        <div className="flex-1">
                            <Suspense>
                                <TreasuryHealth />
                            </Suspense>

                            <Suspense fallback={<RoadmapHeaderSkeleton />}>
                                <RoadmapHeader />
                            </Suspense>
                            {/*
              // @ts-ignore */}
                            <Suspense fallback={<RoadmapRefundSkeleton />}>
                                <RoadmapLockedNotice />
                            </Suspense>

                            {/* {canClaimRefund && roadmap && ( */}
                            <Suspense fallback={<RoadmapRefundSkeleton />}>
                                <RoadmapRefundNotice />
                            </Suspense>
                            {/* )} */}

                            <Suspense fallback={<LoadingSpinner />}>
                                <ActionPanel />
                            </Suspense>

                            <div className="relative mt-12">
                                <div className="flex justify-between items-center mb-4 ">
                                    <h3 className="text-2xl font-white text-white font-black">
                                        Phases
                                    </h3>
                                    <Suspense fallback={<LoadingSpinner />}>
                                        <AddPhaseButton />
                                    </Suspense>
                                </div>

                                <div className="pb-6">
                                    {/* Start right column area */}
                                    <div className="h-full relative text-white">
                                        <Suspense fallback={<PhaseSkeleton />}>
                                            <PhaseList />
                                        </Suspense>
                                    </div>
                                </div>

                                {/* {canResolve && !isFounder && <RoadmapRedraftNotice />} */}
                            </div>
                        </div>
                        <div className="flex-0 lg:max-w-[360px] w-full">
                            <h3 className="text-2xl font-white text-white mb-4 font-black font-syne">
                                Roadmap Information
                            </h3>
                            <div className="">
                                <Suspense
                                    fallback={
                                        <SidebarSkeleton classes="w-full h-24 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                    }
                                >
                                    <VotingInfoPanel />
                                </Suspense>

                                <Suspense
                                    fallback={
                                        <SidebarSkeleton classes="w-full h-64 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                    }
                                >
                                    <RoadmapLockupTotal />
                                </Suspense>

                                <AccordionBox title="Roadmap Info">
                                    <Suspense
                                        fallback={
                                            <SidebarSkeleton classes="w-full h-24 bg-gray-700 bg-opacity-50 rounded mb-8" />
                                        }
                                    >
                                        <RoadmapInfo />
                                    </Suspense>
                                </AccordionBox>
                            </div>
                        </div>
                        {/* End right column area */}
                    </div>
                </div>
            </main>
        </>
    );
}
