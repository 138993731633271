import {
    atom,
    atomFamily,
    DefaultValue,
    selector,
    selectorFamily,
    waitForAll,
} from "recoil";
import {
    AccountDTO,
    Deliverable,
    Phase,
    PhaseProtocolSDK,
    Roadmap,
    RoadmapMetadata,
} from "@dedmonkes/phase-protocol-sdk";
import { Metadata } from "@metaplex-foundation/js";
import { phaseContext } from "./phaseSdk";
import { collectionsQuery } from "./nft";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { connection, phaseWallet } from "./wallet";
import { web3 } from "@project-serum/anchor";
import { MetadataStorage, nameHash } from "@dedmonkes/shadow-helper";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { phaseStore } from "./phases";
import { getFeaturedRoadmaps } from "../utils/getFeaturedRoadmaps";

const collectionsRoadmapQueryRequest = atomFamily({
    key: "userRoadmapQueryRequest",
    default: 0,
});

export const featuredRoadmaps = atom({
    key: "featuredRoadmaps",
    default: [] as string[],
});

export const roadmapsQuery = selector<Record<string, AccountDTO<Roadmap>>>({
    key: "roadmapsQuery",
    get: async ({ get }: any) => {
        const sdk: PhaseProtocolSDK = get(phaseContext);
        const wallet: AnchorWallet | undefined = get(phaseWallet);
        const currRm = get(currentRoadmap);
        const roadmaps: Record<string, AccountDTO<Roadmap>> = {};
        if (wallet?.publicKey) {
            const collections = get(collectionsQuery);
            const roadmapsByCollection: AccountDTO<Roadmap>[] =
                await sdk.getRoadmapsByCollectionMints(collections);
            const roadmapsForAuthority: AccountDTO<Roadmap>[] =
                await sdk.getRoadmapsByTeamAuthority(wallet.publicKey);
            const featuredRoadmapKeys = await getFeaturedRoadmaps(sdk);

            if (currRm !== null) {
                const currentRoadmapAccount: AccountDTO<Roadmap> =
                    await sdk.getRoadmap(new web3.PublicKey(currRm));
                roadmaps[currentRoadmapAccount.address.toBase58()] =
                    currentRoadmapAccount;
            }

            roadmapsByCollection.forEach(
                (rm) => (roadmaps[rm.address.toBase58()] = rm)
            );
            roadmapsForAuthority.forEach(
                (rm) => (roadmaps[rm.address.toBase58()] = rm)
            );
            featuredRoadmapKeys
                .filter((rm) => !!rm.account)
                .forEach((rm) => (roadmaps[rm.address.toBase58()] = rm));

            return roadmaps;
        }

        return {};
    },
});

// export const roadmapsKeysQuery= selector<web3.PublicKey[]>({
//   key: "roadmapsIdQuery",
//   get: async ({get} : any) => {
//     const sdk : PhaseProtocolSDK = get(phaseContext)
//     const wallet : AnchorWallet | undefined= get(phaseWallet)
//     const currRm = get(currentRoadmap)
//     const roadmaps : Record<string, AccountDTO<Roadmap>> = {}

//     if(wallet?.publicKey){
//       const collections = get(collectionsQuery)
//       const roadmapsByCollection : AccountDTO<Roadmap>[] = await sdk.getRoadmapsByCollectionMints(collections)
//       const roadmapsForAuthority : AccountDTO<Roadmap>[]  = await sdk.getRoadmapsByTeamAuthority(wallet.publicKey)

//       if(currRm !== null ){
//         const currentRoadmapAccount : AccountDTO<Roadmap> = await sdk.getRoadmap(new web3.PublicKey(currRm))
//         roadmaps[currentRoadmapAccount.address.toBase58()] = currentRoadmapAccount;
//       }

//       roadmapsByCollection.map(rm => roadmaps.address.toBase58())
//       roadmapsForAuthority.forEach(rm => roadmaps[rm.address.toBase58()] = rm)

//       return roadmaps

//     }

//     return []
//   }
// })

// export const roadmapIdStore = atom<web3.PublicKey[]>({
//   key : "roadmapIdStore",
//   get: async ()
// })

export const roadmapStore = atom<Record<string, AccountDTO<Roadmap>>>({
    key: "roadmapStore",
    default: roadmapsQuery,
});

export const roadmapByCollectionSelector = selectorFamily<
    AccountDTO<Roadmap> | null,
    string
>({
    key: "roadmapByCollection",
    get:
        (collectionMint: string) =>
        ({ get }: any) => {
            const roadmaps: Record<string, AccountDTO<Roadmap>> =
                get(roadmapStore);
            const roadmap = Object.values(roadmaps).find(
                (rm) => rm.account.collection?.toBase58() === collectionMint
            );
            if (roadmap === undefined) {
                return null;
            }
            return roadmap;
        },
});

export const roadmapQuery = selectorFamily<AccountDTO<Roadmap> | undefined, string>({
  key: "roadmapQuery",
    get:
        (roadmapAddress: string) =>
        ({ get }: any) => {
            const roadmaps: Record<string, AccountDTO<Roadmap>> =
                get(roadmapStore);
            const roadmap = roadmaps[roadmapAddress];
            return roadmap;
        },
});

export const roadmapsByAuthorityQuery = selector<AccountDTO<Roadmap>[]>({
    key: "roadmapByAuthorityQuery",
    get: ({ get }: any) => {
        const wallet: AnchorWallet | undefined = get(phaseWallet);
        if (wallet?.publicKey) {
            const roadmapsObj: Record<string, AccountDTO<Roadmap>> =
                get(roadmapStore);
            const roadmaps = Object.values(roadmapsObj).filter(
                (x) =>
                    x.account.teamAuthority.toBase58() ===
                    wallet.publicKey.toBase58()
            );
            return roadmaps;
        }
        return [];
    },
});

export const currentRoadmap = atom<string | null>({
    key: "currentRoadmap",
    default: null,
});

export const metadataQuery = selector<Record<string, RoadmapMetadata>>({
    key: "roadmapMetadataQuery",
    get: async ({ get }: any) => {
        const roadmaps: Record<string, AccountDTO<Roadmap>> = get(roadmapStore);
        const roadmapArray = Object.values(roadmaps);
        const resPromise = roadmapArray.map((rm) =>
            MetadataStorage.getRoadmapMetadata(
                rm.account.metadataShadowDrive,
                rm.account.name
            )
        );
        const results = await Promise.allSettled(resPromise);

        const metadataRecord: Record<string, RoadmapMetadata> = {};
        for (let i = 0; i < results.length; i++) {
            if (results[i].status === "rejected") {
                console.warn(
                    "could not fetch roadmap metadata for " +
                        roadmapArray[i].address.toBase58() +
                        ", " +
                        (results[i] as any).reason.toString()
                );
                continue;
            }

            const roadmap = roadmapArray[i];
            metadataRecord[roadmap.address.toBase58()] = (
                results[i] as any
            ).value;
        }
        return metadataRecord;
    },
});

export const roadmapMetaStore = atom<Record<string, RoadmapMetadata>>({
    key: "roadmapMeta", // unique ID (with respect to other atoms/selectors)
    default: metadataQuery, // default value (aka initial value)
});

export const nftsState = atom({
    key: "nfts", // unique ID (with respect to other atoms/selectors)
    default: [] as Metadata[], // default value (aka initial value)
});

export const roadmapState = atom({
    key: "roadmaps", // unique ID (with respect to other atoms/selectors)
    default: [] as AccountDTO<Roadmap>[], // default value (aka initial value)
});

export const projectsState = atom({
    key: "projects", // unique ID (with respect to other atoms/selectors)
    default: [] as AccountDTO<Roadmap>[], // default value (aka initial value)
});

export const phases = atom({
    key: "phases",
    default: [] as AccountDTO<Phase>[],
});

export const isCreatingPhase = atom({
    key: "isCreatingPhase",
    default: false as boolean,
});

export const roadmapFormState = atom({
    key: "roadmapFormState",
    default: {} as Record<string, string>,
    dangerouslyAllowMutability: true,
});

export const proposalVotingState = atom({
    key: "proposalVotingState",
    default: {},
});

export const solVaultAmountQueryId = atomFamily({
  key : 'solVaultAmountQueryId',
  default: 0
})

export const solTreasuryTotalQuery = selectorFamily({
    key: "solTreasuryTotalQuery",
    get:
        (roadmap: string | null) =>
        async ({ get }: any) => {
            if (roadmap === null) {
                return 0;
            }
            const conn : web3.Connection = get(connection)
            const currRoadmap : string = get(currentRoadmap)
            const phases : AccountDTO<Phase>[] = get(phaseStore(currRoadmap))
            let solBalance =  0

            for (const phase of phases) {
                solBalance =
                    solBalance +
                    (await conn.getBalance(
                        new web3.PublicKey(phase.account.solPhaseVault)
                    ));
            }
            return solBalance / LAMPORTS_PER_SOL;
        },
});

export const solTreasuryTotal = atomFamily({
  key: 'solTreasuryTotal',
  default : solTreasuryTotalQuery,
})

export const usdcTreasuryTotalQuery = selectorFamily({
    key: "usdcTreasuryTotalQuery",
    get:
        (roadmap: string | null) =>
        async ({ get }: any) => {
            if (roadmap === null) {
                return 0;
            }
            const conn: web3.Connection = get(connection);
            const currRoadmap: string = get(currentRoadmap);
            const phases: AccountDTO<Phase>[] = get(phaseStore(currRoadmap));
            let usdcBalance = 0;
            for (const phase of phases) {
                const tokenAmount = await conn.getTokenAccountBalance(
                    new web3.PublicKey(phase.account.usdcPhaseVault)
                );
                if (tokenAmount.value.uiAmount) {
                    usdcBalance = usdcBalance + tokenAmount.value.uiAmount;
                }
            }
            return Math.floor((usdcBalance) * 100) / 100;
        },
});

export const usdcTreasuryTotal = atomFamily({
    key: "usdcTreasuryTotal",
    default: usdcTreasuryTotalQuery,
});

export const solVaultAmountQuery = selectorFamily({
    key: "solVaultAmountQuery",
    get:
        (phaseVaultAddress: string | undefined) =>
        async ({ get }: any) => {
            if (phaseVaultAddress === undefined) {
                return 0;
            }
            const conn: web3.Connection = get(connection);
            get(solVaultAmountQueryId(phaseVaultAddress));
            let solBalance = await conn.getBalance(
                new web3.PublicKey(phaseVaultAddress)
            );
            return solBalance / LAMPORTS_PER_SOL;
        },
});
export const solVaultAmountState = atomFamily({
    key: "solVaultAmountState",
    default: solVaultAmountQuery,
});

export const usdcVaultAmountQueryId = atomFamily({
    key: "usdcVaultAmountQueryId",
    default: 0,
});

export const usdcVaultAmountQuery = selectorFamily({
    key: "usdcVaultAmountQuery",
    get:
        (phaseVaultAddress: string | undefined) =>
        async ({ get }: any) => {
            if (phaseVaultAddress === undefined) {
                return 0;
            }
            const conn: web3.Connection = get(connection);
            get(usdcVaultAmountQueryId(phaseVaultAddress));
            let usdcBalance = await conn.getTokenAccountBalance(
                new web3.PublicKey(phaseVaultAddress)
            );
            return usdcBalance.value.uiAmount === null
                ? 0
                : usdcBalance.value.uiAmount;
        },
});

export const usdcVaultAmountState = atomFamily({
    key: "usdcVaultAmountState",
    default: usdcVaultAmountQuery,
});

export const solPoolAmountState = atomFamily({
    key: "solPoolAmountState",
    default: solVaultAmountQuery,
});

export const usdcPoolAmountState = atomFamily({
    key: "usdcPoolAmountState",
    default: usdcVaultAmountQuery,
});
