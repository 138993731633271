import React from "react";
import { useState, useEffect } from "react";

const Countdown = ({
    endTimestamp,
    onCompletion,
}: {
    endTimestamp: number;
    onCompletion: () => void;
}) => {
    const endTime = new Date(endTimestamp * 1000).getTime();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        setDays(0);
        setMinutes(0);
        setSeconds(0);
        setHours(0);
        setIsFinished(false);

        const timer = setInterval(() => {
            let now = new Date().getTime();

            let remainingTime = endTime - now;

            const second = 1000;

            const minute = second * 60;

            const hour = minute * 60;

            const day = hour * 24;

            setDays(Math.trunc(remainingTime / day));

            setHours(Math.trunc((remainingTime % day) / hour));

            setMinutes(Math.trunc((remainingTime % hour) / minute));

            setSeconds(Math.trunc((remainingTime % minute) / second));
            if (remainingTime <= 0) {
                clearInterval(timer);
                setIsFinished(true);
                onCompletion();
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [endTimestamp]);

    return (
        <div className="pt-2">
            {isFinished ? (
                <h1> Ends in 0d : 00h : 00m : 00s</h1>
            ) : (
                <h1>
                    {" "}
                    Ends in {days}d : {hours}h :{" "}
                    {minutes < 10 ? `0${minutes}` : minutes}m :{" "}
                    {seconds < 10 ? `0${seconds}` : seconds}s
                </h1>
            )}
        </div>
    );
};

export default Countdown;
