import {
    Phase,
    AccountDTO,
    Roadmap,
    PhaseState,
    Proof,
} from "@dedmonkes/phase-protocol-sdk";
import { Dialog, Transition } from "@headlessui/react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useMemo, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useCurrentRoadmapKey } from "../state/hooks/roadmaps";
import { XCircleIcon } from "@heroicons/react/outline";
import PhaseCreateEditForm from "../partials/PhaseCreateEditForm";
import { getProgressPerc } from "../libs/utils";

export default function PhaseCard({
    phase,
    canEdit,
    idx,
}: {
    phase: AccountDTO<Phase>;
    canEdit?: boolean;
    idx: string;
}) {
    const [rpk] = useCurrentRoadmapKey();
    const [isEditingPhase, setIsEditingPhase] = useState<boolean>(false);

    const prettyState = (phaseState: PhaseState) => {
        let state = Object.keys(phaseState || {}).join();

        switch (state) {
            case "lockedForMint":
                state = "Finalized";
                break;
            case "stagedForCompletion":
                state = "Voting On Completion";
                break;
            case "stagedForApproval":
                state = "Voting On Approval";
                break;
            default:
                state = state[0].toUpperCase() + state.substring(1);
        }
        return state;
    };

    const PrettyState = ({ state }: { state: PhaseState }) => {
        const prettyPhaseState = useMemo(() => prettyState(state), [state]);
        return (
            <span className=" bg-green-100 px-2.5 py-0.5 rounded-full text-green-800">
                {prettyPhaseState}
            </span>
        );
    };

    return (
        <>
            {rpk !== null ? (
                <>
                    <Link
                        to={`/roadmap/${rpk}/phase/${phase.address.toString()}`}
                        className="rounded-md lg:max-w-4xl bg-gray-700 bg-opacity-50 shadow-md block hover:border-gray-200 my-4"
                        key={idx}
                    >
                        <div>
                            <div className="p-6 flex items-center sm:px-6">
                                <div className="relative min-w-0 w-full">
                                    <div className="">
                                        <p className="text-xl text-white font-bold mb-2 pr-16">
                                            {phase.account.config.name}{" "}
                                            {canEdit && (
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setIsEditingPhase(true);
                                                    }}
                                                    className="ml-4 text-xs px-4 leading-6 bg-gray-500 hover:bg-gray-600 rounded-full"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        </p>
                                        <p className="mb-4">
                                            <div className="flex items-start gap-4 justify-start absolute top-0 right-0  text-sm font-medium">
                                                <PrettyState
                                                    state={phase.account.state}
                                                />
                                            </div>
                                        </p>
                                        <p className="text-sm flex-shrink-0 font-normal text-gray-400">
                                            {
                                                phase.account.config
                                                    .taskDescriptionLink
                                            }
                                        </p>
                                        <div className="flex justify-between items-center">
                                            <p className="text-gray-200 mt-4 flex items-center gap-2">
                                                <span className="text-xs uppercase">
                                                    Vault Amount
                                                </span>
                                                <span>
                                                    <span className="sol inline-block align-middle mr-[2px]">
                                                        ◎
                                                    </span>
                                                    {phase.account.config.phaseSolVaultDepositAmount.toNumber() /
                                                        LAMPORTS_PER_SOL}
                                                </span>
                                            </p>
                                            <p className="text-gray-200 mt-4 flex items-center gap-2">
                                                <span className="text-xs uppercase">
                                                    Deliverables
                                                </span>
                                                <span>
                                                    {
                                                        phase.account
                                                            .totalDeliverables
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Transition appear show={isEditingPhase} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            onClose={() => setIsEditingPhase(false)}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-800 bg-opacity-50" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-bold leading-6 text-white"
                                            >
                                                Edit Phase
                                            </Dialog.Title>
                                            <button
                                                className="absolute right-0 top-0 p-4"
                                                onClick={() =>
                                                    setIsEditingPhase(false)
                                                }
                                            >
                                                <XCircleIcon className="text-white w-8" />
                                            </button>
                                            <PhaseCreateEditForm
                                                phase={phase}
                                                setIsEditingPhase={
                                                    setIsEditingPhase
                                                }
                                            />
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
