import {
    AccountDTO,
    AccountSwapContext,
    Phase,
    Roadmap,
    SwapContext,
    SwapContextEnum,
    SwapDirection,
    SwapDirectionEnum,
    SwapResult,
    ITransactionNotification,
} from "@dedmonkes/phase-protocol-sdk";
import { Dialog, Listbox, Switch, Transition } from "@headlessui/react";
import {
    CheckIcon,
    XCircleIcon,
    ChevronDownIcon,
} from "@heroicons/react/outline";
import { BN, web3 } from "@project-serum/anchor";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { FC, Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { lastValueFrom, Observable } from "rxjs";
import usePhase from "../hooks/usePhaseProtocol";
import { handleTxNotifications, truncateKey } from "../libs/utils";
import { Tooltip } from "../components/Tooltip";
import {
    useRefreshSolPoolAmount,
    useRefreshUsdcPoolAmount,
    useSolPoolAmount,
    useUsdcPoolAmount,
} from "../state/hooks/roadmaps";

export interface SwapProps {
    startSwapContext: SwapContextEnum;
    isOpen: boolean;
    roadmap: web3.PublicKey;
    pdaContext: web3.PublicKey;
    onClose: () => void;
}

export interface SwapListItemProps {
    solVault: web3.PublicKey;
    usdcVault: web3.PublicKey;
    pdaAddress: web3.PublicKey;
    name: string;
    id: number;
}

export interface BalanceInfo {
    solTokenAccountAddress: web3.PublicKey;
    usdTokenAccountAddress: web3.PublicKey;
    balance?: number | null;
    pdaAddress: web3.PublicKey;
    name: string;
}

const SwapListItem: FC<SwapListItemProps> = ({
    solVault,
    usdcVault,
    pdaAddress,
    name,
    id,
}) => {
    const { connection } = useConnection();

    const [solBalance, setSolBalance] = useState<number>(0);
    const [usdcBalance, setUsdcBalance] = useState<number>(0);

    useEffect(() => {
        const getBalance = async () => {
            const solBalanceResponse = await connection.getTokenAccountBalance(
                solVault
            );
            const usdcBalanceResponse = await connection.getTokenAccountBalance(
                usdcVault
            );

            if (solBalanceResponse.value.uiAmount !== null) {
                setSolBalance(solBalanceResponse.value.uiAmount);
            }

            if (usdcBalanceResponse.value.uiAmount !== null) {
                setUsdcBalance(usdcBalanceResponse.value.uiAmount);
            }
        };
        getBalance();
    }, []);

    return (
        <Listbox.Option
            key={id}
            className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 bg-[#111111] ${
                    active ? "bg-maroon-flush text-white" : "text-gray-200"
                }`
            }
            value={{
                usdTokenAccountAddress: usdcVault,
                solTokenAccountAddress: solVault,
                pdaAddress: pdaAddress,
                name: name,
            }}
        >
            {({ selected }) => (
                <>
                    <span
                        className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                        }`}
                    >
                        {name} - ◎{solBalance} USDC {usdcBalance}
                    </span>
                    {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    ) : null}
                </>
            )}
        </Listbox.Option>
    );
};

const SwapModal: FC<SwapProps> = ({
    startSwapContext,
    isOpen,
    pdaContext,
    roadmap,
    onClose,
}) => {
    const sdk = usePhase();
    let { id } = useParams();
    const { connection } = useConnection();
    const wallet = useAnchorWallet();

    const fetchSolPool = useRefreshSolPoolAmount();
    const fetchUsdcPool = useRefreshUsdcPoolAmount();

    let [selectedwSolBalance, setSelectedwSolBalance] =
        useState<number | null>(null);

    let [selectedNativeSolBalance, setSelectedNativeSolBalance] =
        useState<number | null>(null);
    let [selectedUsdcBalance, setSelectedUsdcBalance] =
        useState<number | null>(null);

    let [isRoadmapSwap, setIsRoadmapSwap] = useState<boolean>(
        startSwapContext === SwapContextEnum.roadmapPool
    );

    let [selectedVault, setSelectedVault] = useState<BalanceInfo | null>(null);
    let [swapDirection, setSwapDirection] = useState<SwapDirectionEnum>(
        SwapDirectionEnum.solToUsdc
    );
    let [isLoading, setIsLoading] = useState<boolean>(true);
    const [swapInputAmount, setSwapInputAmount] = useState<string>("0.00");
    let [roadmapAccount, setRoadmapAccount] =
        useState<AccountDTO<Roadmap> | null>(null);

    let [phases, setPhases] = useState<AccountDTO<Phase>[]>([]);

    const handleSetSwapContext = (isRoadmapSwap: boolean) => {
        if (isRoadmapSwap && roadmapAccount !== null) {
            setSelectedVault({
                usdTokenAccountAddress: roadmapAccount.account.usdcRoadmapPool,
                solTokenAccountAddress: roadmapAccount.account.solRoadmapPool,
                pdaAddress: roadmapAccount.address,
                name: "Roadmap Pool",
            });
        } else {
            setSelectedVault({
                usdTokenAccountAddress: phases[0].account.usdcPhaseVault,
                solTokenAccountAddress: phases[0].account.solPhaseVault,
                pdaAddress: phases[0].address,
                name: phases[0].account.config.name,
            });
        }

        setIsRoadmapSwap(isRoadmapSwap);
    };

    const handleSwap = async (
        swapDirection: SwapDirectionEnum,
        roadmap: AccountDTO<Roadmap> | null,
        amount: string,
        pdaAddress: web3.PublicKey | undefined,
        phases: AccountDTO<Phase>[]
    ) => {
        const toasts = new Map<string, string>();

        if (roadmap === null || pdaAddress === undefined) {
            return;
        }

        const decimalIndex = amount.indexOf(".");
        if (decimalIndex === -1) {
            amount = amount + ".00";
        } else if (decimalIndex === amount.length - 1) {
            amount = amount.slice(0, -1) + ".00";
        }

        const valueDecimals = amount.split(".")[1].length;
        const amountValue = new BN(
            Number.parseFloat(amount) * Math.pow(10, valueDecimals)
        );
        const mintDecimals =
            swapDirection === SwapDirectionEnum.solToUsdc
                ? new BN(web3.LAMPORTS_PER_SOL).div(
                      new BN(Math.pow(10, valueDecimals))
                  )
                : new BN(1_000_000).div(new BN(Math.pow(10, valueDecimals)));

        if (roadmap.address.toBase58() === pdaAddress.toBase58()) {
            const obs = await sdk.swapRoadmapPoolTokens(
                roadmap,
                amountValue.mul(mintDecimals),
                new BN(0),
                swapDirection
            );
            obs.subscribe({
                next: async (notification: any) => {
                    await handleTxNotifications(
                        notification,
                        toasts,
                        `Swapped ${amount} ${
                            swapDirection === SwapDirectionEnum.solToUsdc
                                ? "wSOL"
                                : "USDC"
                        } to ${
                            swapDirection === SwapDirectionEnum.usdcToSol
                                ? "wSOL"
                                : "USDC"
                        } for roadmap ${truncateKey(
                            roadmap.address.toBase58()
                        )}`,
                        "Swap"
                    );
                },
                error: (error: any) => {
                    console.error(error);
                    toast.error(error.toString());
                },
            });
            const balanceInfo: any = await lastValueFrom(obs);

            if (swapDirection === SwapDirectionEnum.solToUsdc) {
                setSelectedUsdcBalance(
                    (x) => balanceInfo.toAccountContext.postBalance
                );
                setSelectedwSolBalance(
                    (x) => balanceInfo.fromAccountContext.postBalance
                );
            } else {
                setSelectedUsdcBalance(
                    (x) => balanceInfo.fromAccountContext.postBalance
                );
                setSelectedwSolBalance(
                    (x) => balanceInfo.toAccountContext.postBalance
                );
            }
            fetchSolPool();
            fetchUsdcPool();
        } else {
            const phase = phases.filter(
                (phase) => phase.address.toBase58() === pdaAddress.toBase58()
            );

            if (phase.length === 0) {
                console.warn("No phase matching pda key provided!");
                return;
            }

            const obs = await sdk.swapPhaseVaultTokens(
                roadmap,
                phase[0],
                amountValue.mul(mintDecimals),
                new BN(0),
                swapDirection
            );

            obs.subscribe({
                next: async (notification: any) => {
                    if (notification.type === undefined) {
                        const balanceInfo: SwapResult = notification;
                        if (swapDirection === SwapDirectionEnum.solToUsdc) {
                            setSelectedUsdcBalance(
                                (x) => balanceInfo.toAccountContext.postBalance
                            );
                            setSelectedwSolBalance(
                                (x) =>
                                    balanceInfo.fromAccountContext.postBalance
                            );
                        } else {
                            setSelectedUsdcBalance(
                                (x) =>
                                    balanceInfo.fromAccountContext.postBalance
                            );
                            setSelectedwSolBalance(
                                (x) => balanceInfo.toAccountContext.postBalance
                            );
                        }
                    }
                    await handleTxNotifications(
                        notification,
                        toasts,
                        `Swapped ${amount} ${
                            swapDirection === SwapDirectionEnum.solToUsdc
                                ? "wSOL"
                                : "USDC"
                        } to ${
                            swapDirection === SwapDirectionEnum.usdcToSol
                                ? "wSOL"
                                : "USDC"
                        } for phase ${truncateKey(
                            phase[0].address.toBase58()
                        )}`,
                        "Swap"
                    );
                },
                error: (error: any) => {
                    console.error(error);
                    toast.error(error.toString());
                },
            });
        }
    };

    const handleSwapInputChange = (amount: string) => {
        var reg = /^-?\d*\.?\d*$/;

        if (!reg.test(amount)) {
            return;
        }

        const decimalsSplit = amount.split(".");

        if (decimalsSplit.length === 2) {
            if (decimalsSplit[1].length > 6) {
                return;
            }
        }

        setSwapInputAmount(amount);
    };
    // Dumb jup-ag with spl-token 1.8 not having the instruction needed
    const handleSyncNative = async (poolAddress: string) => {
        if (wallet) {
            // const transaction = new web3.Transaction().add(.createSyncNativeInstruction())
            // transaction.feePayer = wallet.publicKey
            // wallet?.signTransaction(transaction)
            // try{
            //   toast.loading("Sending transaction to wrap roadmap pools native sol")
            //   const sig = await web3.sendAndConfirmTransaction(connection, transaction, [])
            //   toast.success("Successfully wrapped native Sol in your roadmap pool")
            //   console.log(sig)
            // }
            // catch(e){
            //   toast.error("Error wrapping roadmap pools native sol!")
            // }
        }
    };

    useEffect(() => {
        const getRoadmapAndPhases = async () => {
            if (sdk?.getRoadmap === undefined) {
                return;
            }
            const roadmapAcc = (await sdk.getRoadmap(
                roadmap
            )) as AccountDTO<Roadmap>;
            const phases = await sdk?.getPhases(
                roadmapAcc?.address as web3.PublicKey
            );
            setPhases(phases);
            setRoadmapAccount(roadmapAcc);
            if ((isRoadmapSwap || phases.length === 0) && roadmapAcc !== null) {
                setSelectedVault({
                    usdTokenAccountAddress: roadmapAcc.account.usdcRoadmapPool,
                    solTokenAccountAddress: roadmapAcc.account.solRoadmapPool,
                    pdaAddress: roadmapAcc.address,
                    name: "Roadmap Pool",
                });
            } else {
                const contextPhaseFiltered = phases.filter(
                    (x: any) => x.address.toBase58() === pdaContext.toBase58()
                );
                let phase =
                    contextPhaseFiltered.length === 0
                        ? phases[0]
                        : contextPhaseFiltered[0];

                setSelectedVault({
                    usdTokenAccountAddress: phase.account.usdcPhaseVault,
                    solTokenAccountAddress: phase.account.solPhaseVault,
                    pdaAddress: phase.address,
                    name: phase.account.config.name,
                });
            }

            setIsLoading(false);
        };

        getRoadmapAndPhases();
    }, [sdk, roadmap]);

    useEffect(() => {
        const getBalance = async () => {
            if (selectedVault === null) {
                return;
            }

            const solBalanceResponse = await connection.getTokenAccountBalance(
                selectedVault?.solTokenAccountAddress
            );
            const usdcBalanceResponse = await connection.getTokenAccountBalance(
                selectedVault?.usdTokenAccountAddress
            );

            const nativeSolBalance = await connection.getBalance(
                selectedVault?.solTokenAccountAddress
            );

            setSelectedNativeSolBalance(nativeSolBalance);

            if (solBalanceResponse.value.uiAmount !== null) {
                setSelectedwSolBalance(solBalanceResponse.value.uiAmount);
            }

            if (usdcBalanceResponse.value.uiAmount !== null) {
                setSelectedUsdcBalance(usdcBalanceResponse.value.uiAmount);
            }
        };
        getBalance();
    }, [selectedVault, roadmap]);

    return (
        <>
            {isOpen ? (
                <div className="relative z-10 top-0">
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-80" />
                    <div className="fixed inset-0 h-full">
                        <div className="flex min-h-full items-center justify-center p-4 text-center ">
                            <div
                                className="flex-row items-between w-full h-full 
                        max-w-xl transform
                        rounded-2xl p-8 bg-[#111111] text-left align-middle shadow-xl transition-all"
                            >
                                <div className="h3 text-lg font-bold leading-6 text-white">
                                    Swap Treasury Tokens
                                </div>
                                <br />
                                <div
                                    // as="h4"
                                    className="h4 text-md font-semi leading-normal text-gray-400"
                                >
                                    {`Swap between USDC and wSOL in your ${
                                        isRoadmapSwap
                                            ? "roadmap pool"
                                            : "phase vaults"
                                    }`}
                                </div>
                                {isLoading ? (
                                    "Loading.."
                                ) : (
                                    <Listbox
                                        value={selectedVault}
                                        onChange={setSelectedVault}
                                        disabled={isRoadmapSwap}
                                    >
                                        <div className="relative mt-6">
                                            <div className="w-full text-xs text-white flex justify-between pl-1 pr-1 h-5">
                                                <p className="text-gray-400">
                                                    Swap Context
                                                </p>
                                                <p className="text-gray-400">
                                                    Balance
                                                </p>
                                            </div>

                                            <div className="flex justify-end items-center text-white">
                                                <div className="flex-1">
                                                    <button
                                                        onClick={() =>
                                                            handleSetSwapContext(
                                                                true
                                                            )
                                                        }
                                                        className={`${
                                                            isRoadmapSwap
                                                                ? "bg-maroon-flush-500"
                                                                : "bg-maroon-flush-700 shadow-inner shadow-md hover:bg-maroon-flush-600 transition-all"
                                                        } px-4 py-2 rounded-l-md text-sm`}
                                                    >
                                                        Roadmap
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleSetSwapContext(
                                                                false
                                                            )
                                                        }
                                                        className={`${
                                                            !isRoadmapSwap
                                                                ? "bg-maroon-flush-500"
                                                                : "bg-maroon-flush-700 shadow-inner shadow-md hover:bg-maroon-flush-600 transition-all"
                                                        } px-4 py-2 rounded-r-md text-sm`}
                                                    >
                                                        Phase
                                                    </button>
                                                </div>

                                                <button
                                                    onClick={() =>
                                                        swapDirection ===
                                                        SwapDirectionEnum.usdcToSol
                                                            ? setSwapDirection(
                                                                  SwapDirectionEnum.solToUsdc
                                                              )
                                                            : setSwapDirection(
                                                                  SwapDirectionEnum.usdcToSol
                                                              )
                                                    }
                                                    className="rounded-lg bg-gray-900 px-4 py-2 mr-2 h-10"
                                                >
                                                    <div className="flex items-center justify-center text-white">
                                                        <img
                                                            className="w-5 rounded-full"
                                                            alt="USDCtoSol"
                                                            src={
                                                                swapDirection ===
                                                                SwapDirectionEnum.solToUsdc
                                                                    ? "/SOL.webp"
                                                                    : "/usdc.webp"
                                                            }
                                                        />
                                                        <Tooltip
                                                            text="Switch Swap Direction"
                                                            position="top"
                                                            width={180}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="w-5 h-5"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                                                                />
                                                            </svg>
                                                        </Tooltip>

                                                        <img
                                                            className="w-5 rounded-full"
                                                            alt="SoltoUSDC"
                                                            src={
                                                                swapDirection ===
                                                                SwapDirectionEnum.usdcToSol
                                                                    ? "/SOL.webp"
                                                                    : "/usdc.webp"
                                                            }
                                                        />
                                                    </div>
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        selectedwSolBalance
                                                            ? setSwapInputAmount(
                                                                  selectedwSolBalance.toString()
                                                              )
                                                            : null
                                                    }
                                                    className={`cursor-pointer flex items-center w-fit rounded-lg bg-gray-900 px-4 py-2 ${
                                                        swapDirection !==
                                                            SwapDirectionEnum.solToUsdc &&
                                                        "hidden"
                                                    }`}
                                                >
                                                    <img
                                                        className="w-5 mr-2 rounded-full"
                                                        src="/SOL.webp"
                                                        alt="solbalance"
                                                    />
                                                    {selectedwSolBalance}
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        selectedUsdcBalance
                                                            ? setSwapInputAmount(
                                                                  selectedUsdcBalance.toString()
                                                              )
                                                            : null
                                                    }
                                                    className={`cursor-pointer flex items-center w-fit rounded-lg bg-gray-900 px-4 py-2 ${
                                                        swapDirection !==
                                                            SwapDirectionEnum.usdcToSol &&
                                                        "hidden"
                                                    }`}
                                                >
                                                    <img
                                                        className="w-5 mr-2"
                                                        src="/usdc.webp"
                                                        alt="usdcbalance"
                                                    />
                                                    {selectedUsdcBalance}
                                                </button>
                                            </div>
                                            <br></br>

                                            <div className="relative flex-row justify-center w-full cursor-default rounded-lg p-6 text-left shadow-md border-2 border-gray-700 text-white">
                                                <div className="flex h-full justify-between items-center">
                                                    <Listbox.Button
                                                        disabled={isRoadmapSwap}
                                                        className={`p-0 flex flex-row text-left gap-4 items-center ${
                                                            !isRoadmapSwap &&
                                                            "hover:text-gray-200"
                                                        } rounded-lg p-2 h-full justify-center`}
                                                    >
                                                        <div>
                                                            <div className="font-semibold">
                                                                {
                                                                    selectedVault?.name
                                                                }
                                                            </div>
                                                            <div className="text-gray-500 whitespace-nowrap">
                                                                {truncateKey(
                                                                    selectedVault?.pdaAddress.toBase58() as string
                                                                )}
                                                            </div>
                                                        </div>
                                                        {!isRoadmapSwap ? (
                                                            <div className="text-xl flex justify-center">
                                                                <ChevronDownIcon className="w-6" />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Listbox.Button>
                                                    <div className="flex-row justify-center items-center">
                                                        <input
                                                            value={
                                                                swapInputAmount
                                                            }
                                                            onChange={(e) =>
                                                                handleSwapInputChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="h-7 bg-transparent border-b outline-none border-gray-500 text-center w-24 flex ml-auto text-lg"
                                                            onFocus={(e) =>
                                                                swapInputAmount ===
                                                                "0.00"
                                                                    ? setSwapInputAmount(
                                                                          ""
                                                                      )
                                                                    : null
                                                            }
                                                        ></input>
                                                        <div className="flex flex-row-reverse justify-center items-start text-xs gap-1 mt-2">
                                                            <button
                                                                onClick={() =>
                                                                    swapDirection ===
                                                                        SwapDirectionEnum.usdcToSol &&
                                                                    selectedUsdcBalance &&
                                                                    selectedUsdcBalance !==
                                                                        0
                                                                        ? setSwapInputAmount(
                                                                              selectedUsdcBalance?.toString()
                                                                          )
                                                                        : selectedwSolBalance &&
                                                                          selectedwSolBalance !==
                                                                              0
                                                                        ? setSwapInputAmount(
                                                                              selectedwSolBalance?.toString()
                                                                          )
                                                                        : null
                                                                }
                                                                className="px-2 bg-gray-900 rounded-sm"
                                                            >
                                                                Max
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    swapDirection ===
                                                                        SwapDirectionEnum.usdcToSol &&
                                                                    selectedUsdcBalance &&
                                                                    selectedUsdcBalance !==
                                                                        0
                                                                        ? setSwapInputAmount(
                                                                              (
                                                                                  selectedUsdcBalance /
                                                                                  2
                                                                              ).toString()
                                                                          )
                                                                        : selectedwSolBalance &&
                                                                          selectedwSolBalance !==
                                                                              0
                                                                        ? setSwapInputAmount(
                                                                              (
                                                                                  selectedwSolBalance /
                                                                                  2
                                                                              ).toString()
                                                                          )
                                                                        : null
                                                                }
                                                                className="px-2 bg-gray-900 rounded-sm"
                                                            >
                                                                Half
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#111111]  py-1 text-base ring-1 ring-white ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {phases.map(
                                                        (
                                                            phase: AccountDTO<Phase>,
                                                            idx
                                                        ) => (
                                                            <SwapListItem
                                                                key={phase.address.toBase58()}
                                                                solVault={
                                                                    phase
                                                                        .account
                                                                        .solPhaseVault
                                                                }
                                                                usdcVault={
                                                                    phase
                                                                        .account
                                                                        .usdcPhaseVault
                                                                }
                                                                name={
                                                                    phase
                                                                        .account
                                                                        .config
                                                                        .name
                                                                }
                                                                id={idx}
                                                                pdaAddress={
                                                                    phase.address
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                )}
                                <br />
                                <div></div>

                                <button
                                    onClick={() =>
                                        handleSwap(
                                            swapDirection,
                                            roadmapAccount,
                                            swapInputAmount,
                                            selectedVault?.pdaAddress,
                                            phases
                                        )
                                    }
                                    className="bg-maroon-flush border border-transparent 
                        rounded-md shadow-sm py-2 px-4 inline-flex justify-center 
                        text-sm font-medium text-white hover:bg-maroon-flush-600 
                        w-full"
                                >
                                    Convert {swapInputAmount}{" "}
                                    {swapDirection ===
                                    SwapDirectionEnum.usdcToSol
                                        ? "USDC to SOL"
                                        : "SOL to USDC"}
                                </button>
                                {/* <button
              onClick={() => {

              }
              }
              className="bg-maroon-flush border border-transparent 
                        rounded-md shadow-sm py-2 px-4 inline-flex justify-center 
                        text-sm font-medium text-white hover:bg-maroon-flush-600 
                        w-full"
            >
              Convert {swapInputAmount}{" "}
              {swapDirection === SwapDirectionEnum.usdcToSol
                ? "USDC to SOL"
                : "SOL to USDC"}
            </button> */}
                                <button
                                    className="absolute right-0 top-0 p-4"
                                    onClick={() => onClose()}
                                >
                                    <XCircleIcon className="text-white w-8" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default SwapModal;
